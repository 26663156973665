import React from "react";
import { Navigate } from "react-router-dom";

import Supervised from "../../../../../views/protected/myCompany/supervised/Supervised";
import SupervisedIndex from "../../../../../views/protected/myCompany/supervised/SupervisedIndex";
import SupervisedShow from "../../../../../views/protected/myCompany/supervised/SupervisedShow";
import SupervisedDocuments from "../../../../../views/protected/myCompany/supervised/tabs/SupervisedDocuments";

const supervisedRoutes = {
    element: <Supervised />,
    children: [
        {
            path: "",
            element: <SupervisedIndex />,
        },
        {
            path: ":id/*",
            element: <SupervisedShow />,
            children: [
                {
                    path: "documents",
                    element: <SupervisedDocuments />,
                    children: [
                        {
                            path: ":cat",
                            element: <SupervisedDocuments />,
                        },
                        {
                            path: ":cat/:num_a",
                            element: <SupervisedDocuments />,
                        },
                        {
                            path: ":cat/:num_a/:num_b",
                            element: <SupervisedDocuments />,
                        },
                    ],
                },
                {
                    path: "",
                    element: <Navigate to='documents' />,
                },
            ],
        },
    ],
};

export default supervisedRoutes;
