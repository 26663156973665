import { Link } from "react-router-dom";

import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";
import CommunicationState from "./CommunicationState";

export const communicationsCols = [
    {
        header: "Titolo",
        accessorKey: "title",
        cell: ({ getValue, row: { original } }) => (
            <Link className='font-bold' to={original.uuid}>
                {getValue()}
            </Link>
        ),
    },
    {
        header: "Stato",
        accessorKey: "id",
        cell: ({ row: { original } }) => <CommunicationState start_at={original.start_at} end_at={original.end_at} />,
    },
    {
        header: "Dal",
        accessorKey: "start_at",
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
    {
        header: "Al",
        accessorKey: "end_at",
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
];
