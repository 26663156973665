import { Link } from "react-router-dom";

import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";
import StateDisplay from "../../../../../components/ui/State/StateDisplay";
import UserDisplay from "../../../../../components/ui/Users/UserDisplay";

export const uploadsCols = [
    {
        header: "Data",
        accessorKey: "updated_at",
        cell: ({ getValue, row: { original } }) => (
            <Link className='font-bold' to={original.uuid}>
                <DateFormatDisplay value={getValue()} />
            </Link>
        ),
    },
    {
        header: "Azienda",
        accessorKey: "company",
        cell: ({ getValue }) => (
            <Link className='font-bold' to={`/admin/companies/${getValue()?.uuid}`}>
                {getValue()?.business_name}
            </Link>
        ),
    },
    {
        header: "Categoria",
        accessorKey: "document_category",
        cell: ({ getValue }) => <>{getValue()?.name}</>,
    },
    {
        header: "Stato",
        accessorKey: "status",
        cell: ({ getValue }) => <StateDisplay state={getValue()} />,
    },
    {
        header: "Utente",
        accessorKey: "user",
        cell: ({ getValue }) => <UserDisplay user={getValue()} />,
    },
];
