import Header from "components/layout/ProtectedLayout/_components/Header/Header";
import SideNav from "components/layout/ProtectedLayout/_components/SideNav/SideNav";
import React from "react";
import { Outlet } from "react-router-dom";

const ProtectedLayout = () => {
    return (
        <div className='app-layout-classic flex flex-auto flex-col'>
            <div className='flex flex-auto min-w-0'>
                <SideNav />
                <div className='flex flex-col flex-auto min-h-screen min-w-0 relative w-full'>
                    <Header />
                    <div className='h-full flex flex-auto flex-col'>
                        <Outlet />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ProtectedLayout;
