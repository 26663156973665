import { HiOutlinePlus } from "@react-icons/all-files/hi/HiOutlinePlus";
import React from "react";
import { useSanctum } from "react-sanctum";

import FormDescription from "../../../../../../components/RefactoredForms/FormDescription";
import { Avatar, Notification, Upload, toast } from "../../../../../../components/ui";
import userService from "../../../../../../services/userService";

const AccountAvatarUploader = () => {
    const { user, setUser } = useSanctum();

    const onFileUpload = files => {
        const fileObj = files && files[0];
        if (!fileObj) {
            return;
        }
        userService
            .storeAvatar(fileObj)
            .then(res => {
                setUser({ ...user, avatar: res.avatar });
                toast.push(<Notification title={"Avatar aggiornato con successo"} type='success' />);
            })
            .catch(error => toast.push(<Notification title={error.response.data.message} type='success' />));
    };

    return (
        <>
            <FormDescription title='Avatar' desc="Carica l'immagine del tuo profilo clicccando sull'avatar." />
            <div className='grid md:grid-cols-3 gap-4 py-8 border-b border-gray-200 dark:border-gray-600 items-center'>
                <div className='font-semibold'>File png, jpg, jpeg</div>
                <div className='col-span-2'>
                    <Upload className='cursor-pointer' onChange={onFileUpload} showList={false} uploadLimit={1}>
                        <Avatar size={80} src={user.avatar} shape='circle' icon={<HiOutlinePlus />} />
                    </Upload>
                </div>
            </div>
        </>
    );
};

export default AccountAvatarUploader;
