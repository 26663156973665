import api from "../configs/api.config";

const COMMUNICATION_BASE_URL = "/communications";
const fetchAll = params => api.get(COMMUNICATION_BASE_URL, { params }).then(res => res.data);
const fetchActive = params => api.get(`${COMMUNICATION_BASE_URL}/active`, { params }).then(res => res.data);
const fetch = id => api.get(`${COMMUNICATION_BASE_URL}/${id}`).then(res => res.data.data);
const store = params => api.post(`${COMMUNICATION_BASE_URL}`, params).then(res => res.data.data);
const update = (id, params) => api.put(`${COMMUNICATION_BASE_URL}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${COMMUNICATION_BASE_URL}/${id}`);

const communicationService = {
    fetchAll,
    fetchActive,
    fetch,
    store,
    update,
    destroy,
};

export default communicationService;
