import * as Yup from "yup";

const createInitialValues = {
    name: "",
    description: "",
    is_public: false,
    is_pin_to_dashboard: false,
    file: [],
    company_groups: [],
    send_notification: false,
};

const createValidationSchema = Yup.object().shape({
    name: Yup.string().max(100).required(),
    description: Yup.string(800).nullable().notRequired(),
    is_public: Yup.bool().required(),
    is_pin_to_dashboard: Yup.bool().required(),
    file: Yup.array().min(1).required(),
    company_groups: Yup.array().required(),
    send_notification: Yup.boolean().required(),
});

const editInitialValues = {
    name: "",
    description: "",
    is_public: false,
    is_pin_to_dashboard: false,
    company_groups: [],
};

const editValidationSchema = Yup.object().shape({
    name: Yup.string().max(100).required(),
    description: Yup.string(800).nullable().notRequired(),
    is_public: Yup.bool().required(),
    is_pin_to_dashboard: Yup.bool().required(),
    company_groups: Yup.array().required(),
});

const setInitialValues = attachment => {
    return {
        name: attachment?.name,
        description: attachment?.description,
        is_public: attachment?.is_public,
        is_pin_to_dashboard: attachment?.is_pin_to_dashboard,
        company_groups: attachment?.company_groups,
    };
};

export const globalAttachmentFormConfig = {
    createInitialValues,
    createValidationSchema,
    editInitialValues,
    editValidationSchema,
    setInitialValues,
};
