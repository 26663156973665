import React from "react";

import Spinner from "../Spinner";

export const LoadingMessage = ({ message, loading }) => {
    return (
        loading && (
            <div className='flex justify-start items-center gap-2'>
                <Spinner color='sky-800' size='sm' />
                <span className='font-bold text-sky-800'>{message}</span>
            </div>
        )
    );
};
