import React from "react";

import FormDisplay from "../../../../../../components/RefactoredForms/FormDisplay";
import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import PageHeader from "../../../../../../components/ui/Page/PageHeader";

const UploadNotificationShow = ({ upload }) => {
    return (
        <>
            <div className={"flex flex-col gap-4"}>
                <PageHeader title={"Impostazioni di notifica"} description={"Message, data e destinatari della notifica"} />
                <div className='grid xl:grid-cols-2 gap-4'>
                    <div className={"flex flex-col gap-4"}>
                        <FormDisplay value='Abilita notifica schedulata'>
                            {!!upload?.scheduled_notification?.notification_text ? "SI" : "NO"}
                        </FormDisplay>
                        <FormDisplay value='Giorno e ora previsto di invio notifica'>
                            <DateFormatDisplay value={upload?.scheduled_notification?.notify_at} formatString='dd/MM/yyyy HH:mm' />
                        </FormDisplay>
                        <FormDisplay value='Giorno e ora di effettivo invio notifica'>
                            <DateFormatDisplay value={upload?.scheduled_notification?.notified_at} formatString='dd/MM/yyyy HH:mm' />
                        </FormDisplay>
                        <FormDisplay value='Testo della notifica'> </FormDisplay>
                        <div
                            className='rich-text-content-display'
                            dangerouslySetInnerHTML={{
                                __html: upload?.scheduled_notification?.notification_text,
                            }}
                        />
                    </div>
                    <div className={"flex flex-col gap-4"}>
                        <FormDisplay value='Abilita visualizzazione lista file'>
                            {upload?.scheduled_notification?.show_upload_file_list ? "SI" : "NO"}
                        </FormDisplay>
                        <FormDisplay value="Abilita invio notifica all'indirizzo email aziendale">
                            {upload?.scheduled_notification?.send_to_company_email ? "SI" : "NO"}
                        </FormDisplay>
                        <div className='ml-5'>
                            <FormDisplay value='Metti in copia Studio Barzaghi'>
                                {upload?.scheduled_notification?.send_ccn_to_sysadmin ? "SI" : "NO"}
                            </FormDisplay>
                        </div>
                        <FormDisplay value='Invia agli amministratori della società'>
                            {upload?.scheduled_notification?.send_to_company_admin_email ? "SI" : "NO"}
                        </FormDisplay>
                        <FormDisplay value='Invia ai delegati della società'>
                            {upload?.scheduled_notification?.send_to_company_delegati_email ? "SI" : "NO"}
                        </FormDisplay>
                        <FormDisplay value='Metti in copia lo studio delegato'>
                            {upload?.scheduled_notification?.send_to_company_supervisor_email ? "SI" : "NO"}
                        </FormDisplay>
                        <FormDisplay value='Invia alla PEC della società'>
                            {upload?.scheduled_notification?.send_to_company_pec ? "SI" : "NO"}
                        </FormDisplay>
                    </div>
                </div>
            </div>
        </>
    );
};

export default UploadNotificationShow;
