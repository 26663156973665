import React from "react";
import { Navigate } from "react-router-dom";

import AcceptInvite from "../../views/auth/AcceptInvite/AcceptInvite";
import ForceResetPassword from "../../views/auth/ForceResetPassword/ForceResetPassword";
import ForgotPassword from "../../views/auth/ForgotPassword/ForgotPassword";
import ResetPassword from "../../views/auth/ResetPassword/ResetPassword";
import SignIn from "../../views/auth/SignIn/SignIn";

const authRoutes = [
    { path: "login", element: <SignIn /> },
    { path: "force-reset-password", element: <ForceResetPassword /> },
    { path: "forgot-password", element: <ForgotPassword /> },
    { path: "new-password/:token", element: <ResetPassword /> },
    { path: "welcome", element: <AcceptInvite /> },
    { index: true, element: <Navigate to='login' /> },
];

export default authRoutes;
