import { useContext, useEffect, useMemo } from "react";
import { Navigate } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import { ApplicationContext } from "../App";
import { sessionConfig } from "../configs/session.config";
import companyService from "../services/companyService";

const AuthGuard = props => {
    const { authenticated, user } = useSanctum();
    const { setCurrentCompany } = useContext(ApplicationContext);

    useEffect(() => {
        if (user) {
            if (user.is_system_admin) {
                sessionConfig.removeCurrentCompanyId();
            } else {
                sessionConfig.setCurrentCompanyId(user.company.id);
                companyService.fetch(user.company.uuid).then(res => setCurrentCompany(res));
            }
        }
    }, [user]);

    const redirectAfterLoginPath = useMemo(() => {
        if (props.auth && window.location.pathname !== "/") {
            return "redirect=" + encodeURIComponent(window.location.pathname);
        }
    }, [props.auth]);

    if (authenticated !== null) {
        return authenticated === props.auth ? (
            <div>{props.children}</div>
        ) : (
            <Navigate to={{ pathname: props.redirect, search: redirectAfterLoginPath }} replace />
        );
    }
    return <div></div>;
};

export default AuthGuard;
