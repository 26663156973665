import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import FormDisplay from "../../../../../components/RefactoredForms/FormDisplay";
import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import { Notification, toast } from "../../../../../components/ui";
import CancelButton from "../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../components/ui/Buttons/_components/SaveButton";
import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";
import usersService from "../../../../../services/usersService";

const AdminUserShow = ({ user, onUpdate }) => {
    return (
        <Formik
            initialValues={{
                email: user.email || "",
                name: user.name || "",
            }}
            validationSchema={Yup.object().shape({
                email: Yup.string().email().required(),
                name: Yup.string().required(),
            })}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                usersService
                    .update(user.uuid, values)
                    .then(res => {
                        onUpdate(res);
                        toast.push(<Notification title='Utente aggiornato con successo' type='success' />);
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isSubmitting, values, isValid, dirty, resetForm, setFieldValue }) => (
                <Form>
                    <div className='grid lg:grid-cols-4 gap-y-7 gap-x-4'>
                        <FormGroup name={"name"} label='Nome e cognome' required>
                            <FormInput name={"name"} />
                        </FormGroup>
                        <FormGroup name={"email"} label='Email' required>
                            <FormInput name={"email"} type={"email"} />
                        </FormGroup>
                        <FormDisplay value='Ultimo Accesso'>
                            <DateFormatDisplay value={user.last_login} />
                        </FormDisplay>
                        <FormDisplay value='Data Creazione'>
                            <DateFormatDisplay value={user.created_at} />
                        </FormDisplay>
                    </div>
                    <div className='justify-end flex gap-4 mt-4'>
                        <CancelButton disabled={!dirty || isSubmitting} handleClick={() => resetForm()} />
                        <SaveButton label='Salva' loading={isSubmitting} disabled={!dirty || !isValid} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AdminUserShow;
