import api from "../configs/api.config";

const USERS_BASE_URL = "users";

/**
 * Build url
 * @param companyUuid
 * @param userUuid
 * @return {string|string}
 */
const getUserBaseUrl = (companyUuid = null, userUuid = null) => {
    let url = companyUuid ? `companies/${companyUuid}/${USERS_BASE_URL}` : USERS_BASE_URL;

    return userUuid ? `${url}/${userUuid}` : url;
};

// #############################################################################
// USERS
// #############################################################################
const fetchAll = params => api.get(getUserBaseUrl(null, null), { params }).then(res => res.data);
const fetch = userUuid => api.get(getUserBaseUrl(null, userUuid)).then(res => res.data.data);
const store = params => api.post(getUserBaseUrl(null, null), params).then(res => res.data.data);
const update = (userUuid, params) => api.put(getUserBaseUrl(null, userUuid), params).then(res => res.data.data);
const destroy = userUuid => api.delete(getUserBaseUrl(null, userUuid));
const reInvite = userUuid => api.put(`${getUserBaseUrl(null, userUuid)}/re-invite`);
const forgotPassword = userUuid => api.put(`${getUserBaseUrl(null, userUuid)}/forgot-password`);
const toggleActivation = userUuid => api.patch(`${getUserBaseUrl(null, userUuid)}/toggle-active`).then(res => res.data.data);

// #############################################################################
// COMPANY USERS
// #############################################################################
const companyFetchAll = (companyUuid, params) => api.get(getUserBaseUrl(companyUuid, null), { params }).then(res => res.data.data);
const companyFetch = (companyUuid, userUuid) => api.get(getUserBaseUrl(companyUuid, userUuid)).then(res => res.data.data);
const companyStore = (companyUuid, params) => api.post(getUserBaseUrl(companyUuid, null), params).then(res => res.data.data);
const companyUpdate = (companyUuid, userUuid, params) => api.put(getUserBaseUrl(companyUuid, userUuid), params);
const companyDestroy = (companyUuid, userUuid) => api.delete(getUserBaseUrl(companyUuid, userUuid));
const companyReInvite = (companyUuid, userUuid) => api.put(`${getUserBaseUrl(companyUuid, userUuid)}/re-invite`);
const companyForgotPassword = (companyUuid, userUuid) => api.put(`${getUserBaseUrl(companyUuid, userUuid)}/forgot-password`);
const companyToggleActivation = (companyUuid, userUuid) =>
    api.patch(`${getUserBaseUrl(companyUuid, userUuid)}/toggle-active`).then(res => res.data.data);

const usersService = {
    fetchAll,
    fetch,
    store,
    update,
    destroy,
    reInvite,
    forgotPassword,
    toggleActivation,
    companyFetchAll,
    companyFetch,
    companyStore,
    companyUpdate,
    companyDestroy,
    companyReInvite,
    companyForgotPassword,
    companyToggleActivation,
};

export default usersService;
