import React from "react";
import { Outlet } from "react-router-dom";

const BlankLayout = () => {
    return (
        <div className='app-layout-blank flex flex-auto flex-col h-[100vh]'>
            <Outlet />
        </div>
    );
};

export default BlankLayout;
