import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import usePermissions from "../../../../utils/hooks/usePermissions";

const Supervision = () => {
    const { user } = useSanctum();

    const hasPermission = usePermissions(user.role.permissions, ["supervisions.view"]);

    return user?.company?.can_manage_other_companies && hasPermission ? <Outlet /> : <Navigate to='/' />;
};

export default Supervision;
