import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import attachmentService from "../../../../../../../services/attachmentService";
import CompanyAttachmentCreateForm from "../../../../../../_forms/attachments/company/CompanyAttachmentCreateForm";
import { companyAttachmentFormConfig } from "../../../../../../_forms/attachments/company/companyAttachmentFormConfig";
import { CompanyContext } from "../../Company";

const CompanyAttachmentCreate = () => {
    const navigate = useNavigate();
    const { company } = useContext(CompanyContext);

    return (
        <Formik
            validationSchema={companyAttachmentFormConfig.createValidationSchema}
            initialValues={companyAttachmentFormConfig.createInitialValues}
            onSubmit={(values, { setSubmitting }) => {
                attachmentService
                    .companyStore(company?.uuid, values)
                    .then(res => {
                        toast.push(<Notification title='Allegato caricato con successo' type='success' />);
                        navigate("../" + res.uuid);
                    })
                    .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                    .finally(() => setSubmitting(false));

                setSubmitting(false);
            }}
        >
            {({ isValid, isSubmitting, resetForm }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <CompanyAttachmentCreateForm />
                        <div className='gap-4 flex justify-end'>
                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                            <CancelButton handleClick={() => resetForm()} />
                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CompanyAttachmentCreate;
