import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import React from "react";

import Button from "../Button";

const SaveButton = ({ disabled, loading, label, handleClick }) => {
    return (
        <Button
            type={handleClick ? "button" : "submit"}
            disabled={disabled}
            loading={loading}
            size='sm'
            variant='solid'
            color='sky'
            icon={<HiCheck />}
            onClick={handleClick}
        >
            {label || "Salva"}
        </Button>
    );
};

export default SaveButton;
