import { formatISO9075 } from "date-fns";

import api from "../configs/api.config";

const UPLOAD_BASE_URL = "/uploads";
const fetchAll = params => api.get(UPLOAD_BASE_URL, { params }).then(res => res.data);
const fetch = uploadUuid => api.get(`${UPLOAD_BASE_URL}/${uploadUuid}`).then(res => res.data.data);
const store = params => api.post(`${UPLOAD_BASE_URL}`, params).then(res => res.data.data);
const update = (uploadUuid, params) => api.put(`${UPLOAD_BASE_URL}/${uploadUuid}`, params).then(res => res.data.data);
const complete = uploadUuid => api.patch(`${UPLOAD_BASE_URL}/${uploadUuid}/complete`).then(res => res.data.data);
const destroy = uploadUuid => api.delete(`${UPLOAD_BASE_URL}/${uploadUuid}`);
const storeDocument = (uploadUuid, params) => {
    let formData = new FormData();
    formData.append("file", params.file);

    return api
        .post(`${UPLOAD_BASE_URL}/${uploadUuid}/documents`, formData, { headers: { "Content-Type": "multipart/form-data" } })
        .then(res => res.data.data);
};
const updateDocument = (uploadUuid, documentUuid, params) =>
    api.put(`${UPLOAD_BASE_URL}/${uploadUuid}/documents/${documentUuid}`, params).then(res => res.data.data);
const destroyDocument = (uploadUuid, documentUuid) =>
    api.delete(`${UPLOAD_BASE_URL}/${uploadUuid}/documents/${documentUuid}`).then(res => res.data.data);

const mapUploadValuesToApi = (upload, values) => ({
    payload: {
        auto_publish_at: upload?.payload?.auto_publish_at ? formatISO9075(new Date(upload?.payload?.auto_publish_at)) : null,
        month: upload?.payload?.month?.value || null,
        year: upload?.payload?.year?.value || null,
    },
    has_scheduled_notification: values?.has_scheduled_notification,
    scheduled_notification: {
        notify_at:
            values?.has_scheduled_notification && values?.scheduled_notification?.notify_at
                ? formatISO9075(new Date(values.scheduled_notification.notify_at))
                : null,
        notification_text: values?.has_scheduled_notification ? values.scheduled_notification.notification_text : "",
        show_upload_file_list: values?.has_scheduled_notification ? values.scheduled_notification.show_upload_file_list : false,
        send_to_company_email: values?.has_scheduled_notification ? values.scheduled_notification.send_to_company_email : false,
        send_to_company_admin_email: values?.has_scheduled_notification ? values.scheduled_notification.send_to_company_admin_email : false,
        send_ccn_to_sysadmin:
            values?.has_scheduled_notification && values.scheduled_notification.send_to_company_email
                ? values.scheduled_notification.send_ccn_to_sysadmin
                : false,
        send_to_company_supervisor_email: values?.has_scheduled_notification ? values.scheduled_notification.send_to_company_supervisor_email : false,
        send_to_company_delegati_email: values?.has_scheduled_notification ? values.scheduled_notification.send_to_company_delegati_email : false,
        send_to_company_pec: values?.has_scheduled_notification ? values.scheduled_notification.send_to_company_pec : false,
    },
});

const mapDocumentValuesToApi = values => ({
    document_type_id: values?.document_type?.id,
    expiration: values?.document_type?.is_expiration_required && values?.expiration ? formatISO9075(values?.expiration) : null,
    auto_publish_at: values?.auto_publish_at ? formatISO9075(new Date(values?.auto_publish_at)) : null,
});

const uploadService = {
    fetchAll,
    fetch,
    store,
    update,
    complete,
    destroy,
    storeDocument,
    updateDocument,
    destroyDocument,
    mapUploadValuesToApi,
    mapDocumentValuesToApi,
};

export default uploadService;
