import api from "../configs/api.config";

const csrfCookie = () => api.get("../csrf-cookie");
const signIn = (email, password, rememberMe = false) => api.post("login", { email, password, rememberMe }).then(res => res.data);
const forgotPassword = email => api.post("forgot-password", { email });
const resetPassword = (token, email, password, password_confirmation) =>
    api.post("reset-password", {
        token,
        email,
        password,
        password_confirmation,
    });
const register = (token, email, password, password_confirmation) => api.post("register", { token, email, password, password_confirmation });
const forceChangePassword = (current_password, password, password_confirmation) =>
    api.post("force-change-password", {
        current_password,
        password,
        password_confirmation,
    });

const authService = {
    csrfCookie,
    signIn,
    forgotPassword,
    resetPassword,
    register,
    forceChangePassword,
};

export default authService;
