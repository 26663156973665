import { Form, Formik } from "formik";
import React from "react";
import { useSanctum } from "react-sanctum";
import * as Yup from "yup";

import FormDescription from "../../../../../../components/RefactoredForms/FormDescription";
import { Button, Notification } from "../../../../../../components/ui";
import FormContainer from "../../../../../../components/ui/Form/FormContainer";
import FormRow from "../../../../../../components/ui/Form/FormRow/FormRow";
import Input from "../../../../../../components/ui/Input";
import toast from "../../../../../../components/ui/toast";
import userService from "../../../../../../services/userService";

const validationSchema = Yup.object().shape({
    name: Yup.string().max(255).required("Obbligatorio"),
});
const AccountInformationsForm = () => {
    const { user, setUser } = useSanctum();

    const initialValues = {
        name: user.name,
        email: user.email,
    };

    return (
        <>
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    userService
                        .updateProfileInformation(values)
                        .then(res => {
                            toast.push(<Notification title={"Profilo aggiornato con successo"} type='success' />);
                            setUser({
                                ...user,
                                email: values.email,
                                name: values.name,
                            });
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message} type='success' />))
                        .finally(() => {
                            setSubmitting(false);
                        });
                }}
            >
                {({ values, touched, errors, isSubmitting, valid, resetForm }) => {
                    return (
                        <Form>
                            <FormContainer>
                                <FormDescription title='Informazioni' desc='Aggiorna le tue informazioni di base.' />
                                <FormRow name='name' label='Nome' component={Input} required={true} />
                                <FormRow name='email' label='Indirizzo email' component={Input} required={true} type='email' />

                                <div className='mt-4 ltr:text-right'>
                                    <Button className='ltr:mr-2 rtl:ml-2' type='button' onClick={resetForm}>
                                        Annulla
                                    </Button>
                                    <Button variant='solid' loading={isSubmitting} type='submit'>
                                        {isSubmitting ? "Salvataggio..." : "Salva"}
                                    </Button>
                                </div>
                            </FormContainer>
                        </Form>
                    );
                }}
            </Formik>
        </>
    );
};

export default AccountInformationsForm;
