import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import { useFormikContext } from "formik";
import React from "react";

import Button from "../Buttons";

const FormikSubmitButton = ({ label }) => {
    const { isValid, isSubmitting, dirty } = useFormikContext();

    return (
        <Button type='submit' disabled={!isValid || !dirty} loading={isSubmitting} size='sm' variant='solid' color='sky' icon={<HiCheck />}>
            {label || "Salva"}
        </Button>
    );
};

export default FormikSubmitButton;
