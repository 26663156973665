import api from "../configs/api.config";

const PUBLIC_COMPANY_BASE_URL = "/public/companies";

const getPublicCompanyDashboardUrl = (uuid, fullUrl) =>
    fullUrl ? `${window.location.origin.toString()}/public/companies/${uuid}` : `/public/companies/${uuid}`;
const getPublicCompanyAuthUrl = (uuid, fullUrl = false) => `${getPublicCompanyDashboardUrl(uuid, fullUrl)}/auth`;
const companyInfo = uuid => api.get(`${PUBLIC_COMPANY_BASE_URL}/${uuid}/info`).then(res => res.data.data);
const company = uuid => api.get(`${PUBLIC_COMPANY_BASE_URL}/${uuid}`).then(res => res.data.data);
const attachments = (uuid, params) => api.get(`${PUBLIC_COMPANY_BASE_URL}/${uuid}/attachments`, params).then(res => res.data.data);
const attachment = (uuid, attachmentUuid) => api.put(`${PUBLIC_COMPANY_BASE_URL}/${uuid}/attachments/${attachmentUuid}`).then(res => res.data.data);
const auth = (uuid, params) => api.post(`${PUBLIC_COMPANY_BASE_URL}/${uuid}/auth`, params).then(res => res.data);

const publicCompanyService = {
    getPublicCompanyAuthUrl,
    getPublicCompanyDashboardUrl,
    companyInfo,
    company,
    attachments,
    attachment,
    auth,
};

export default publicCompanyService;
