import React from "react";

import Table from "../../Table";
import TBody from "../../Table/TBody";
import THead from "../../Table/THead";
import Td from "../../Table/Td";
import Th from "../../Table/Th";
import Tr from "../../Table/Tr";
import { Skeleton } from "../../index";

const LaravelTableSkeleton = ({ rows, cols }) => {
    return (
        <Table>
            <THead>
                <Tr>
                    {[...Array(cols)].map((col, index) => (
                        <Th key={"th_" + index}>
                            <Skeleton />
                        </Th>
                    ))}
                </Tr>
            </THead>
            <TBody>
                {[...Array(rows)].map((row, index) => (
                    <Tr key={"tr_" + index}>
                        {[...Array(cols)].map((col, colIndex) => (
                            <Td key={"td_" + index + colIndex}>
                                <Skeleton />
                            </Td>
                        ))}
                    </Tr>
                ))}
            </TBody>
        </Table>
    );
};

export default LaravelTableSkeleton;
