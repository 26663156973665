import React from "react";

import FormDescription from "../../RefactoredForms/FormDescription";

const PageHeader = ({ title, description = null, icon, toolbar, loading }) => {
    return (
        <div className='flex justify-between gap-4 w-full items-center'>
            <div className='flex items-center justify-start gap-4'>
                {icon && icon}
                <FormDescription title={title} desc={description} />
            </div>

            {toolbar && <div className='flex justify-end items-center gap-4'>{toolbar}</div>}
        </div>
    );
};

export default PageHeader;
