import { HiEyeOff } from "@react-icons/all-files/hi/HiEyeOff";
import { HiSearch } from "@react-icons/all-files/hi/HiSearch";
import classNames from "classnames";
import _ from "lodash";
import React, { useEffect, useState } from "react";

import Card from "../../Card";
import Input from "../../Input";
import Skeleton from "../../Skeleton";
import { useConfig } from "../../index";

const DualListBoxBox = ({ loading, items, active, setActive }) => {
    const { themeColor, primaryColorLevel } = useConfig();
    const color = `${themeColor}-${primaryColorLevel}`;

    const [search, setSearch] = useState("");
    const [filtered, setFiltered] = useState([]);

    useEffect(() => {
        !!search && items ? setFiltered(items.filter(item => item.label.toLowerCase().includes(search.toLowerCase()))) : setFiltered(() => items);
    }, [items, search]);

    const isActive = item => _.find(active, i => i.value === item.value);
    const removeSelection = item => setActive(active.filter(i => i.value !== item.value));
    const addSelection = item => setActive([...active, item]);

    return (
        <Card className='grow'>
            <Input
                disable={loading}
                placeholder='Cerca'
                prefix={<HiSearch />}
                type='text'
                value={search}
                onChange={event => setSearch(event.target.value || "")}
                size='sm'
            />

            <div className='h-64 bg-white rounded mt-4 overflow-auto'>
                {loading && (
                    <div className='grid gap-4 grid-cols-1'>
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                        <Skeleton height={30} />
                    </div>
                )}
                {filtered.length < 1 && (
                    <div className='text-lg px-3 py-2 text-sm mt-1 flex align-middle justify-center'>
                        <HiEyeOff className='mr-1 ' />
                        Nessun elemento disponibile
                    </div>
                )}
                {filtered.map((item, index) => {
                    let active = isActive(item);

                    return (
                        <div
                            className={classNames(
                                "w-full rounded text-lg cursor-pointer px-3 py-2 text-sm mt-1",
                                active
                                    ? `bg-${color} hover:bg-${themeColor} hover:text-white text-gray-50`
                                    : `hover:text-${color} dark:hover:text-${themeColor}-100 hover:bg-gray-50`
                            )}
                            onClick={() => (active ? removeSelection(item) : addSelection(item))}
                            key={index}
                        >
                            {item.label}
                        </div>
                    );
                })}
            </div>
        </Card>
    );
};

export default DualListBoxBox;
