import React from "react";
import { HiDocumentText } from "react-icons/hi";

import dateHelper from "../../../utils/dateHelper";
import DownloadButton from "../Buttons/_components/DownloadButton";
import PreviewButton from "../Buttons/_components/PreviewButton";
import DateFormatDisplay from "../Formatter/DateFormatDisplay";
import DocumentStateDisplay from "../State/DocumentStateDisplay";

const DocumentRowShow = ({ document, loading = false }) => {
    return (
        <div className='flex items-center p-4 gap-2 hover:bg-neutral-100'>
            <div>
                <HiDocumentText color='#d4d4d4' size={100} />
                <div className='text-center'>
                    <DocumentStateDisplay state={document.status} auto_publish_at={document.auto_publish_at} />
                </div>
            </div>
            <div className='flex flex-col'>
                <div className='flex justify-start gap-2 align-middle'>
                    <div className='font-semibold text-lg text-sky-800'>{document.media?.name}</div>
                    <DownloadButton size='xs' url={document?.media?.url} label={document.media?.file_name} filename={document?.media?.file_name} />
                    <PreviewButton size='xs' url={document?.media?.url} label={document.media?.file_name} filename={document?.media?.file_name} />
                </div>

                <div className='flex flex-col gap-1'>
                    <div>Tipologia: {document?.document_type?.name}</div>
                    <div>
                        Periodo: {dateHelper.getMonth(document?.month)?.label} {document?.year}
                    </div>
                    <div>
                        Pubblicazione: <DateFormatDisplay value={document?.auto_publish_at} />
                    </div>
                    <div>
                        Scadenza: <DateFormatDisplay showExpiration={true} value={document?.expiration} />
                    </div>
                </div>
            </div>
        </div>
    );
};

export default DocumentRowShow;
