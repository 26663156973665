import { Menu } from "components/ui";
import { themeConfig } from "configs/theme.config";
import { NAV_ITEM_TYPE_COLLAPSE, NAV_ITEM_TYPE_ITEM, NAV_ITEM_TYPE_TITLE } from "constants/navigation.constant";
import React, { useEffect, useState } from "react";
import useMenuActive from "utils/hooks/useMenuActive";

import CollapsedMenuItem from "./CollapsedMenuItem";
import SingleMenuItem from "./SingleMenuItem";

const { MenuGroup } = Menu;

const MenuContent = ({ navMode, collapsed, routeKey, navigationTree = [], onMenuItemClick }) => {
    const [defaultExpandKey, setDefaultExpandKey] = useState([]);

    const { activedRoute } = useMenuActive(navigationTree, routeKey);

    useEffect(() => {
        if (defaultExpandKey.length === 0 && activedRoute?.parentKey) {
            setDefaultExpandKey([activedRoute?.parentKey]);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activedRoute?.parentKey]);

    const handleLinkClick = () => onMenuItemClick?.();

    const getNavItem = nav => {
        if (nav.subMenu.length === 0 && nav.type === NAV_ITEM_TYPE_ITEM) {
            return <SingleMenuItem key={nav.key} nav={nav} onLinkClick={handleLinkClick} sideCollapsed={collapsed} />;
        }

        if (nav.subMenu.length > 0 && nav.type === NAV_ITEM_TYPE_COLLAPSE) {
            return <CollapsedMenuItem key={nav.key} nav={nav} onLinkClick={onMenuItemClick} sideCollapsed={collapsed} />;
        }

        if (nav.type === NAV_ITEM_TYPE_TITLE) {
            if (nav.subMenu.length > 0) {
                return (
                    <MenuGroup nav={nav} key={nav.key}>
                        {nav.subMenu.map(subNav =>
                            subNav.subMenu.length > 0 ? (
                                <CollapsedMenuItem key={subNav.key} nav={subNav} onLinkClick={onMenuItemClick} sideCollapsed={collapsed} />
                            ) : (
                                <SingleMenuItem key={subNav.key} nav={subNav} onLinkClick={onMenuItemClick} sideCollapsed={collapsed} />
                            )
                        )}
                    </MenuGroup>
                );
            } else {
                <MenuGroup label={nav.title} />;
            }
        }
    };

    return (
        <Menu
            className='px-4 pb-4'
            variant={navMode}
            sideCollapsed={collapsed}
            defaultActiveKeys={activedRoute?.key ? [activedRoute.key] : []}
            defaultExpandedKeys={defaultExpandKey}
        >
            {navigationTree.map(nav => getNavItem(nav))}
        </Menu>
    );
};

MenuContent.defaultProps = {
    navMode: themeConfig.navMode,
};

export default MenuContent;
