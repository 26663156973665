import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

import FormError from "./FormError";
import FormLabel from "./FormLabel";

const FormGroup = ({ label, name, required = false, children, ...props }) => {
    const { errors } = useFormikContext();

    return (
        <div className='flex flex-col gap-2'>
            {/* LABEL */}
            {label && <FormLabel name={name} label={label} required={required} />}

            {/* CONTENT */}
            {children}

            {/* ADDITIONAL INFO IF NEEDED*/}
            {props.info && <span className='text-xs'>{props.info}</span>}

            {/* ERRORS */}
            {get(errors, name) && <FormError>{get(errors, name)}</FormError>}
        </div>
    );
};

export default FormGroup;
