import { HiCheckCircle, HiXCircle } from "react-icons/hi2";

const FormBoolDisplay = ({ value }) =>
    value ? (
        <span className='text-emerald-500'>
            <HiCheckCircle size='20' />
        </span>
    ) : (
        <span className='text-red-500'>
            <HiXCircle size='20' />
        </span>
    );

export default FormBoolDisplay;
