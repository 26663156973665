import { Link } from "react-router-dom";

import FormBoolDisplay from "../../../../../components/ui/Form/FormDisplay/FormBoolDisplay";

export const documentCategoriesCols = [
    {
        header: "Categoria",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) => (
            <Link to={original.uuid} className='font-bold'>
                {original.code} {getValue()}
            </Link>
        ),
    },
    {
        header: "Descrizione",
        accessorKey: "description",
    },
    {
        header: "Diviso per anni",
        accessorKey: "is_split_by_year",
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "Diviso per mesi",
        accessorKey: "is_split_by_month",
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "Documenti",
        accessorKey: "document_types_count",
    },
];
