import React, { useContext } from "react";
import withHeaderItem from "utils/hoc/withHeaderItem";

import { ApplicationContext } from "../../../../../../App";
import useResponsive from "../../../../../../utils/hooks/useResponsive";
import NavToggle from "../../../../../shared/NavToggle";

export const SideNavToggle = ({ className }) => {
    const { sideNavCollapse, setSideNavCollapse } = useContext(ApplicationContext);
    const onCollapse = () => setSideNavCollapse(!sideNavCollapse);
    const { larger } = useResponsive();

    return (
        <>
            {larger.md && (
                <div className={className} onClick={onCollapse}>
                    <NavToggle className='text-2xl' toggled={sideNavCollapse} />
                </div>
            )}
        </>
    );
};

export default withHeaderItem(SideNavToggle);
