import isEmpty from "lodash/isEmpty";
import { useMemo } from "react";

function usePermissions(userPermissions = [], permissions = [], emptyCheck = false) {
    const permissionsMatch = useMemo(() => {
        return permissions.some(permission => userPermissions.includes(permission));
    }, [permissions, userPermissions]);

    if (isEmpty(permissions) || isEmpty(userPermissions) || typeof permissions === "undefined") {
        return !emptyCheck;
    }

    return permissionsMatch;
}

export default usePermissions;
