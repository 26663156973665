import {GiSkullCrossedBones} from "@react-icons/all-files/gi/GiSkullCrossedBones";
import {format, isBefore, parseISO} from "date-fns";
import React from "react";

import {Tooltip} from "../index";

const DateFormatDisplay = ({value, formatString = "dd/MM/yyyy", showExpiration = false}) => {
    let expired = value && showExpiration ? isBefore(parseISO(value), new Date()) : undefined;

    return (
        <div className={`flex justify-start items-center ${expired && "text-red-500 font-bold"}`}>
            {expired === true && (<Tooltip title='Il documento è scaduto'>
                <GiSkullCrossedBones color='#ef4444' size={20}/>
            </Tooltip>)}
            {value ? format(parseISO(value), formatString) : "---"}
        </div>);
};
export default DateFormatDisplay;
