import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import SweetAlert from "sweetalert2";

import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import companySupervisionService from "../../../../../../../services/companySupervisionService";
import SupervisorEditForm from "../../../../../../_forms/supervision/SupervisorEditForm";
import companySupervisionFormConfig from "../../../../../../_forms/supervision/companySupervisionFormConfig";
import { CompanyContext } from "../../Company";

const SupervisorShow = ({ setSupervisor, supervisor, loading }) => {
    const { company } = useContext(CompanyContext);
    const [initialValues, setInitialValues] = useState([]);

    const handleMessagePopUp = () => {
        SweetAlert.fire({
            icon: "warning",
            text: "Sei sicuro di voler rimuovere il delegato dall'azienda?",
        }).then(res => {
            if (res.isConfirmed) {
                handleDelete();
            }
        });
    };

    const handleDelete = () => {
        companySupervisionService
            .destroy(company?.uuid)
            .then(res => {
                toast.push(<Notification title='Delegato rimosso con successo' type='success' />);
                setSupervisor(undefined);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    useEffect(() => {
        if (supervisor) {
            setInitialValues(companySupervisionFormConfig.setInitialValues(supervisor));
        }
    }, [supervisor]);

    return (
        <Formik
            validationSchema={companySupervisionFormConfig.validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                companySupervisionService
                    .update(company?.uuid, {
                        ...values,
                        document_types: values.document_types,
                    })
                    .then(res => {
                        toast.push(<Notification title='Tipologie documenti aggiornate con successo' type='success' />);
                        setSupervisor(res);
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, isSubmitting, resetForm }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <SupervisorEditForm
                            companyName={company?.business_name}
                            loading={loading}
                            supervisor={supervisor}
                            onCancel={handleMessagePopUp}
                        />
                        <div className='gap-4 flex justify-end'>
                            <CancelButton disabled={!isValid || isSubmitting} handleClick={() => resetForm()} />
                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SupervisorShow;
