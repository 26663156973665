import _ from "lodash";
import { useEffect, useMemo, useState } from "react";

import DualListBoxActions from "./_components/DualListBoxActions";
import DualListBoxBox from "./_components/DualListBoxBox";

const DualListBox = ({ loading, options, selected, onChange, optionTitle, selectedTitle }) => {
    const [values, setValues] = useState({
        activeOptionItems: [],
        activeSelectedItems: [],
    });

    const optionItems = useMemo(() => _.differenceBy(options, selected, "value"), [options, selected]);

    const handleAddClicked = () => {
        onChange(_.union(selected, values.activeOptionItems));
    };
    const handleAddAllClicked = () => {
        onChange(options);
    };
    const handleRemoveClicked = () => {
        onChange(_.differenceBy(selected, values.activeSelectedItems, "value"));
    };
    const handleRemoveAllClicked = () => {
        onChange([]);
    };

    const handleSetOptionItemsActive = actives =>
        setValues(() => {
            return {
                ...values,
                activeOptionItems: actives,
            };
        });

    const handleSetSelectedItemsActive = actives =>
        setValues(() => {
            return {
                ...values,
                activeSelectedItems: actives,
            };
        });

    useEffect(() => {
        setValues({
            activeOptionItems: [],
            activeSelectedItems: [],
        });
    }, [selected, options]);

    return (
        <div className='flex gap-4'>
            <div className={"flex-grow"}>
                {optionTitle && <h6 className='text-sky-800 mb-3'>{optionTitle}</h6>}
                <DualListBoxBox loading={loading} items={optionItems} active={values.activeOptionItems} setActive={handleSetOptionItemsActive} />
            </div>

            <DualListBoxActions
                addDisabled={loading || optionItems.length < 1 || values.activeOptionItems.length < 1}
                addAllDisabled={loading || optionItems.length < 1}
                removeDisabled={loading || selected.length < 1 || values.activeSelectedItems.length < 1}
                removeAllDisabled={loading || selected.length < 1}
                onAddClicked={handleAddClicked}
                onAddAllClicked={handleAddAllClicked}
                onRemoveClicked={handleRemoveClicked}
                onRemoveAllClicked={handleRemoveAllClicked}
            />

            <div className={"flex-grow"}>
                {selectedTitle && <h6 className='text-sky-800 mb-3'>{selectedTitle}</h6>}
                <DualListBoxBox loading={loading} items={selected} active={values.activeSelectedItems} setActive={handleSetSelectedItemsActive} />
            </div>
        </div>
    );
};

export default DualListBox;

/*



 */
