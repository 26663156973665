import Swal from "sweetalert2";

export default function useSweetAlert() {
    const SweetAlert = Swal.mixin({
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: "Si, continua",
        cancelButtonText: "No, annulla",
        customClass: {
            confirmButton:
                "button bg-sky-50 dark:bg-sky-500 dark:bg-opacity-20 hover:bg-sky-100 dark:hover:bg-sky-500 dark:hover:bg-opacity-30 active:bg-sky-200 dark:active:bg-sky-500 dark:active:bg-opacity-40 text-sky-800 dark:text-sky-50 radius-round h-11 px-8 py-2 mr-2",
            cancelButton:
                "button bg-transparent border border-transparent hover:bg-gray-50 dark:hover:bg-gray-600 active:bg-gray-100 dark:active:bg-gray-500 dark:active:border-gray-500 text-gray-600 dark:text-gray-100 radius-round h-11 px-8 py-2",
        },
    });

    return {
        SweetAlert,
    };
}
