import { Outlet } from "react-router-dom";

import AuthLayout from "../components/layout/AuthLayout/AuthLayout";
import BlankLayout from "../components/layout/BlankLayout";
import ProtectedLayout from "../components/layout/ProtectedLayout/ProtectedLayout";
import publicCompanyService from "../services/publicCompanyService";
import { SanctumToken } from "../utils/SanctumToken/SanctumToken";
import AuthGuard from "./AuthGuard";
import authRoutes from "./auth/authRoutes";
import protectedRoutes from "./protected/protectedRoutes";
import publicRoutes from "./public/publicRoutes";

const routes = [
    {
        element: <BlankLayout />,
        children: [
            // Accesso tramite sanctum token all'area mediante password aziendale
            {
                path: publicCompanyService.getPublicCompanyDashboardUrl(":id"),
                element: (
                    <SanctumToken>
                        <Outlet />
                    </SanctumToken>
                ),
                children: publicRoutes,
            },
            // Pubblico
            {
                path: "/auth/*",
                element: (
                    <AuthGuard auth={false} redirect='/'>
                        <AuthLayout />
                    </AuthGuard>
                ),
                children: authRoutes,
            },
            // Privato
            {
                path: "/*",
                element: (
                    <AuthGuard auth={true} redirect='/auth/login'>
                        <ProtectedLayout />
                    </AuthGuard>
                ),
                children: protectedRoutes,
            },
        ],
    },
];

export default routes;
