import React from "react";
import { HiXMark } from "react-icons/hi2";

import Button from "../Button";

const CancelButton = ({ disabled, loading, label, handleClick }) => {
    return (
        <Button
            type='button'
            disabled={disabled}
            loading={loading}
            size='sm'
            variant='solid'
            color='neutral-100'
            icon={<HiXMark color={"#a3a3a3"} />}
            onClick={handleClick}
        >
            <span className='text-neutral-400'>{label || "Reset"}</span>
        </Button>
    );
};

export default CancelButton;
