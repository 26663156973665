import { FaPowerOff } from "@react-icons/all-files/fa/FaPowerOff";
import { useRequest } from "ahooks";
import React, { useContext } from "react";
import { Outlet, useParams } from "react-router-dom";

import { sessionConfig } from "../../../configs/session.config";
import publicCompanyService from "../../../services/publicCompanyService";
import { SanctumTokenContext } from "../../../utils/SanctumToken/SanctumToken";
import { Button } from "../../ui";
import PageContainer from "../ProtectedLayout/_components/PageContainer";
import Header from "./_components/Header";
import { HeaderStart } from "./_components/HeaderStart";

export const PublicContext = React.createContext();

const PublicLayout = () => {
    const { id } = useParams();
    const { setTokenValid } = useContext(SanctumTokenContext);

    const { data: company, loading: companyLoading } = useRequest(publicCompanyService.company, { defaultParams: [id] });
    const { data: attachments, loading: attachmentsLoading } = useRequest(publicCompanyService.attachments, { defaultParams: [id] });

    const contextValue = {
        company,
        attachments,
        companyLoading,
        attachmentsLoading,
    };

    const handleLogout = () => {
        sessionConfig.clear();
        setTokenValid(false);
    };

    return (
        <PublicContext.Provider value={contextValue}>
            <div className='app-layout-classic flex flex-auto flex-col'>
                <div className='flex flex-auto min-w-0'>
                    <div className='flex flex-col flex-auto min-h-screen min-w-0 relative w-full'>
                        <Header
                            className='shadow dark:shadow-2xl'
                            headerStart={<HeaderStart />}
                            headerEnd={
                                <Button size='sm' variant='plain' icon={<FaPowerOff />} onClick={() => handleLogout()}>
                                    Esci
                                </Button>
                            }
                        />
                        <div className='h-full flex flex-auto flex-col'>
                            <PageContainer>
                                <Outlet />
                            </PageContainer>
                        </div>
                    </div>
                </div>
            </div>
        </PublicContext.Provider>
    );
};

export default PublicLayout;
