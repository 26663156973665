import { Alert, Button, FormContainer, FormItem } from "components/ui";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useSearchParams } from "react-router-dom";
import authService from "services/authService";
import useTimeOutMessage from "utils/hooks/useTimeOutMessage";
import * as Yup from "yup";

import PasswordInput from "../../../components/shared/PasswordInput";
import FormDisplay from "../../../components/ui/Form/FormDisplay/FormDisplay";

const validationSchema = Yup.object().shape({
    password: Yup.string().min(8).required("Obbligatorio"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "La password non coincide")
        .required("Obbligatorio"),
});

const AcceptInviteForm = props => {
    const [message, setMessage] = useTimeOutMessage();
    const [searchParams] = useSearchParams();

    const token = searchParams.get("token");
    const email = searchParams.get("email");

    const initialValues = {
        password: "",
        password_confirmation: "",
    };

    return (
        <div>
            {message && (
                <Alert className='mb-4' type='danger' showIcon>
                    {message}
                </Alert>
            )}

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting, resetForm }) => {
                    setSubmitting(true);
                    authService
                        .register(token, email, values.password, values.password_confirmation)
                        .then(res => window.location.reload())
                        .catch(err => setMessage(err.response.data.message))
                        .finally(() => {
                            setSubmitting(false);
                            resetForm();
                        });
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form>
                        <FormContainer>
                            <FormDisplay label='Indirizzo email'>{email}</FormDisplay>
                            <FormItem label='Password' invalid={errors.password && touched.password} errorMessage={errors.password}>
                                <Field autoComplete='off' name='password' placeholder='Password' component={PasswordInput} />
                            </FormItem>
                            <FormItem
                                label='Conferma password'
                                invalid={errors.password_confirmation && touched.password_confirmation}
                                errorMessage={errors.password_confirmation}
                            >
                                <Field autoComplete='off' name='password_confirmation' placeholder='Conferma password' component={PasswordInput} />
                            </FormItem>

                            <Button block loading={isSubmitting} variant='solid' type='submit'>
                                {isSubmitting ? "Registrazione..." : "Registrati"}
                            </Button>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default AcceptInviteForm;
