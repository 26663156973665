import React from "react";

import Supervision from "../../../../views/protected/myCompany/supervision/Supervision";
import SupervisorShow from "../../../../views/protected/myCompany/supervision/SupervisorShow";

const supervisionsRoutes = {
    element: <Supervision />,
    children: [
        {
            path: "",
            index: true,
            element: <SupervisorShow />,
        },
    ],
};

export default supervisionsRoutes;
