import PropTypes from "prop-types";
import React, { useEffect } from "react";

import { useConfig } from "../../ConfigProvider";

const LaravelTableCheckbox = ({ onChange, checked, indeterminate }) => {
    const ref = React.useRef(null);
    const { themeColor, primaryColorLevel } = useConfig();
    const checkboxColor = `checkbox text-${themeColor}-${primaryColorLevel}`;

    useEffect(() => {
        if (typeof indeterminate === "boolean") {
            ref.current.indeterminate = checked && indeterminate;
        }
    }, [ref, indeterminate]);

    return (
        <>
            <input className={checkboxColor} type='checkbox' ref={ref} checked={checked} onChange={onChange} />
        </>
    );
};

LaravelTableCheckbox.propTypes = {
    checked: PropTypes.bool,
    indeterminate: PropTypes.bool,
    onChange: PropTypes.func,
};

LaravelTableCheckbox.defaultProps = {
    checked: false,
    indeterminate: false,
    onChange: () => {},
};

export default LaravelTableCheckbox;
