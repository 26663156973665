import { useFormikContext } from "formik";
import React from "react";

import FormCheckbox from "../../../../components/RefactoredForms/FormCheckbox";
import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import { Upload } from "../../../../components/ui";

const CompanyAttachmentCreateForm = () => {
    const { values, setFieldValue } = useFormikContext();

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='Nome allegato' required>
                    <FormInput name='name' />
                </FormGroup>
                <FormGroup label='Descrizione allegato' name='description' required>
                    <FormInput name='description' />
                </FormGroup>
                <FormGroup name={"is_public"} label={"Visibilità"}>
                    <FormCheckbox name={"is_public"} label={"Spunta per rendere il file visibili all'esterno della piattaforma"} />
                </FormGroup>
            </div>
            <FormGroup label='Allegato' name='file' required>
                <Upload
                    label='Carica file'
                    showList={true}
                    multiple={false}
                    draggable={false}
                    uploadLimit={1}
                    fileList={values.file}
                    onChange={file => setFieldValue("file", file)}
                    onFileRemove={() => setFieldValue("file", undefined)}
                />
            </FormGroup>
        </div>
    );
};

export default CompanyAttachmentCreateForm;
