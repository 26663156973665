import React from "react";

import AcceptInviteForm from "./AcceptInviteForm";

const AcceptInvite = () => {
    return (
        <>
            <div className='mb-8'>
                <h3 className='mb-1'>Benvenuto.</h3>
                <p>Completa le informazioni per accedere alla piattaforma.</p>
            </div>
            <AcceptInviteForm />
        </>
    );
};

export default AcceptInvite;
