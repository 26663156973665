import React from "react";

const DisplayTitle = ({ title, icon = null, description = null, info = null, toolbar = null }) => (
    <div className='flex flex-row items-center justify-start gap-2'>
        <div>{icon}</div>
        <div className='flex flex-col flex-grow'>
            <div className='flex align-middle justify-between'>
                <h5>{title}</h5>
                {toolbar}
            </div>
            {description && <p>{description}</p>}
            {info && <small>{info}</small>}
        </div>
    </div>
);

export default DisplayTitle;
