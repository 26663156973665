import { Form, Formik } from "formik";
import React, { useContext } from "react";

import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import officeService from "../../../../../../../services/officeService";
import { CompanyContext } from "../../Company";
import CompanyOfficeForm from "./_components/CompanyOfficeForm";
import companyOfficeFormConfig from "./_components/companyOfficeFormConfig";

const CompanyOfficeCreate = ({ onCreate }) => {
    const { company } = useContext(CompanyContext);

    return (
        <Formik
            validationSchema={companyOfficeFormConfig.validationSchema}
            initialValues={companyOfficeFormConfig.initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                officeService
                    .store(company.uuid, values)
                    .then(res => {
                        toast.push(<Notification title='Sede creata con successo' type='success' />);
                        onCreate();
                        resetForm();
                    })
                    .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, isSubmitting, resetForm }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <CompanyOfficeForm />

                        <div className='gap-4 flex justify-end'>
                            <CancelButton disabled={isSubmitting} handleClick={() => resetForm()} />
                            <SaveButton loading={isSubmitting} disabled={!isValid || isSubmitting} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CompanyOfficeCreate;
