import React from "react";
import { useSanctum } from "react-sanctum";

import AccountAvatarUploader from "./_components/AccountAvatarUploader";
import AccountInformationsForm from "./_components/AccountInformationsForm";

const AccountInformations = () => {
    const { user, setUser } = useSanctum();

    return (
        <>
            <AccountAvatarUploader />
            <AccountInformationsForm />
        </>
    );
};

export default AccountInformations;
