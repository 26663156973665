import { HiChevronRight } from "@react-icons/all-files/hi/HiChevronRight";
import React, { useContext } from "react";

import logoYellowSquareBlueNoText from "../../../../assets/logos/logoYellowSquareBlueNoText.svg";
import { PublicContext } from "../PublicLayout";

export const HeaderStart = () => {
    const { company } = useContext(PublicContext);

    return (
        <div className='flex align-middle justify-center items-center'>
            <img className='h-8 w-8 mr-2' src={logoYellowSquareBlueNoText} />
            <span className='font-bold text-sky-700'>{process.env.REACT_APP_NAME}</span>
            <HiChevronRight />
            <span className='font-semibold'>{company?.business_name}</span>
        </div>
    );
};
