import * as Yup from "yup";

const initialValues = {
    name: "",
    permissions: [],
};

const validationSchema = Yup.object().shape({
    name: Yup.string().max(190).required("Obbligatorio"),
    permissions: Yup.array().min(1).required(),
});

const setInitialValues = role => {
    return {
        name: role?.name || "",
        permissions: role?.permissions || [],
    };
};

const roleFormConfig = {
    initialValues,
    validationSchema,
    setInitialValues,
};

export default roleFormConfig;
