import api from "../configs/api.config";

const fetch = uuid => api.get(`companies/${uuid}/supervision`).then(res => res.data.data);
const store = (uuid, params) => api.post(`companies/${uuid}/supervision`, params).then(res => res.data.data);
const update = (uuid, params) => api.put(`companies/${uuid}/supervision`, params).then(res => res.data.data);
const destroy = uuid => api.delete(`companies/${uuid}/supervision`).then(res => res.data);

const companySupervisionService = {
    fetch,
    store,
    update,
    destroy,
};

export default companySupervisionService;
