import React from "react";

import Badge from "../Badge";

const StateDisplay = ({ state }) => {
    if (state) return <Badge content={state.label} innerClass={`bg-${state.color}-500 font-semibold text-${state.color}-50`} />;
    return <></>;
};

export default StateDisplay;
