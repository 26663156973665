import React from "react";

import UploadCreate from "../../../../views/protected/admin/uploads/UploadCreate";
import Uploads from "../../../../views/protected/admin/uploads/Uploads";
import UploadsIndex from "../../../../views/protected/admin/uploads/UploadsIndex";
import Upload from "../../../../views/protected/admin/uploads/upload/Upload";

const uploadsRoutes = {
    element: <Uploads />,
    children: [
        {
            path: "create",
            element: <UploadCreate />,
        },
        {
            path: ":id/*",
            element: <Upload />,
        },
        {
            path: "",
            index: true,
            element: <UploadsIndex />,
        },
    ],
};

export default uploadsRoutes;
