import React from "react";

import FormDescription from "../../../../components/RefactoredForms/FormDescription";
import FormRowDisplay from "../../../../components/ui/Form/FormRow/FormRowDisplay";

const MyCompanyOffice = ({ office }) => {
    return (
        <>
            <FormDescription title='Informazioni generali' />
            <FormRowDisplay label='Nome e cognome referente'>{office?.referent_name}</FormRowDisplay>
            <FormRowDisplay label='Recapito telefonico'>{office?.phone}</FormRowDisplay>
            <FormRowDisplay label='Indirizzo email'>{office?.email}</FormRowDisplay>
            <FormRowDisplay label='CIG'>{office?.cig}</FormRowDisplay>

            <FormDescription title='Indirizzo' />
            <FormRowDisplay label='Indirizzo'>{office?.address?.address}</FormRowDisplay>
            <FormRowDisplay label='Numero civico'>{office?.address?.street_number}</FormRowDisplay>
            <FormRowDisplay label='Città'>{office?.address?.city}</FormRowDisplay>
            <FormRowDisplay label='Cap'>{office?.address?.postal_code}</FormRowDisplay>
            <FormRowDisplay label='Nazione'>{office?.address?.country}</FormRowDisplay>

            <FormDescription title='Altro' />
            <FormRowDisplay label='Matricola INPS'>{office?.inps_number}</FormRowDisplay>
            <FormRowDisplay label='Pat 1 INAIL'>{office?.inail_primary_pat}</FormRowDisplay>
            <FormRowDisplay label='Pat 2 INAIL'>{office?.inail_secondary_pat}</FormRowDisplay>
            <FormRowDisplay label='Codice ateco'>{office?.ateco_code}</FormRowDisplay>
            <FormRowDisplay label='CCNL'>{office?.ccnl}</FormRowDisplay>
            <FormRowDisplay label='CSC'>{office?.csc}</FormRowDisplay>
            <FormRowDisplay label='Classificazione INPS'>{office?.inps_classification}</FormRowDisplay>
            <FormRowDisplay label='Assistenza sanitaria'>{office?.health_care}</FormRowDisplay>
            <FormRowDisplay label='Ente bilaterale'>{office?.bilateral_institution}</FormRowDisplay>
            <FormRowDisplay label='Solidarietà bilaterale'>{office?.bilateral_solidarity}</FormRowDisplay>
        </>
    );
};

export default MyCompanyOffice;
