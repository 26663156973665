import { Form, Formik } from "formik";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormGroup from "../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../components/RefactoredForms/FormInput";
import { Card, Notification, toast } from "../../../../../../components/ui";
import CancelButton from "../../../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../../../components/ui/Page/PageHeader";
import companyGroupService from "../../../../../../services/companyGroupService";
import { CompanyGroupContext } from "../CompanyGroupShow";

const CompanyGroupEdit = () => {
    const { companyGroup, setCompanyGroup } = useContext(CompanyGroupContext);
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
    });

    const initialValues = {
        name: companyGroup?.name || "",
    };

    const handleDelete = () => {
        companyGroupService
            .destroy(companyGroup.uuid)
            .then(res => {
                toast.push(<Notification title='Gruppo eliminato con successo' type='success' />);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />));
    };

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                companyGroupService
                    .update(companyGroup.uuid, values)
                    .then(res => {
                        toast.push(<Notification title='Gruppo modificato con successo' type='success' />);
                        setCompanyGroup(res);
                    })
                    .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, isSubmitting, submitForm, resetForm }) => (
                <Form>
                    <Card
                        header={
                            <PageHeader
                                title={`Modifica ${companyGroup.name}`}
                                description={"Modifica o elimina il gruppo aziende."}
                                toolbar={
                                    <>
                                        <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                                        <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        <DestroyButton
                                            message={`Sei sicuro di voler eliminare il gruppo ${companyGroup?.name}?`}
                                            handleClick={() => handleDelete()}
                                        />
                                    </>
                                }
                            />
                        }
                    >
                        <div className='flex flex-col gap-4'>
                            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                <FormGroup label='Nome' name='name' required>
                                    <FormInput name={"name"} />
                                </FormGroup>
                            </div>
                        </div>
                    </Card>
                </Form>
            )}
        </Formik>
    );
};

export default CompanyGroupEdit;
