import React from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import usePermissions from "../../../../utils/hooks/usePermissions";

const Roles = () => {
    const { user } = useSanctum();

    const hasPermission = usePermissions(user.role.permissions, ["roles.view"]);

    return hasPermission ? <Outlet /> : <Navigate to='/' />;
};

export default Roles;
