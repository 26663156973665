import { Dropdown, Menu } from "components/ui";
import React from "react";
import { Link, matchPath, matchRoutes, useMatch } from "react-router-dom";

import PermissionsCheck from "../../../../shared/PermissionsCheck";
import MenuIcon from "./MenuIcon";

const { MenuItem, MenuCollapse } = Menu;

const DefaultItem = ({ nav, onLinkClick }) => {
    const match = useMatch(nav.path);

    const routeMatches = matchRoutes(nav.subMenu, window.location.pathname);

    return (
        <PermissionsCheck
            is_system_admin={nav.is_system_admin}
            can_manage_other_companies={nav?.can_manage_other_companies}
            is_supervised={nav?.is_supervised}
            permissions={nav.permissions || []}
        >
            <MenuCollapse
                label={
                    <>
                        <MenuIcon icon={nav.icon} />
                        <span>{nav.title}</span>
                    </>
                }
                key={nav.key}
                eventKey={nav.key}
                expanded={!!routeMatches}
                className='mb-2'
            >
                {nav.subMenu.map(subNav => (
                    <PermissionsCheck
                        is_system_admin={nav.is_system_admin}
                        can_manage_other_companies={nav?.can_manage_other_companies}
                        is_supervised={nav?.is_supervised}
                        permissions={nav.permissions || []}
                        key={subNav.key}
                    >
                        <MenuItem eventKey={subNav.key} isActive={!!matchPath({ path: subNav.path }, window.location.pathname)}>
                            {subNav.path ? (
                                <Link
                                    className='h-full w-full flex items-center'
                                    onClick={() =>
                                        onLinkClick?.({
                                            key: subNav.key,
                                            title: subNav.title,
                                            path: subNav.path,
                                        })
                                    }
                                    to={subNav.path}
                                >
                                    <span>{subNav.title}</span>
                                </Link>
                            ) : (
                                <span>{subNav.title}</span>
                            )}
                        </MenuItem>
                    </PermissionsCheck>
                ))}
            </MenuCollapse>
        </PermissionsCheck>
    );
};

const CollapsedItem = ({ nav, onLinkClick }) => {
    const menuItem = (
        <MenuItem key={nav.key} eventKey={nav.key} className='mb-2'>
            <MenuIcon icon={nav.icon} />
        </MenuItem>
    );

    return (
        <PermissionsCheck
            is_system_admin={nav.is_system_admin}
            can_manage_other_companies={nav?.can_manage_other_companies}
            is_supervised={nav?.is_supervised}
            permissions={nav.permissions || []}
        >
            <Dropdown trigger='hover' renderTitle={menuItem} placement='middle-start-top'>
                {nav.subMenu.map(subNav => (
                    <PermissionsCheck is_system_admin={nav.is_system_admin} permissions={nav.permissions || []} key={subNav.key}>
                        <Dropdown.Item eventKey={subNav.key}>
                            {subNav.path ? (
                                <Link
                                    className='h-full w-full flex items-center'
                                    onClick={() =>
                                        onLinkClick?.({
                                            key: subNav.key,
                                            title: subNav.title,
                                            path: subNav.path,
                                        })
                                    }
                                    to={subNav.path}
                                >
                                    <span>{subNav.title}</span>
                                </Link>
                            ) : (
                                <span>{subNav.title}</span>
                            )}
                        </Dropdown.Item>
                    </PermissionsCheck>
                ))}
            </Dropdown>
        </PermissionsCheck>
    );
};

const CollapsedMenuItem = ({ sideCollapsed, ...rest }) => {
    return sideCollapsed ? <CollapsedItem {...rest} /> : <DefaultItem {...rest} />;
};

export default CollapsedMenuItem;
