import { HiPhone } from "@react-icons/all-files/hi/HiPhone";
import React from "react";

import PageHeader from "../Page/PageHeader";
import { Card } from "../index";

const BaseCompanyContacts = ({ company, referent_name = null, referent_contact = null }) => {
    return (
        <Card className='flex items-center justify-start flex-1'>
            <PageHeader
                title={referent_name || company?.company_data?.referent_name || "Nessun referente"}
                description={referent_contact || company?.company_data?.phone || "Nessun numero di telefono"}
                icon={<HiPhone className='text-neutral-300' size={30} />}
            />
        </Card>
    );
};

export default BaseCompanyContacts;
