import React from "react";

import ResetPasswordForm from "./ResetPasswordForm";

const ResetPassword = () => {
    return (
        <>
            <div className='mb-8'>
                <h3 className='mb-1'>Reset password</h3>
                <p>Imposta la tua nuova password.</p>
            </div>
            <ResetPasswordForm />
        </>
    );
};

export default ResetPassword;
