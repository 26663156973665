import { Form, Formik } from "formik";
import React, { useContext, useState } from "react";

import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import companySupervisionService from "../../../../../../../services/companySupervisionService";
import companySupervisionFormConfig from "../../../../../../_forms/supervision/companySupervisionFormConfig";
import { CompanyContext } from "../../Company";
import SupervisorCreateForm from "./_components/SupervisorCreateForm";

const SupervisorCreate = ({ setSupervisor }) => {
    const { company } = useContext(CompanyContext);

    const [selectedCompany, setSelectedCompany] = useState(null);

    return (
        <Formik
            validationSchema={companySupervisionFormConfig.validationSchema}
            validateOnMount={true}
            initialValues={companySupervisionFormConfig.initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                companySupervisionService
                    .store(company.uuid, values)
                    .then(res => {
                        toast.push(<Notification title='Delegato assegnato con successo' type='success' />);
                        setSupervisor(res);
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, isSubmitting, resetForm }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <SupervisorCreateForm
                            setSupervisor={setSupervisor}
                            selectedCompany={selectedCompany}
                            setSelectedCompany={setSelectedCompany}
                        />
                        <div className='gap-4 flex justify-end'>
                            <CancelButton
                                disabled={!isValid || isSubmitting}
                                handleClick={() => {
                                    setSelectedCompany(null);
                                    resetForm();
                                }}
                            />
                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default SupervisorCreate;
