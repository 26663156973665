import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";

import publicCompanyService from "../../services/publicCompanyService";

export const SanctumTokenContext = React.createContext({
    tokenValid: null,
    setTokenValid: value => {},
});
export const SanctumToken = ({ children }) => {
    const [tokenValid, setTokenValid] = useState(null);

    const contextValue = {
        tokenValid,
        setTokenValid,
    };

    const { id } = useParams();

    useEffect(() => {
        if (id) {
            publicCompanyService
                .company(id)
                .then(() => setTokenValid(true))
                .catch(() => setTokenValid(false));
        }
    }, []);

    return <SanctumTokenContext.Provider value={contextValue}>{children}</SanctumTokenContext.Provider>;
};
