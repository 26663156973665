import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import PermissionsCheck from "../../../../components/shared/PermissionsCheck";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import roleService from "../../../../services/roleService";
import { rolesCols } from "./_components/rolesCols";

const RolesIndex = () => {
    const { data: roles, run, loading } = useRequest(roleService.fetchAll, { manual: true });
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Gestione ruoli'
                        toolbar={
                            <PermissionsCheck permissions={["roles.create"]} is_system_admin={false}>
                                <CreateButton handleClick={() => navigate("create")} />
                            </PermissionsCheck>
                        }
                    />
                }
            >
                <LaravelTable columns={rolesCols || {}} records={roles} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default RolesIndex;
