import React from "react";

import { Avatar } from "../index";

const UserDisplay = ({ user, children, size }) => {
    return (
        <span className='flex items-center'>
            <Avatar src={user?.avatar} size={size || 25} shape='circle' className='mr-3' />
            <div className='flex flex-col'>
                <div className='text-gray-800'>{user?.name}</div>
                <small>{children}</small>
            </div>
        </span>
    );
};

export default UserDisplay;
