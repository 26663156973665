import { Form, Formik } from "formik";
import * as Yup from "yup";

import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import { Notification, toast } from "../../../../../components/ui";
import CancelButton from "../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../components/ui/Buttons/_components/SaveButton";
import usersService from "../../../../../services/usersService";

const AdminUserCreate = ({ onCreate }) => {
    const initialValues = {
        name: "",
        email: "",
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(100).required(),
        email: Yup.string().email().required(),
    });

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                usersService
                    .store(values)
                    .then(res => {
                        toast.push(<Notification title='Utente creato con successo' type='success' />);
                        resetForm();
                        onCreate(res);
                    })
                    .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, setFieldTouched, isSubmitting, errors, touched, resetForm, values, setFieldValue }) => (
                <Form>
                    <div className='grid lg:grid-cols-2 gap-4'>
                        <FormGroup name={"name"} required label='Nome e cognome'>
                            <FormInput name={"name"} />
                        </FormGroup>
                        <FormGroup name={"email"} required label='Email'>
                            <FormInput name={"email"} type='email' />
                        </FormGroup>
                    </div>
                    <div className='mt-4 gap-4 flex justify-end'>
                        <CancelButton disabled={isSubmitting} handleClick={() => resetForm()} />
                        <SaveButton loading={isSubmitting} disabled={!isValid || isSubmitting} />
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default AdminUserCreate;
