import api from "./api.config";

export const sanctumConfig = {
    axiosInstance: api,
    apiUrl: process.env.REACT_APP_BACKEND_URL ?? "",
    csrfCookieRoute: "csrf-cookie",
    signInRoute: "login",
    signOutRoute: "logout",
    userObjectRoute: "user",
};
