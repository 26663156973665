import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../services/attachmentService";
import companyGroupService from "../../../../services/companyGroupService";
import GlobalAttachmentEditForm from "../../../_forms/attachments/global/GlobalAttachmentEditForm";
import { globalAttachmentFormConfig } from "../../../_forms/attachments/global/globalAttachmentFormConfig";

const AttachmentShow = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: attachment, loading, mutate } = useRequest(attachmentService.fetch, { defaultParams: [id] });
    const { data: companyGroups } = useRequest(companyGroupService.noPaginate);

    const [mappedAttachment, setMappedAttachment] = useState(undefined);
    const [initialValues, setInitialValues] = useState(globalAttachmentFormConfig.editInitialValues);

    useEffect(() => {
        if (attachment) {
            const groups = attachment.company_groups
                ? attachment.company_groups.map(cg => {
                      return {
                          value: cg.id,
                          label: cg.name,
                      };
                  })
                : [];
            const att = { ...attachment, company_groups: groups };

            setMappedAttachment(att);
            setInitialValues(globalAttachmentFormConfig.setInitialValues(att));
        }
    }, [attachment]);

    const handleDelete = () => {
        attachmentService
            .destroy(attachment.uuid)
            .then(res => {
                toast.push(<Notification title='Allegato eliminato con successo' type='success' />);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={globalAttachmentFormConfig.editValidationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    attachmentService
                        .update(attachment.uuid, { ...values, company_groups: values.company_groups.map(g => g.value) })
                        .then(res => {
                            toast.push(<Notification title='Allegato aggiornato con successo' type='success' />);
                            mutate(res);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, touched, resetForm, setFieldTouched, errors, values, setFieldValue }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title={`Modifica ${attachment?.name}`}
                                    description={"Cambia visibilità e anagrafica dell'allegato"}
                                    toolbar={
                                        <>
                                            <CancelButton handleClick={() => navigate("../")} label={"Annulla"} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                            <DestroyButton
                                                message={`Sei sicuro di voler eliminare ${attachment?.name}?`}
                                                loading={loading}
                                                handleClick={() => handleDelete()}
                                            />
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <GlobalAttachmentEditForm attachment={mappedAttachment} companyGroups={companyGroups} loading={loading} />
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default AttachmentShow;
