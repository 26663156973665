import { useRequest } from "ahooks";
import React, { useContext } from "react";

import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import Accordion from "../../../../../../../components/ui/Accordion/Accordion";
import AccordionItem from "../../../../../../../components/ui/Accordion/AccordionItem";
import AddressDisplay from "../../../../../../../components/ui/Address/AddressDisplay";
import officeService from "../../../../../../../services/officeService";
import { CompanyContext } from "../../Company";
import CompanyOfficeCreate from "./CompanyOfficeCreate";
import CompanyOfficeShow from "./CompanyOfficeShow";

const CompanyOfficesTab = () => {
    const { company } = useContext(CompanyContext);

    const { data: offices, run } = useRequest(officeService.fetchAll, { defaultParams: [company.uuid] });

    const handleRun = () => {
        run([company.uuid]);
    };

    return (
        <>
            <FormDescription title={"Elenco sedi"} desc={"Aggiungi, modifica o rimuovi sedi aziendali"} />
            <Accordion defaultValue={undefined}>
                {(offices || []).map(office => (
                    <AccordionItem key={office.id} value={office.id} title={<AddressDisplay address={office.address} />}>
                        <CompanyOfficeShow office={office} onUpdate={handleRun} onDelete={handleRun} />
                    </AccordionItem>
                ))}

                <AccordionItem value='create' title='Aggiungi nuovo sede'>
                    <CompanyOfficeCreate onCreate={handleRun} />
                </AccordionItem>
            </Accordion>
        </>
    );
};

export default CompanyOfficesTab;
