import React from "react";
import { HiEnvelope } from "react-icons/hi2";

import PageHeader from "../Page/PageHeader";
import { Card } from "../index";

const BaseCompanyEmails = ({ company }) => {
    return (
        <Card className='flex items-center justify-start flex-1'>
            <PageHeader
                title={company?.company_data?.email || "Nessuna email"}
                description={company?.company_data?.pec || "Nessuna PEC"}
                icon={<HiEnvelope className='text-neutral-300' size={30} />}
            />
        </Card>
    );
};

export default BaseCompanyEmails;
