import { Alert, Button, FormContainer, FormItem, Input } from "components/ui";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import useTimeOutMessage from "utils/hooks/useTimeOutMessage";
import * as Yup from "yup";

import ActionLink from "../../../components/shared/ActionLink";
import authService from "../../../services/authService";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("La mail fornita non è valida").required("Inserisci il tuo indirizzo email"),
});

const ForgotPasswordForm = props => {
    const [emailSent, setEmailSent] = useState(false);
    const [message, setMessage] = useTimeOutMessage();
    const initialValues = {
        email: "",
    };

    return (
        <div>
            {emailSent && (
                <Alert className='mb-4' type='success' showIcon>
                    Controlla la tua casella di posta.
                </Alert>
            )}
            {message && (
                <Alert className='mb-4' type='danger' showIcon>
                    {message}{" "}
                </Alert>
            )}
            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setEmailSent(false);
                    authService
                        .forgotPassword(values.email)
                        .then(res => {
                            setEmailSent(true);
                            setSubmitting(false);
                        })
                        .catch(err => {
                            setMessage(err.response.data.message);
                            setSubmitting(false);
                        });
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form>
                        <FormContainer>
                            <div className={emailSent ? "hidden" : ""}>
                                <FormItem invalid={errors.email && touched.email} errorMessage={errors.email}>
                                    <Field type='email' autoComplete='off' name='email' placeholder='Email' component={Input} />
                                </FormItem>
                            </div>
                            <Button block loading={isSubmitting} variant='solid' type='submit'>
                                {emailSent ? "Re-invia Email" : "Invia Email"}
                            </Button>
                            <div className='mt-4 text-center'>
                                <span>Torna a </span>
                                <ActionLink to='/auth/login'>Accesso</ActionLink>
                            </div>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForgotPasswordForm;
