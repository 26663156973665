import Sorter from "./Sorter";
import TBody from "./TBody";
import TFoot from "./TFoot";
import THead from "./THead";
import Table from "./Table";
import Td from "./Td";
import Th from "./Th";
import Tr from "./Tr";

Table.THead = THead;
Table.TBody = TBody;
Table.TFoot = TFoot;
Table.Th = Th;
Table.Tr = Tr;
Table.Td = Td;
Table.Sorter = Sorter;

export default Table;
