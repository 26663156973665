import React from "react";

import SignInForm from "./SignInForm";

const SignIn = () => {
    return (
        <div>
            <div className='mb-8'>
                <h3 className='mb-1'>Accesso</h3>
                <p>Inserisci le tue credenziali.</p>
            </div>
            <SignInForm />
        </div>
    );
};

export default SignIn;
