import { NAV_ITEM_TYPE_COLLAPSE, NAV_ITEM_TYPE_ITEM } from "../../../constants/navigation.constant";

export const adminNavigationConfig = [
    {
        key: "admin-dashboard",
        path: "/admin/dashboard",
        title: "Dashboard",
        icon: "home",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: true,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
    {
        key: "admin-attachments",
        path: "/admin/attachments",
        title: "Bacheca",
        icon: "attachments",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: true,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
    {
        key: "admin-companies",
        path: "",
        title: "Gestione aziende",
        icon: "companies",
        type: NAV_ITEM_TYPE_COLLAPSE,
        is_system_admin: true,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [
            {
                key: "admin-companies",
                path: "/admin/companies",
                title: "Aziende",
                icon: "",
                type: NAV_ITEM_TYPE_ITEM,
                is_system_admin: true,
                can_manage_other_companies: undefined,
                is_supervised: undefined,
                permissions: [],
                subMenu: [],
            },
            {
                key: "admin-companyGroups",
                path: "/admin/company-groups",
                title: "Gruppi aziende",
                icon: "",
                type: NAV_ITEM_TYPE_ITEM,
                is_system_admin: true,
                can_manage_other_companies: undefined,
                is_supervised: undefined,
                permissions: [],
                subMenu: [],
            },
        ],
    },
    {
        key: "admin-documentCategories",
        path: "",
        title: "Gestione documenti",
        icon: "documents",
        type: NAV_ITEM_TYPE_COLLAPSE,
        is_system_admin: true,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [
            {
                key: "admin-uploads",
                path: `/admin/uploads`,
                title: "Caricamenti",
                icon: "",
                type: NAV_ITEM_TYPE_ITEM,
                is_system_admin: true,
                can_manage_other_companies: undefined,
                is_supervised: undefined,
                permissions: [],
                subMenu: [],
            },
            {
                key: "admin-document-types",
                path: `/admin/document-types`,
                title: "Tipologie documenti",
                icon: "",
                type: NAV_ITEM_TYPE_ITEM,
                is_system_admin: true,
                can_manage_other_companies: undefined,
                is_supervised: undefined,
                permissions: [],
                subMenu: [],
            },
            {
                key: "admin-document-categories",
                path: `/admin/document-categories`,
                title: "Categorie documenti",
                icon: "",
                type: NAV_ITEM_TYPE_ITEM,
                is_system_admin: true,
                can_manage_other_companies: undefined,
                is_supervised: undefined,
                permissions: [],
                subMenu: [],
            },
        ],
    },
    {
        key: "admin-communications",
        path: "/admin/communications",
        title: "Comunicazioni",
        icon: "communications",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: true,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
    {
        key: "admin-users",
        path: "/admin/users",
        title: "Gestione utenze",
        icon: "users",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: true,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
];
