import React from "react";
import { Navigate } from "react-router-dom";

import Account from "../../../views/protected/Account/Account";
import AccountInformations from "../../../views/protected/Account/tabs/AccountInformations/AccountInformations";
import AccountPassword from "../../../views/protected/Account/tabs/AccountPassword/AccountPassword";

const accountRoutes = {
    element: <Account />,
    children: [
        {
            path: "profile",
            title: "Profilo",
            element: <AccountInformations />,
        },
        {
            path: "password",
            title: "Password",
            element: <AccountPassword />,
        },
        {
            path: "",
            element: <Navigate to={"profile"} />,
        },
    ],
};

export default accountRoutes;
