import {useRequest} from "ahooks";
import React from "react";

import FormCheckbox from "../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../components/RefactoredForms/FormInput";
import SupervisorInfo from "./SupervisorInfo";
import listService from "../../../services/listService";

const SupervisorEditForm = ({supervisor, onCancel}) => {
    const {data: documentTypes} = useRequest(listService.publicDocumentTypes);

    return (
        <>
            <SupervisorInfo
                referent_name={supervisor?.referent_name}
                referent_contact={supervisor?.referent_contact}
                supervisor={supervisor?.supervisor}
                onCancel={onCancel}
            />

            <div className={"grid xl:grid-cols-3 lg:grid-cols-2 gap-4"}>
                <FormGroup label='Referente aziendale' name='referent_name'>
                    <FormInput name='referent_name'/>
                </FormGroup>
                <FormGroup label='Contatto referente aziendale' name='referent_contact'>
                    <FormInput name='referent_contact'/>
                </FormGroup>
            </div>

            <FormDescription
                title='Tipologie documento'
                desc={`Seleziona le tipologie documento a cui ha accesso ${supervisor?.supervisor?.business_name}.`}
            />
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                {documentTypes?.map(dt => (
                    <FormCheckbox
                        key={dt.value}
                        id={dt.value}
                        name={"document_types"}
                        label={dt.label}
                        description={dt.description}
                        value={dt.value.toString()}
                    />
                ))}
            </div>
        </>
    );
};

export default SupervisorEditForm;
