import React from "react";

const Total = props => {
    const { total } = props;
    return (
        <div className='pagination-total'>
            Totale <span>{total}</span> Record
        </div>
    );
};

export default Total;
