import React from "react";

import UserCreate from "../../../../views/protected/myCompany/users/UserCreate";
import Users from "../../../../views/protected/myCompany/users/Users";
import UsersIndex from "../../../../views/protected/myCompany/users/UsersIndex";
import User from "../../../../views/protected/myCompany/users/user/User";
import UserEdit from "../../../../views/protected/myCompany/users/user/UserEdit";
import UserShow from "../../../../views/protected/myCompany/users/user/UserShow";

const usersRoutes = {
    element: <Users />,
    children: [
        {
            path: "create",
            element: <UserCreate />,
        },
        {
            path: ":uuid",
            element: <User />,
            children: [
                {
                    path: "",
                    index: true,
                    element: <UserShow />,
                },
                {
                    path: "edit",
                    element: <UserEdit />,
                },
            ],
        },
        {
            path: "",
            index: true,
            element: <UsersIndex />,
        },
    ],
};

export default usersRoutes;
