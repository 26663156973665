import { useRequest } from "ahooks";
import React, { createContext, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";

import PageContainer from "../../../../../components/layout/ProtectedLayout/_components/PageContainer";
import companyService from "../../../../../services/companyService";
import roleService from "../../../../../services/roleService";

export const CompanyContext = createContext({
    loading: true,
    company: {},
    roles: [],
});
const Company = () => {
    const { id } = useParams();
    const { data: company, loading, mutate } = useRequest(companyService.fetch, { defaultParams: [id] });
    const { data: roles } = useRequest(roleService.companyRoles, { defaultParams: [id] });

    const contextValue = {
        company: company,
        setCompany: mutate,
        roles: roles,
        loading,
    };

    return (
        <PageContainer>
            <CompanyContext.Provider value={contextValue}>{company?.uuid && <Outlet />}</CompanyContext.Provider>
        </PageContainer>
    );
};

export default Company;
