import { HiSearch } from "@react-icons/all-files/hi/HiSearch";
import PropTypes from "prop-types";
import React from "react";

import Input from "../../Input";

const LaravelTableSearch = ({ query, handleChangeQuery }) => {
    return (
        <div className='flex items-center mb-4'>
            <Input
                prefix={<HiSearch className='text-lg' />}
                size='sm'
                value={query}
                onChange={event => handleChangeQuery(event.target.value)}
                style={{ maxWidth: 230 }}
                placeholder='Cerca'
            />
        </div>
    );
};

LaravelTableSearch.propTypes = {
    handleChangeQuery: PropTypes.func,
    query: PropTypes.string,
};

export default LaravelTableSearch;
