import { useRequest } from "ahooks";
import React from "react";
import { HiPaperAirplane } from "react-icons/hi";
import { useNavigate } from "react-router-dom";
import SweetAlert from "sweetalert2";

import FormDisplay from "../../../../../../components/RefactoredForms/FormDisplay";
import { Button, Card, Notification, toast } from "../../../../../../components/ui";
import CancelButton from "../../../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../../../components/ui/Buttons/_components/DestroyButton";
import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import PageHeader from "../../../../../../components/ui/Page/PageHeader";
import StateDisplay from "../../../../../../components/ui/State/StateDisplay";
import { UPLOAD_STATE_ENUM } from "../../../../../../constants/enums/uploadState.enum";
import uploadService from "../../../../../../services/uploadService";
import dateHelper from "../../../../../../utils/dateHelper";

const UploadHeader = ({ upload, onDestroyed, onUpdated }) => {
    const { runAsync, loading: destroyLoading } = useRequest(uploadService.destroy, { manual: true });
    const navigate = useNavigate();
    const handleDelete = () => {
        runAsync(upload?.uuid)
            .then(() => {
                toast.push(<Notification title='Caricamento eliminato con successo' type='success' />);
                onDestroyed();
            })
            .catch(err => toast.push(<Notification title={"Impossibile eliminare il caricamento"} type='danger' />));
    };

    const handleMessagePopUp = () => {
        if (upload.documents.length < 1) {
            toast.push(<Notification title={"Non sono presenti documenti"} type='danger' />);
            return;
        }
        SweetAlert.fire({
            icon: "warning",
            text: "Sei sicuro di voler pubblicare tutti i documenti contenuti nell'upload?",
        }).then(res => {
            if (res.isConfirmed) {
                uploadService
                    .complete(upload.uuid)
                    .then(res => {
                        toast.push(<Notification title={"Documenti pubblicati con successo"} type='success' />);
                        onUpdated(res);
                    })
                    .catch(err =>
                        toast.push(<Notification title='Impossibile pubblicare! Controlla la validità dei documenti inseriti.' type='danger' />)
                    );
            }
        });
    };

    return (
        <Card className='mb-3'>
            <div className='flex flex-col gap-4'>
                <PageHeader
                    title={upload?.document_category?.name}
                    description={
                        <div className='grid xl:grid-cols-5 lg:grid-cols-2 gap-4'>
                            <FormDisplay value='Azienda'>{upload?.company?.business_name}</FormDisplay>
                            <FormDisplay value='Mese'>{dateHelper.getMonths().find(m => m.value === upload?.payload?.month)?.label}</FormDisplay>
                            <FormDisplay value='Anno'>{dateHelper.getAllYears().find(m => m.value === upload?.payload?.year)?.label}</FormDisplay>
                            <FormDisplay value='Pubblicazione (default)'>
                                <DateFormatDisplay value={upload?.payload?.auto_publish_at} />
                            </FormDisplay>
                            <FormDisplay value='Stato'>
                                <StateDisplay state={upload?.status} />
                            </FormDisplay>
                        </div>
                    }
                    toolbar={
                        <>
                            <CancelButton handleClick={() => navigate("../")} label={"Annulla"} />
                            {upload?.status?.value === UPLOAD_STATE_ENUM.DRAFT ? (
                                <>
                                    <Button
                                        size='sm'
                                        type='button'
                                        onClick={() => handleMessagePopUp()}
                                        loading={false}
                                        variant='solid'
                                        color='green-500'
                                        icon={<HiPaperAirplane />}
                                    >
                                        Pubblica
                                    </Button>
                                    <DestroyButton
                                        loading={destroyLoading}
                                        disabled={destroyLoading}
                                        label='Elimina'
                                        handleClick={() => handleDelete()}
                                        message='Sei sicuro di voler eliminare il caricamento e tutti i documenti in esso contenuti?'
                                    />
                                </>
                            ) : (
                                <></>
                            )}
                        </>
                    }
                />
            </div>
        </Card>
    );
};

export default UploadHeader;
