import { useRequest } from "ahooks";
import React from "react";
import { HiFolderOpen } from "react-icons/hi2";
import { useNavigate } from "react-router-dom";

import { Alert, Card } from "../../../../../../components/ui";
import Button from "../../../../../../components/ui/Buttons";
import CardHeader from "../../../../../../components/ui/Card/CardHeaders/CardHeader";
import { LoadingMessage } from "../../../../../../components/ui/LoadingMessage/LoadingMessage";
import dashboardService from "../../../../../../services/dashboardService";
import MyCompanyUploadRow from "./MyCompanyUploadRow";

const MyCompanyUploadsBox = () => {
    const navigate = useNavigate();

    const { data: uploads, loading } = useRequest(dashboardService.fetchUploads);

    return (
        <Card>
            <CardHeader
                title='Ultimi caricamenti'
                toolbar={
                    <Button
                        onClick={() => navigate("/my-company/documents")}
                        icon={<HiFolderOpen />}
                        size='sm'
                        loading={loading}
                        color='yellow-500'
                        variant='solid'
                    />
                }
            />
            <LoadingMessage loading={loading} message='Caricamento documenti ...' />
            {!loading && uploads && (
                <div className='flex flex-col gap-2 mt-4'>
                    {uploads.length < 1 && (
                        <Alert showIcon={true} type='info'>
                            Nessun nuovo caricamento
                        </Alert>
                    )}
                    {uploads.map((upload, index) => (
                        <MyCompanyUploadRow key={index} index={index} upload={upload} />
                    ))}
                </div>
            )}
        </Card>
    );
};

export default MyCompanyUploadsBox;
