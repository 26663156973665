import * as Yup from "yup";

const createInitialValues = {
    name: "",
    description: "",
    is_public: false,
    file: [],
};

const createValidationSchema = Yup.object().shape({
    name: Yup.string().max(100).required(),
    description: Yup.string(800).nullable().notRequired(),
    is_public: Yup.bool().required(),
    file: Yup.array().min(1).required(),
});

const editInitialValues = {
    name: "",
    description: "",
    is_public: false,
};

const editValidationSchema = Yup.object().shape({
    name: Yup.string().max(100).required(),
    description: Yup.string(800).nullable().notRequired(),
    is_public: Yup.bool().required(),
});

const setInitialValues = mappedAttachment => {
    return {
        name: mappedAttachment?.name,
        description: mappedAttachment?.description,
        is_public: mappedAttachment?.is_public,
    };
};

export const companyAttachmentFormConfig = {
    createInitialValues,
    createValidationSchema,
    editInitialValues,
    editValidationSchema,
    setInitialValues,
};
