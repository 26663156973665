import React from "react";

import { ApplicationContext } from "../../../../App";
import FormDescription from "../../../../components/RefactoredForms/FormDescription";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import AccordionItem from "../../../../components/ui/Accordion/AccordionItem";
import EmptyAccordionItem from "../../../../components/ui/Accordion/EmptyAccordionItem";
import AddressDisplay from "../../../../components/ui/Address/AddressDisplay";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import FormRowDisplay from "../../../../components/ui/Form/FormRow/FormRowDisplay";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import MyCompanyOffice from "./MyCompanyOffice";

const MyCompanyCompany = () => {
    const { currentCompany } = React.useContext(ApplicationContext);

    return (
        <PageContainer>
            <Card header={<PageHeader title='La tua anagrafica aziendale' />}>
                <Accordion defaultValue={undefined}>
                    <AccordionItem key='informazioni_generali' value='informazioni_generali' title='Informazioni generali'>
                        <FormDescription title='Informazioni generali' />
                        <FormRowDisplay label='Ragione Sociale'>{currentCompany?.business_name}</FormRowDisplay>
                        <FormRowDisplay label='Partita Iva'>{currentCompany?.vat_number}</FormRowDisplay>
                        <FormRowDisplay label='Codice Fiscale'>{currentCompany?.fiscal_code}</FormRowDisplay>
                    </AccordionItem>

                    <AccordionItem key='recapiti' value='recapiti' title='Recapiti'>
                        <FormDescription title='Recapiti' />
                        <FormRowDisplay label='Email'>{currentCompany?.company_data?.email}</FormRowDisplay>
                        <FormRowDisplay label='PEC'>{currentCompany?.company_data?.pec}</FormRowDisplay>
                        <FormRowDisplay label='Telefono'>{currentCompany?.company_data?.phone}</FormRowDisplay>
                    </AccordionItem>

                    <AccordionItem key='referente' value='referente' title='Referente'>
                        <FormDescription title='Referente' />
                        <FormRowDisplay label='Referente'>{currentCompany?.company_data?.referent_name}</FormRowDisplay>
                    </AccordionItem>

                    <AccordionItem key='legale_rappresentante' value='legale_rappresentante' title='Legale Rappresentante'>
                        <FormDescription title='Legale Rappresentante' />
                        <FormRowDisplay label='Nome legale rappresentante'>{currentCompany?.company_data?.legal_first_name}</FormRowDisplay>
                        <FormRowDisplay label='Cognome legale rappresentante'>{currentCompany?.company_data?.legal_last_name}</FormRowDisplay>
                        <FormRowDisplay label='Codice fiscale legale rappresentante'>
                            {currentCompany?.company_data?.legal_fiscal_code}
                        </FormRowDisplay>
                    </AccordionItem>

                    <AccordionItem key='sede_legale' value='sede_legale' title='Sede Legale'>
                        <FormDescription title='Sede Legale' />
                        <FormRowDisplay label='Indirizzo'>{currentCompany?.company_data?.legal_office?.address}</FormRowDisplay>
                        <FormRowDisplay label='Numero civico'>{currentCompany?.company_data?.legal_office?.street_number}</FormRowDisplay>
                        <FormRowDisplay label='Città'>{currentCompany?.company_data?.legal_office?.city}</FormRowDisplay>
                        <FormRowDisplay label='Cap'>{currentCompany?.company_data?.legal_office?.postal_code}</FormRowDisplay>
                        <FormRowDisplay label='Nazione'>{currentCompany?.company_data?.legal_office?.country}</FormRowDisplay>
                    </AccordionItem>

                    <AccordionItem key='sedi' value='sedi' title='Altre sedi'>
                        <Accordion defaultValue={undefined}>
                            <FormDescription title='Altre sedi' />
                            {(currentCompany?.offices || []).length < 1 ? (
                                <EmptyAccordionItem title='Nessuna sede' />
                            ) : (
                                currentCompany.offices.map(office => (
                                    <AccordionItem key={office.uuid} value={office.uuid} title={<AddressDisplay address={office.address} />}>
                                        <MyCompanyOffice office={office} />
                                    </AccordionItem>
                                ))
                            )}
                        </Accordion>
                    </AccordionItem>

                    <AccordionItem key='altro' value='altro' title='Altro'>
                        <FormDescription title='Altro' />
                        <FormRowDisplay label='Codice INAIL'>{currentCompany?.company_data?.inail_code}</FormRowDisplay>
                        <FormRowDisplay label='Classificazione INAIL'>{currentCompany?.company_data?.inail_classification}</FormRowDisplay>
                        <FormRowDisplay label='Codice cassa edile'>{currentCompany?.company_data?.building_fund_code}</FormRowDisplay>
                    </AccordionItem>
                </Accordion>
            </Card>
        </PageContainer>
    );
};

export default MyCompanyCompany;
