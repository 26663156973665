import { HiCheck } from "@react-icons/all-files/hi/HiCheck";
import { useRequest } from "ahooks";
import { useFormikContext } from "formik";
import React, { useEffect, useState } from "react";

import FormCheckbox from "../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../components/RefactoredForms/FormInput";
import { Button } from "../../../../../components/ui";
import listService from "../../../../../services/listService";

const RoleForm = () => {
    const { data: permissions = [], loading } = useRequest(listService.permissions);
    const [toggleSelection, setToggleSelection] = useState(false);
    const { setFieldValue } = useFormikContext();

    const handleToggleSelection = () => {
        setToggleSelection(!toggleSelection);
    };

    useEffect(() => {
        setFieldValue("permissions", toggleSelection ? permissions.map(p => p.value) : []);
    }, [toggleSelection]);

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid lg:grid-cols-2'>
                <FormGroup
                    name={"name"}
                    label={"Etichetta ruolo"}
                    required
                    info={"L'etichetta del ruolo è il nome mnemonico dato al ruolo stesso. ES. 'Impiegato'"}
                >
                    <FormInput name={"name"} />
                </FormGroup>
            </div>

            <FormDescription title={"Permessi"} desc={"Seleziona i permessi che verranno assegnati all'utente a cui verrà associato il ruolo."} />
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                {permissions.map(permission => (
                    <FormCheckbox key={permission.uuid} name={"permissions"} label={permission.label} value={permission.value} />
                ))}
                <div>
                    <Button type='button' icon={<HiCheck />} variant='plain' size='xs' onClick={() => handleToggleSelection()}>
                        Seleziona tutto
                    </Button>
                </div>
            </div>
        </div>
    );
};

export default RoleForm;
