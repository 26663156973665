import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import toast from "../../../../components/ui/toast";
import roleService from "../../../../services/roleService";
import RoleForm from "./_components/RoleForm";
import roleFormConfig from "./_components/roleFormConfig";

const RoleCreate = () => {
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Formik
                enableReinitialize={true}
                initialValues={roleFormConfig.initialValues}
                validationSchema={roleFormConfig.validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    roleService
                        .store(values)
                        .then(res => {
                            toast.push(<Notification title='Ruolo creato con successo' type='success' />);
                            navigate("../" + res.id.toString());
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, resetForm }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Crea nuovo ruolo'
                                    toolbar={
                                        <>
                                            <CancelButton handleClick={() => resetForm()} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        </>
                                    }
                                />
                            }
                        >
                            <RoleForm />
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default RoleCreate;
