import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import companyGroupService from "../../../../services/companyGroupService";

const CompanyGroupCreate = () => {
    const navigate = useNavigate();

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(255).required(),
    });

    const initialValues = {
        name: "",
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    companyGroupService
                        .store(values)
                        .then(res => {
                            toast.push(<Notification title='Gruppo creato con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, resetForm }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Crea nuovo gruppo aziende'
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                    <FormGroup label='Nome' name='name' required>
                                        <FormInput name={"name"} />
                                    </FormGroup>
                                </div>
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default CompanyGroupCreate;
