import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../../../../services/attachmentService";
import CompanyAttachmentEditForm from "../../../../../../_forms/attachments/company/CompanyAttachmentEditForm";
import { companyAttachmentFormConfig } from "../../../../../../_forms/attachments/company/companyAttachmentFormConfig";
import { CompanyContext } from "../../Company";

const CompanyAttachmentShow = () => {
    const { company } = useContext(CompanyContext);
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: attachment, loading, mutate } = useRequest(attachmentService.companyFetch, { defaultParams: [company?.uuid, id] });

    const [initialValues, setInitialValues] = useState(companyAttachmentFormConfig.editInitialValues);

    useEffect(() => {
        if (attachment) {
            setInitialValues(companyAttachmentFormConfig.setInitialValues(attachment));
        }
    }, [attachment]);

    const handleDelete = () => {
        attachmentService
            .companyDestroy(company.uuid, attachment.uuid)
            .then(() => {
                toast.push(<Notification title='Allegato eliminato con successo' type='success' />);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />));
    };

    return (
        <>
            <Formik
                validationSchema={companyAttachmentFormConfig.editValidationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    attachmentService
                        .companyUpdate(company.uuid, attachment.uuid, values)
                        .then(res => {
                            toast.push(<Notification title='Allegato aggiornato con successo' type='success' />);
                            mutate(res);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                    setSubmitting(false);
                }}
            >
                {({ isValid, isSubmitting, resetForm }) => (
                    <Form>
                        <CompanyAttachmentEditForm loading={loading} attachment={attachment} />
                        <div className='flex justify-end gap-4'>
                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                            <CancelButton handleClick={() => resetForm()} />
                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                            <DestroyButton
                                message={`Sei sicuro di voler eliminare ${attachment?.name}?`}
                                loading={loading}
                                handleClick={() => handleDelete()}
                            />
                        </div>
                    </Form>
                )}
            </Formik>
        </>
    );
};

export default CompanyAttachmentShow;
