import { Alert, Button, FormContainer, FormItem } from "components/ui";
import { Field, Form, Formik } from "formik";
import React from "react";
import authService from "services/authService";
import useTimeOutMessage from "utils/hooks/useTimeOutMessage";
import * as Yup from "yup";

import ActionLink from "../../../components/shared/ActionLink";
import PasswordInput from "../../../components/shared/PasswordInput";

const validationSchema = Yup.object().shape({
    current_password: Yup.string().max(255).required("Obbligatorio"),
    password: Yup.string().min(8).required("Obbligatorio"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "La password non coincide")
        .required("Obbligatorio"),
});

const ForceResetPasswordForm = props => {
    const [message, setMessage] = useTimeOutMessage();

    const initialValues = {
        current_password: "",
        password: "",
        password_confirmation: "",
    };

    return (
        <div>
            {message && (
                <Alert className='mb-4' type='danger' showIcon>
                    {message}
                </Alert>
            )}

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    authService
                        .forceChangePassword(values.current_password, values.password, values.password_confirmation)
                        .then(res => window.location.reload())
                        .catch(err => setMessage(err.response.data.message))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form>
                        <FormContainer>
                            <FormItem
                                label='Attuale password'
                                invalid={errors.current_password && touched.current_password}
                                errorMessage={errors.current_password}
                            >
                                <Field autoComplete='off' name='current_password' placeholder='Attuale password' component={PasswordInput} />
                            </FormItem>
                            <FormItem label='Nuova Password' invalid={errors.password && touched.password} errorMessage={errors.password}>
                                <Field autoComplete='off' name='password' placeholder='Nuova password' component={PasswordInput} />
                            </FormItem>
                            <FormItem
                                label='Conferma nuova password'
                                invalid={errors.password_confirmation && touched.password_confirmation}
                                errorMessage={errors.password_confirmation}
                            >
                                <Field
                                    autoComplete='off'
                                    name='password_confirmation'
                                    placeholder='Conferma nuova password'
                                    component={PasswordInput}
                                />
                            </FormItem>

                            <Button block loading={isSubmitting} variant='solid' type='submit'>
                                {isSubmitting ? "Invio richiesta..." : "Aggiorna Password"}
                            </Button>
                            <div className='mt-4 text-center'>
                                <span>Torna a </span>
                                <ActionLink to='/auth/login'>Accesso</ActionLink>
                            </div>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ForceResetPasswordForm;
