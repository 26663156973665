import { Link } from "react-router-dom";

import FormBoolDisplay from "../../../../../components/ui/Form/FormDisplay/FormBoolDisplay";

export const documentTypesCols = [
    {
        header: "Documento",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) => (
            <Link to={original.uuid} className='font-bold'>
                {original.code} {getValue()}
            </Link>
        ),
    },
    {
        header: "Descrizione",
        accessorKey: "description",
    },
    {
        header: "Caricabile",
        accessorKey: "can_be_uploaded_by_company",
        cell: ({ getValue }) => <FormBoolDisplay value={!!getValue()} />,
    },
    {
        header: "Riconoscimento",
        accessorKey: "filename_regex",
        cell: ({ getValue }) => <FormBoolDisplay value={!!getValue()} />,
    },
    {
        header: "Scadenza",
        accessorKey: "is_expiration_required",
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "Privato",
        accessorKey: "is_visible_only_to_sysadmin",
        cell: ({ getValue }) => <FormBoolDisplay value={!!getValue()} />,
    },
];
