import React, { useState } from "react";
import { Outlet, useNavigate } from "react-router-dom";

import PageContainer from "../../../components/layout/ProtectedLayout/_components/PageContainer";
import AdaptableCard from "../../../components/shared/AdaptableCard";
import { Tabs } from "../../../components/ui";
import TabList from "../../../components/ui/Tabs/TabList";
import TabNav from "../../../components/ui/Tabs/TabNav";

const Account = () => {
    const [currentTab, setCurrentTab] = useState("profile");

    const navigate = useNavigate();

    const tabs = [
        { value: "profile", label: "Profilo" },
        { value: "password", label: "Password" },
    ];

    return (
        <PageContainer header='Account'>
            <AdaptableCard>
                <Tabs
                    value={currentTab}
                    onChange={value => {
                        setCurrentTab(value);
                        navigate(value);
                    }}
                >
                    <TabList>
                        {tabs.map((tab, index) => (
                            <TabNav key={index} value={tab.value}>
                                {tab.label}
                            </TabNav>
                        ))}
                    </TabList>
                </Tabs>
                <div className='px-4 py-6'>
                    <Outlet />
                </div>
            </AdaptableCard>
        </PageContainer>
    );
};

export default Account;
