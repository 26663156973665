import { HiPlus } from "@react-icons/all-files/hi/HiPlus";
import React from "react";

import Button from "../Button";

const CreateButton = ({ disabled, loading, label, handleClick }) => {
    return (
        <Button type='button' disabled={disabled} loading={loading} size='sm' variant='solid' color='sky' icon={<HiPlus />} onClick={handleClick}>
            {label || "Nuovo"}
        </Button>
    );
};

export default CreateButton;
