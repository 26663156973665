import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import communicationService from "../../../../services/communicationService";
import { communicationsCols } from "./components/communicationsCols";

const CommunicationsIndex = () => {
    const { data: communications, run, loading } = useRequest(communicationService.fetchAll, { manual: true });

    const navigate = useNavigate();

    return (
        <PageContainer header={<PageHeader title='Gestione comunicazioni' toolbar={<CreateButton handleClick={() => navigate("create")} />} />}>
            <Card header={<PageHeader title='Gestione comunicazioni' toolbar={<CreateButton handleClick={() => navigate("create")} />} />}>
                <LaravelTable columns={communicationsCols} records={communications} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default CommunicationsIndex;
