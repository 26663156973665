import { Form, Formik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";

import { Notification, toast } from "../../../../../../../components/ui";
import usersService from "../../../../../../../services/usersService";
import { CompanyContext } from "../../Company";
import CompanyUserForm from "./_components/CompanyUserForm";

const CompanyUserCreate = ({ onCreate, documentTypes = [], roles = [] }) => {
    const { company } = useContext(CompanyContext);

    const initialValues = {
        name: "",
        email: "",
        role: "",
        can_view_all_document_types: false,
        document_types_permitted: [],
    };

    const validationSchema = Yup.object().shape({
        name: Yup.string().max(100).required(),
        email: Yup.string().email().required(),
        role: Yup.object().nullable().required(),
        can_view_all_document_types: Yup.boolean().required(),
        document_types_permitted: Yup.array().required(),
    });

    return (
        <Formik
            validationSchema={validationSchema}
            initialValues={initialValues}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                usersService
                    .companyStore(company.uuid, { ...values, role: values.role?.value })
                    .then(res => {
                        toast.push(<Notification title='Utente creato con successo' type='success' />);
                        resetForm();
                        onCreate(res);
                    })
                    .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {() => (
                <Form>
                    <CompanyUserForm documentTypes={documentTypes} roles={roles} />
                </Form>
            )}
        </Formik>
    );
};

export default CompanyUserCreate;
