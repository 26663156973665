import { HiDownload } from "@react-icons/all-files/hi/HiDownload";
import { useRequest } from "ahooks";
import React from "react";
import { Link } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import MediaService from "../../../../services/mediaService";

const ManualsIndex = () => {
    const { data = [] } = useRequest(MediaService.userManuals);

    return (
        <PageContainer>
            <Card header={<PageHeader title='Manuali utente' loading={false} />}>
                <div className='flex flex-col gap-4'>
                    {data.length < 1 ? (
                        <p> Nessun manuale presente.</p>
                    ) : (
                        data.map((manual, index) => (
                            <Link
                                target={"_blank"}
                                to={manual.url}
                                className={`border-b border-gray-200 dark:border-gray-600 flex gap-4 items-center cursor-pointer hover:text-sky-800`}
                                key={index}
                            >
                                <HiDownload size={20} />
                                <span className='font-semibold text-lg'>{manual.name}</span>
                            </Link>
                        ))
                    )}
                </div>
            </Card>
        </PageContainer>
    );
};

export default ManualsIndex;
