import { useRequest } from "ahooks";
import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";

import CreateButton from "../../../../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../../../../services/attachmentService";
import { CompanyContext } from "../../Company";
import { companyAttachmentsCols } from "./_components/companyAttachmentsCols";

const CompanyAttachmentsIndex = () => {
    const { company } = useContext(CompanyContext);
    const navigate = useNavigate();

    const {
        data: attachments,
        run,
        loading,
    } = useRequest(attachmentService.companyFetchAll, {
        manual: true,
        defaultParams: [company?.uuid],
    });

    const handleRun = params => {
        if (company?.uuid) {
            run([company.uuid], params);
        }
    };

    return (
        <>
            <PageHeader loading={loading} toolbar={<CreateButton handleClick={() => navigate("create")} />} />
            <LaravelTable columns={companyAttachmentsCols} records={attachments} loading={loading} onParamsChange={handleRun} searchable sortable />
        </>
    );
};

export default CompanyAttachmentsIndex;
