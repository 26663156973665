import { useRequest } from "ahooks";
import React, { useContext } from "react";

import { ApplicationContext } from "../../../../App";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import BaseCompanyContacts from "../../../../components/ui/Company/BaseCompanyContacts";
import BaseCompanyInfo from "../../../../components/ui/Company/BaseCompanyInfo";
import CompanyPublicLinks from "../../../../components/ui/Company/CompanyPublicLinks";
import StudioBarzaghiInfo from "../../../../components/ui/Company/StudioBarzaghiInfo";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import supervisionService from "../../../../services/supervisionService";
import MyCompanyAttachmentsBox from "./widgets/MyCompanyAttachmentsBox/MyCompanyAttachmentsBox";
import MyCompanyCommunicationsWidget from "./widgets/MyCompanyCommunicationsWidget/MyCompanyCommunicationsWidget";
import MyCompanyUploadsBox from "./widgets/MyCompanyUploadsBox/MyCompanyUploadsBox";

const Dashboard = () => {
    const { currentCompany, setCurrentCompany } = useContext(ApplicationContext);
    const { data } = useRequest(supervisionService.fetch);

    return (
        <PageContainer>
            <PageHeader
                title={"Benvenuto nella tua DASHBOARD"}
                description='Qui troverai le principali informazioni in un colpo d’occhio e avrai gli ultimi caricamenti sottomano'
            ></PageHeader>
            <div className='flex flex-col gap-5 mt-5'>
                <div className='flex flex-row flex-wrap justify-center gap-4 w-full'>
                    <BaseCompanyInfo company={currentCompany} />
                    <CompanyPublicLinks company={currentCompany} updateCompany={setCurrentCompany} withPasswordRefresh />
                    {data?.supervisor && (
                        <>
                            <BaseCompanyInfo company={data?.supervisor} />
                            <BaseCompanyContacts
                                company={data?.supervisor}
                                referent_contact={data?.referent_contact}
                                referent_name={data?.referent_name}
                            />
                        </>
                    )}
                    <StudioBarzaghiInfo />
                </div>
                <MyCompanyCommunicationsWidget />
                <div className='grid grid-cols lg:grid-cols-2 gap-4'>
                    <MyCompanyAttachmentsBox />
                    <MyCompanyUploadsBox />
                </div>
            </div>
        </PageContainer>
    );
};

export default Dashboard;
