import { useRequest } from "ahooks";
import React, { createContext, useState } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import AdaptableCard from "../../../../components/shared/AdaptableCard";
import { Tabs } from "../../../../components/ui";
import BaseCompanyContacts from "../../../../components/ui/Company/BaseCompanyContacts";
import BaseCompanyEmails from "../../../../components/ui/Company/BaseCompanyEmails";
import BaseCompanyInfo from "../../../../components/ui/Company/BaseCompanyInfo";
import CompanyPublicLinks from "../../../../components/ui/Company/CompanyPublicLinks";
import TabList from "../../../../components/ui/Tabs/TabList";
import TabNav from "../../../../components/ui/Tabs/TabNav";
import companyService from "../../../../services/companyService";

export const SupervisedContext = createContext({
    company: undefined,
});
const SupervisedShow = () => {
    const navigate = useNavigate();
    const { id } = useParams();

    const {
        data: company,
        loading,
        error,
    } = useRequest(companyService.fetch, {
        defaultParams: [id],
        onError: error => {
            navigate("/my-company/supervised", { replace: true, state: { error: "Azienda non trovata" } });
        },
    });
    const contextValue = {
        company,
        loading,
    };

    const [currentTab, setCurrentTab] = useState("documents");

    const tabs = [{ value: "documents", label: "Documenti Aziendali" }];

    if (loading) {
        return <div>Caricamento azienda</div>;
    }

    return (
        <PageContainer>
            <SupervisedContext.Provider value={contextValue}>
                <div className='flex flex-row flex-wrap justify-start gap-4 w-full mb-3'>
                    <BaseCompanyInfo company={company} loading={loading} />
                    <BaseCompanyContacts company={company} loading={loading} />
                    <BaseCompanyEmails company={company} loading={loading} />
                    <CompanyPublicLinks company={company} loading={loading} />
                </div>

                <AdaptableCard>
                    <Tabs value={currentTab} onChange={value => setCurrentTab(value)}>
                        <TabList>
                            {tabs.map((tab, index) => (
                                <TabNav key={index} value={tab.value}>
                                    {tab.label}
                                </TabNav>
                            ))}
                        </TabList>
                        <div className='px-4 py-6'>
                            <Outlet />
                        </div>
                    </Tabs>
                </AdaptableCard>
            </SupervisedContext.Provider>
        </PageContainer>
    );
};

export default SupervisedShow;
