import axios from "axios";
import qs from "qs";
import { redirect } from "react-router-dom";
import { useSanctum, withSanctum } from "react-sanctum";

import { sessionConfig } from "./session.config";

const onRequest = config => {
    const token = sessionConfig.getToken();
    const currentCompanyId = sessionConfig.getCurrentCompanyId();

    if (currentCompanyId) {
        config.headers["Current-Company"] = currentCompanyId ?? null;
    }

    if (token) {
        config.headers["Authorization"] = token;
    }
    config.paramsSerializer = params =>
        qs.stringify(params, {
            arrayFormat: "comma",
            skipNulls: true,
        });

    return config;
};

const onRequestError = error => {
    return Promise.reject(error);
};

const onResponse = response => {
    return response;
};

const onResponseError = async error => {
    // TODO il redirect in caso non sono autorizzato non può risolversi con l'auto logout, in quanto anche la logout darebbe lo stesso risultato (500)
    if (error.response.status === 500 && error.response.data.message === "User does not have access to this company") {
        // window.location.href = '/logout';
    }
    return Promise.reject(error);
};

export const setupInterceptorsTo = axiosInstance => {
    axiosInstance.interceptors.request.use(onRequest, onRequestError);
    axiosInstance.interceptors.response.use(onResponse, onResponseError);
    return axiosInstance;
};

const api = setupInterceptorsTo(
    axios.create({
        baseURL: process.env.REACT_APP_BACKEND_URL,
        headers: {
            "Content-Type": "application/json",
            Accept: "application/json",
        },
        withCredentials: true,
    })
);

export default api;
