import { useRequest } from "ahooks";
import React, { createContext } from "react";
import { Navigate, Outlet } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import listService from "../../../../services/listService";
import usePermissions from "../../../../utils/hooks/usePermissions";

export const UsersContext = createContext({
    roles: [],
});

const Users = () => {
    const { user } = useSanctum();
    const { data: roles } = useRequest(listService.roles);

    const hasPermission = usePermissions(user.role.permissions, ["users.view"]);
    const contextValue = {
        roles,
    };

    return hasPermission ? (
        <UsersContext.Provider value={contextValue}>
            <Outlet />
        </UsersContext.Provider>
    ) : (
        <Navigate to='/' />
    );
};

export default Users;
