import React from "react";

import AttachmentCreate from "../../../../views/protected/admin/attachments/AttachmentCreate";
import AttachmentShow from "../../../../views/protected/admin/attachments/AttachmentShow";
import Attachments from "../../../../views/protected/admin/attachments/Attachments";
import AttachmentsIndex from "../../../../views/protected/admin/attachments/AttachmentsIndex";

const companiesRoutes = {
    element: <Attachments />,
    children: [
        {
            path: "",
            element: <AttachmentsIndex />,
        },
        {
            path: "create",
            element: <AttachmentCreate />,
        },
        {
            path: ":id",
            element: <AttachmentShow />,
        },
    ],
};

export default companiesRoutes;
