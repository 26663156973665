import api from "../configs/api.config";

const COMPANY_GROUP_BASE_URL = "/company-groups";
const fetchAll = params => api.get(COMPANY_GROUP_BASE_URL, { params }).then(res => res.data);
const noPaginate = params =>
    api.get(COMPANY_GROUP_BASE_URL, { params: { ...params, per_page: -1 } }).then(res =>
        res.data.data.map(d => {
            return { ...d, label: d.name, value: d.id };
        })
    );
const fetch = uuid => api.get(`${COMPANY_GROUP_BASE_URL}/${uuid}`).then(res => res.data.data);
const store = params => api.post(`${COMPANY_GROUP_BASE_URL}`, params).then(res => res.data.data);
const update = (uuid, params) => api.put(`${COMPANY_GROUP_BASE_URL}/${uuid}`, params).then(res => res.data.data);
const destroy = uuid => api.delete(`${COMPANY_GROUP_BASE_URL}/${uuid}`);
const attachToGroup = (groupUuid, params) => api.post(`${COMPANY_GROUP_BASE_URL}/${groupUuid}/companies`, params).then(res => res.data.data);

const companyGroupService = {
    fetchAll,
    noPaginate,
    fetch,
    store,
    update,
    destroy,
    attachToGroup,
};

export default companyGroupService;
