import React from "react";

const CardHeader = ({ title = "", toolbar = undefined }) => {
    return (
        <div className='flex justify-between items-center'>
            <span className='text-lg'>{title}</span>
            {toolbar}
        </div>
    );
};

export default CardHeader;
