import Companies from "../../../../../views/protected/admin/companies/Companies";
import CompaniesIndex from "../../../../../views/protected/admin/companies/CompaniesIndex";
import CompanyCreate from "../../../../../views/protected/admin/companies/CompanyCreate";
import { companyRoutes } from "./companyRoutes";

const companiesRoutes = {
    element: <Companies />,
    children: [
        {
            path: "",
            element: <CompaniesIndex />,
        },
        {
            path: "create",
            element: <CompanyCreate />,
        },
        {
            path: ":id/*",
            ...companyRoutes,
        },
    ],
};

export default companiesRoutes;
