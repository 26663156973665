import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../services/attachmentService";
import CompanyAttachmentCreateForm from "../../../_forms/attachments/company/CompanyAttachmentCreateForm";
import { companyAttachmentFormConfig } from "../../../_forms/attachments/company/companyAttachmentFormConfig";

const AttachmentCreate = () => {
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Formik
                validationSchema={companyAttachmentFormConfig.createValidationSchema}
                initialValues={companyAttachmentFormConfig.createInitialValues}
                onSubmit={(values, { setSubmitting }) => {
                    attachmentService
                        .store(values)
                        .then(res => {
                            toast.push(<Notification title='Allegato caricato con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, touched, resetForm, setFieldTouched, errors, values, setFieldValue }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Aggiungi documento alla bacheca aziendale '
                                    description='Aggiungi spunta per rendere il file visibile all’esterno della piattaforma per renderlo visibile ai dipendenti'
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        </>
                                    }
                                />
                            }
                        >
                            <CompanyAttachmentCreateForm loading={false} />
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default AttachmentCreate;
