import { Field } from "formik";
import React from "react";

const FormCheckbox = ({ label, ...props }) => {
    return (
        <div className='flex gap-2 items-start justify-start'>
            <Field type='checkbox' className={`checkbox ${props.disabled ? "bg-neutral-100 text-neutral-300" : "text-sky-800"}`} {...props} />
            <div className={"flex flex-col gap-1"}>
                <span className='font-semibold'>{label}</span>
                {props?.description && <span className='text-xs'>{props.description}</span>}
            </div>
        </div>
    );
};

export default FormCheckbox;
