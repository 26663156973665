import { useFormikContext } from "formik";
import { get, has } from "lodash";
import React from "react";

import Select from "../ui/Select";

const FormSelect = ({ name, ...props }) => {
    const { values, setFieldValue, setFieldTouched, errors } = useFormikContext();

    return (
        <Select
            invalid={has(errors, name)}
            name={name}
            isSearchable={true}
            isClearable={true}
            value={get(values, name)}
            onChange={value => setFieldValue(name, value)}
            onBlur={() => setFieldTouched(name)}
            {...props}
        />
    );
};

export default FormSelect;
