import { Menu, Tooltip } from "components/ui";
import React from "react";
import { Link, useMatch } from "react-router-dom";

import PermissionsCheck from "../../../../shared/PermissionsCheck";
import MenuIcon from "./MenuIcon";

const { MenuItem } = Menu;

const CollapsedItem = ({ title, children }) => {
    return (
        <Tooltip title={title} placement='right'>
            {children}
        </Tooltip>
    );
};

const DefaultItem = ({ nav, onLinkClick, sideCollapsed }) => {
    const match = useMatch(nav.path);

    return (
        <PermissionsCheck
            is_system_admin={nav.is_system_admin}
            can_manage_other_companies={nav?.can_manage_other_companies}
            is_supervised={nav?.is_supervised}
            permissions={nav.permissions || []}
        >
            <MenuItem key={nav.key} eventKey={nav.key} className='mb-2' isActive={!!match}>
                <Link
                    to={nav.path}
                    onClick={() =>
                        onLinkClick?.({
                            key: nav.key,
                            title: nav.title,
                            path: nav.path,
                        })
                    }
                    className='flex items-center h-full w-full '
                >
                    <MenuIcon icon={nav.icon} />
                    {!sideCollapsed && <span>{nav.title}</span>}
                </Link>
            </MenuItem>
        </PermissionsCheck>
    );
};

const SingleMenuItem = ({ nav, onLinkClick, sideCollapsed, userAuthority }) => {
    return (
        <PermissionsCheck
            is_system_admin={nav.is_system_admin}
            can_manage_other_companies={nav?.can_manage_other_companies}
            is_supervised={nav?.is_supervised}
            permissions={nav.permissions || []}
        >
            {sideCollapsed ? (
                <CollapsedItem title={nav.title}>
                    <DefaultItem nav={nav} sideCollapsed={sideCollapsed} onLinkClick={onLinkClick} userAuthority={userAuthority} />
                </CollapsedItem>
            ) : (
                <DefaultItem nav={nav} sideCollapsed={sideCollapsed} onLinkClick={onLinkClick} userAuthority={userAuthority} />
            )}
        </PermissionsCheck>
    );
};

export default SingleMenuItem;
