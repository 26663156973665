import React from "react";

import ForceResetPasswordForm from "./ForceResetPasswordForm";

const ForceResetPassword = () => {
    return (
        <>
            <div className='mb-8'>
                <h3 className='mb-1'>Password scaduta</h3>
                <p>La tua password è scaduta. Aggiornala.</p>
            </div>
            <ForceResetPasswordForm />
        </>
    );
};

export default ForceResetPassword;
