import { useRequest } from "ahooks";
import React, { useState } from "react";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import PermissionsCheck from "../../../../components/shared/PermissionsCheck";
import { Card } from "../../../../components/ui";
import Accordion from "../../../../components/ui/Accordion/Accordion";
import AccordionItem from "../../../../components/ui/Accordion/AccordionItem";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import UserDisplay from "../../../../components/ui/Users/UserDisplay";
import UserInviteState from "../../../../components/ui/Users/UserInviteState";
import UserState from "../../../../components/ui/Users/UserState";
import usersService from "../../../../services/usersService";
import AdminUserCreate from "./_components/AdminUserCreate";
import AdminUserDropdown from "./_components/AdminUserDropdown";
import AdminUserShow from "./_components/AdminUserShow";

const UsersIndex = () => {
    const [users, setUsers] = useState([]);

    const { loading } = useRequest(usersService.fetchAll, {
        defaultParams: [{ per_page: -1 }],
        onSuccess: data => setUsers(data?.data ?? []),
    });

    const handleUpdate = user => setUsers(users.map(u => (u.id === user?.id ? user : u)));

    return (
        <PageContainer>
            <Card header={<PageHeader title='Gestione utenti' />}>
                {!loading && (
                    <Accordion defaultValue={undefined}>
                        {users.map(user => (
                            <AccordionItem
                                key={user.id}
                                value={user.id}
                                title={
                                    <div className='flex flex-grow items-center justify-between gap-4'>
                                        <UserDisplay user={user}>{user.email}</UserDisplay>
                                        <div className='flex items-center justify-end gap-4'>
                                            <UserState user={user} field='active' />
                                            <UserInviteState value={user.welcome_valid_until} />
                                            <AdminUserDropdown
                                                user={user}
                                                onToggleActivation={handleUpdate}
                                                onDelete={() => setUsers(users.filter(u => u.id !== user.id))}
                                                onReInvite={handleUpdate}
                                            />
                                        </div>
                                    </div>
                                }
                            >
                                <AdminUserShow user={user} onUpdate={handleUpdate} />
                            </AccordionItem>
                        ))}
                        <AccordionItem value='create' title='Aggiungi nuovo utente'>
                            <AdminUserCreate onCreate={user => setUsers([...users, user])} />
                        </AccordionItem>
                    </Accordion>
                )}
            </Card>
        </PageContainer>
    );
};

export default UsersIndex;
