import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../services/attachmentService";
import CompanyAttachmentEditForm from "../../../_forms/attachments/company/CompanyAttachmentEditForm";
import { companyAttachmentFormConfig } from "../../../_forms/attachments/company/companyAttachmentFormConfig";

const AttachmentShow = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const { data: attachment, loading, mutate } = useRequest(attachmentService.fetch, { defaultParams: [id] });

    const [initialValues, setInitialValues] = useState(companyAttachmentFormConfig.editInitialValues);

    useEffect(() => {
        if (attachment) {
            setInitialValues(companyAttachmentFormConfig.setInitialValues(attachment));
        }
    }, [attachment]);

    const handleDelete = () => {
        attachmentService
            .destroy(attachment.uuid)
            .then(res => {
                toast.push(<Notification title='Allegato eliminato con successo' type='success' />);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={companyAttachmentFormConfig.editValidationSchema}
                initialValues={initialValues}
                enableReinitialize={true}
                onSubmit={(values, { setSubmitting }) => {
                    attachmentService
                        .update(attachment.uuid, values)
                        .then(res => {
                            toast.push(<Notification title='Allegato aggiornato con successo' type='success' />);
                            mutate(res);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, touched, resetForm, setFieldTouched, errors, values, setFieldValue }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Modifica documento della bacheca aziendale'
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton handleClick={() => resetForm()} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                            <DestroyButton
                                                message={`Sei sicuro di voler eliminare ${attachment?.name}?`}
                                                loading={loading}
                                                handleClick={() => handleDelete()}
                                            />
                                        </>
                                    }
                                />
                            }
                        >
                            <CompanyAttachmentEditForm loading={false} attachment={attachment} />
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default AttachmentShow;
