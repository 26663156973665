import {useRequest} from "ahooks";
import React from "react";
import {useNavigate} from "react-router-dom";

import documentCategoryService from "../../../../services/documentCategoryService";
import Folder from "./Folder";

const DocumentCategoryNavigator = () => {
    const {loading, data: documentCategories} = useRequest(documentCategoryService.noPaginate);
    const navigate = useNavigate();

    return (
        <div>
            <div className='flex items-center justify-start gap-2 font-bold min-h-[40px]'>
                <span className='document-breadcrumb-item-disabled'>↑</span>
                <span className='document-breadcrumb-item-disabled'>Categorie</span>
            </div>
            <div className='grid grid-cols-1 lg:grid-cols-2 gap-4'>
                {!loading ? (
                    documentCategories?.map((dc, index) => (
                        <Folder key={index} label={dc.name} description={dc.description}
                                onSelect={() => navigate(dc.uuid)}/>
                    ))
                ) : (
                    <div>Loading</div>
                )}
            </div>
        </div>
    );
};

export default DocumentCategoryNavigator;
