import React, { createContext, useState } from "react";

export const AccordionContext = createContext({
    currentSelected: undefined,
    setCurrentSelected: () => {},
});
const Accordion = ({ defaultValue, children }) => {
    const [currentSelected, setCurrentSelected] = useState(defaultValue);

    const accordionContextValue = {
        currentSelected,
        setCurrentSelected,
    };

    return (
        <AccordionContext.Provider value={accordionContextValue}>
            <div className='grid my-4'>{children}</div>
        </AccordionContext.Provider>
    );
};

export default Accordion;
