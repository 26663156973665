import navigationIcon from "configs/navigation.config/_components/navigation-icon.config";
import React from "react";

export const Icon = ({ component: Component }) => {
    return (
        <>
            <Component />
        </>
    );
};

const MenuIcon = ({ icon, gutter }) => {
    if (typeof icon !== "string" && !icon) {
        return <></>;
    }

    return <span className={`text-2xl ${gutter ? "ltr:mr-2 rtl:ml-2" : ""}`}>{navigationIcon[icon]}</span>;
};

MenuIcon.defaultProps = {
    gutter: true,
};

export default MenuIcon;
