import React from "react";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import PageHeader from "../../../../components/ui/Page/PageHeader";

const AdminDashboard = () => {
    return (
        <PageContainer>
            <PageHeader title='Benvenuto STUDIO BARZAGHI'></PageHeader>
        </PageContainer>
    );
};

export default AdminDashboard;
