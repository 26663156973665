import React from "react";

import DownloadButton from "../Buttons/_components/DownloadButton";
import PreviewButton from "../Buttons/_components/PreviewButton";
import UserDisplay from "../Users/UserDisplay";

const Attachment = ({ attachment }) => {
    return (
        <div className='flex items-center justify-between align-middle gap-4 py-4 border-b border-gray-200 dark:border-gray-600'>
            <div className='flex items-center justify-start align-middle'>
                <div className='mr-3 flex gap-1'>
                    <PreviewButton label={attachment.name} filename={attachment.media.file_name} url={attachment.media.url} />
                    <DownloadButton label={attachment.name} filename={attachment.media.file_name} url={attachment.media.url} />
                </div>
                <div className='flex flex-col'>
                    <span className='font-bold'>{attachment?.name}</span>
                    <span className='font-semibold'>{attachment?.description}</span>
                </div>
            </div>

            <UserDisplay user={attachment.user} />
        </div>
    );
};

export default Attachment;
