import { useRequest } from "ahooks";
import { useFormikContext } from "formik";
import React from "react";

import documentCategoryService from "../../../services/documentCategoryService";
import Select from "../Select/Select";

const DocumentCategoryPicker = ({ name }) => {
    const { values, touched, errors, setFieldTouched, setFieldValue } = useFormikContext();
    const { data: documentCategories = [], loading } = useRequest(documentCategoryService.noPaginate);

    return (
        <Select
            form={{ touched, errors }}
            field={{ name: name }}
            value={values[name]}
            onBlur={() => setFieldTouched(name)}
            onChange={value => setFieldValue(name, value)}
            options={documentCategories}
            isClearable={true}
            isLoading={loading}
            placeholder='Seleziona una categoria documento'
            size='sm'
            className='min-w-[320px]'
        />
    );
};

export default DocumentCategoryPicker;
