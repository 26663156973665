import React from "react";
import { useSanctum } from "react-sanctum";
import usePermissions from "utils/hooks/usePermissions";

const PermissionsCheck = ({ is_system_admin = false, can_manage_other_companies, is_supervised, permissions = [], children, fallback }) => {
    const { user } = useSanctum();

    const isAdminMatched = is_system_admin === user.is_system_admin;

    const canManageOtherCompanyMatch =
        can_manage_other_companies !== undefined ? user?.company?.can_manage_other_companies === can_manage_other_companies : true;

    const isSupervised = is_supervised !== undefined ? user?.company?.is_supervised === is_supervised : true;

    const roleMatched = usePermissions(user.role?.permissions || [], permissions, false);

    return roleMatched && isAdminMatched && canManageOtherCompanyMatch && isSupervised ? children : fallback ? fallback : <></>;
};

export default PermissionsCheck;
