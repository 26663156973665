import { get } from "lodash";
import React from "react";

import Tag from "../Tag";

const UserState = ({ user, field }) => {
    return get(user, field) ? (
        <Tag suffix className='bg-white' suffixClass='bg-green-500'>
            Attivo
        </Tag>
    ) : (
        <Tag suffix className='bg-white' suffixClass='bg-red-500'>
            Disattivo
        </Tag>
    );
};

export default UserState;
