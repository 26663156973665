import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../services/attachmentService";
import companyGroupService from "../../../../services/companyGroupService";
import GlobalAttachmentCreateForm from "../../../_forms/attachments/global/GlobalAttachmentCreateForm";
import { globalAttachmentFormConfig } from "../../../_forms/attachments/global/globalAttachmentFormConfig";

const AttachmentCreate = () => {
    const navigate = useNavigate();
    const { data: companyGroups, loading } = useRequest(companyGroupService.noPaginate);

    return (
        <PageContainer>
            <Formik
                validationSchema={globalAttachmentFormConfig.createValidationSchema}
                initialValues={globalAttachmentFormConfig.createInitialValues}
                onSubmit={(values, { setSubmitting }) => {
                    const data = { ...values, company_groups: values.company_groups.map(g => g.value) };
                    console.log(data);
                    attachmentService
                        .store(data)
                        .then(res => {
                            toast.push(<Notification title='Allegato caricato con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, resetForm }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title={"Crea nuovo allegato"}
                                    description={"Carica un file e rendilo disponibile alle aziende selezionando la visibilità e i gruppi azienda"}
                                    toolbar={
                                        <>
                                            <CancelButton handleClick={() => navigate("../")} label={"Annulla"} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <GlobalAttachmentCreateForm loading={loading} companyGroups={companyGroups || []} />
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default AttachmentCreate;
