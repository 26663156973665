import { HiPhoneOutgoing } from "@react-icons/all-files/hi/HiPhoneOutgoing";
import { HiQuestionMarkCircle } from "@react-icons/all-files/hi/HiQuestionMarkCircle";
import React from "react";
import { HiEnvelope } from "react-icons/hi2";

import { Card } from "../index";

const StudioBarzahiInfo = ({ company, referent_name = null, referent_contact = null }) => {
    return (
        <Card className='flex items-center justify-start flex-1'>
            <div className='flex items-center justify-start gap-4'>
                <HiQuestionMarkCircle className='text-neutral-300' size={30} />
                <div>
                    <h6>Studio Barzaghi Monza</h6>
                    <div className='flex items-center gap-1 justify-start'>
                        <HiPhoneOutgoing />
                        +39 039 230 2879
                    </div>
                    <div className='flex items-center gap-1 justify-start'>
                        <HiEnvelope />
                        info@studiobarzaghimonza.it
                    </div>
                </div>
            </div>
        </Card>
    );
};

export default StudioBarzahiInfo;
