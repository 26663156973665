import { HiLockClosed } from "@react-icons/all-files/hi/HiLockClosed";
import { HiRefresh } from "@react-icons/all-files/hi/HiRefresh";
import React from "react";
import SweetAlert from "sweetalert2";

import companyService from "../../../services/companyService";
import publicCompanyService from "../../../services/publicCompanyService";
import PermissionsCheck from "../../shared/PermissionsCheck";
import CopyToClipboardButton from "../Buttons/_components/CopyToClipboardButton";
import Card from "../Card";
import { LoadingMessage } from "../LoadingMessage/LoadingMessage";
import PageHeader from "../Page/PageHeader";
import { Notification } from "../index";
import toast from "../toast";

const CompanyPublicLinks = ({ company, updateCompany = null, withPasswordRefresh = false }) => {
    const handleRefreshPublicPassword = () => {
        SweetAlert.fire({
            icon: "warning",
            text: "Sei sicuro di voler cambiare la password di accesso all'area pubblica? Il sistema genererà in automatico una nuova password sicura.",
        }).then(res => {
            if (res.isConfirmed) {
                companyService
                    .resetPublicPassword(company.uuid)
                    .then(res => {
                        toast.push(<Notification title='Password aggiornata con successo' type='success' />);
                        updateCompany({
                            ...company,
                            public_password: res.public_password,
                        });
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
            }
        });
    };

    const defaultIcon = <HiLockClosed className='text-neutral-300' size={30} />;

    const changePasswordIcon = (
        <HiRefresh onClick={() => handleRefreshPublicPassword()} className='text-neutral-300 hover:text-sky-800 cursor-pointer' size={30} />
    );

    return (
        <Card className='flex items-center justify-start flex-1'>
            <PageHeader
                icon={
                    withPasswordRefresh && updateCompany ? (
                        <PermissionsCheck permissions={["reset-public-password"]} is_system_admin={false} fallback={defaultIcon}>
                            {changePasswordIcon}
                        </PermissionsCheck>
                    ) : (
                        defaultIcon
                    )
                }
                title={
                    company ? (
                        <>
                            <CopyToClipboardButton
                                label='Indirizzo bacheca pubblica'
                                textToCopy={publicCompanyService.getPublicCompanyDashboardUrl(company?.uuid, true)}
                            />
                            <CopyToClipboardButton label='Password bacheca pubblica' textToCopy={company?.public_password} />
                        </>
                    ) : (
                        <LoadingMessage loading={true} />
                    )
                }
            />
        </Card>
    );
};

export default CompanyPublicLinks;
