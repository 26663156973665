import { formatISO9075 } from "date-fns";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormSelect from "../../../../components/RefactoredForms/FormSelect";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, DatePicker, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import Notification from "../../../../components/ui/Notification";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import CompanyPicker from "../../../../components/ui/_forms/CompanyPicker";
import DocumentCategoryPicker from "../../../../components/ui/_forms/DocumentCategoryPicker";
import FormikSubmitButton from "../../../../components/ui/_forms/FormikSubmitButton";
import uploadService from "../../../../services/uploadService";
import dateHelper from "../../../../utils/dateHelper";

const UploadCreate = () => {
    const navigate = useNavigate();

    const initialValues = {
        document_category: null,
        company: null,
        payload: {
            auto_publish_at: null,
            month: null,
            year: null,
        },
    };

    const validation = Yup.object().shape({
        document_category: Yup.object().nullable().required(),
        company: Yup.object().nullable().required(),
        payload: Yup.object().shape({
            auto_publish_at: Yup.date().nullable().notRequired(),
            month: Yup.object().nullable().when("is_split_by_month", {
                is: true,
                then: Yup.object().nullable().required(),
                otherwise: Yup.object().nullable().notRequired(),
            }),
            year: Yup.object().nullable().when("is_split_by_year", {
                is: true,
                then: Yup.object().nullable().required(),
                otherwise: Yup.object().nullable().notRequired(),
            }),
        }),
    });

    return (
        <PageContainer>
            <Formik
                enableReinitialize={true}
                validationSchema={validation}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    uploadService
                        .store({
                            company_id: values.company?.id,
                            document_category_id: values?.document_category?.id,
                            payload: {
                                auto_publish_at: values?.payload?.auto_publish_at ? formatISO9075(values?.payload?.auto_publish_at) : null,
                                month: values?.payload?.month?.value || null,
                                year: values?.payload?.year?.value || null,
                            },
                        })
                        .then(res => {
                            toast.push(<Notification title='Caricamento creato con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ errors, resetForm, touched, values, setFieldTouched, setFieldValue }) => (
                    <Form>
                        <Card>
                            <div className='flex flex-col gap-4'>
                                <PageHeader
                                    title={"Crea un caricamento massivo"}
                                    description={"Seleziona un'azienda e una categoria di documento, successivamente potrai caricare i documenti."}
                                />

                                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                    <FormGroup name={"document_category"} label={"Categoria documento"} required>
                                        <DocumentCategoryPicker name='document_category' />
                                    </FormGroup>
                                    <FormGroup name={"company"} label={"Azienda"} required>
                                        <CompanyPicker name='company' />
                                    </FormGroup>
                                    <FormGroup name={"payload.auto_publish_at"} label={"Data di pubblicazione"}>
                                        <DatePicker
                                            placeholder='Data di pubblicazione'
                                            amPm={false}
                                            clearable={true}
                                            labelFormat={{ month: "MM", year: "YYYY" }}
                                            inputFormat='DD/MM/YYYY'
                                            value={values?.payload?.auto_publish_at || null}
                                            onBlur={() => setFieldTouched("payload.auto_publish_at")}
                                            onChange={value => setFieldValue("payload.auto_publish_at", value)}
                                        />
                                    </FormGroup>
                                    {values?.document_category?.is_split_by_month && (
                                        <FormGroup name={"payload.month"} label={"Mese"} required>
                                            <FormSelect
                                                isClearable={true}
                                                isMulti={false}
                                                placeholder='Seleziona un mese'
                                                name='payload.month'
                                                onBlur={() => setFieldTouched("payload.month")}
                                                onChange={value => setFieldValue("payload.month", value)}
                                                options={dateHelper.getMonths()}
                                            />
                                        </FormGroup>
                                    )}
                                    {values?.document_category?.is_split_by_year && (
                                        <FormGroup name={"payload.year"} label={"Anno"} required>
                                            <FormSelect
                                                isClearable={true}
                                                isMulti={false}
                                                placeholder='Seleziona un anno'
                                                name='payload.year'
                                                onBlur={() => setFieldTouched("payload.year")}
                                                onChange={value => setFieldValue("payload.year", value)}
                                                options={dateHelper.getAllYears()}
                                            />
                                        </FormGroup>
                                    )}
                                </div>

                                <div className='flex justify-end gap-4'>
                                    <CancelButton handleClick={() => navigate("../")} label={"Annulla"} />
                                    <CancelButton handleClick={() => resetForm()} />
                                    <FormikSubmitButton />
                                </div>
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default UploadCreate;
