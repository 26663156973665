import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import PermissionsCheck from "../../../../components/shared/PermissionsCheck";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import documentTypeService from "../../../../services/documentTypeService";
import { documentTypesCols } from "./_components/documentTypesCols";

const DocumentTypesIndex = () => {
    const { data: documentTypes, run, loading } = useRequest(documentTypeService.fetchAll, { manual: true });
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Gestione tipologie documenti'
                        loading={loading}
                        toolbar={<CreateButton handleClick={() => navigate("create")} />}
                    />
                }
            >
                <LaravelTable columns={documentTypesCols} records={documentTypes} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default DocumentTypesIndex;
