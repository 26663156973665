import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import toast from "../../../../components/ui/toast";
import roleService from "../../../../services/roleService";
import RoleForm from "./_components/RoleForm";
import roleFormConfig from "./_components/roleFormConfig";

const RoleShow = () => {
    const { id } = useParams();
    const navigate = useNavigate();

    const [initialValues, setInitialValues] = useState(roleFormConfig.initialValues);
    const { data: role, mutate } = useRequest(roleService.fetch, { defaultParams: [id] });

    useEffect(() => {
        if (id && role) {
            setInitialValues(roleFormConfig.setInitialValues(role));
        }
    }, [id, role]);

    const handleDelete = () => {
        roleService
            .destroy(id)
            .then(res => {
                toast.push(<Notification title='Ruolo eliminato con successo' type='success' />);
                navigate("../");
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    return (
        <PageContainer>
            <Formik
                enableReinitialize={true}
                initialValues={initialValues}
                validationSchema={roleFormConfig.validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    roleService
                        .update(id, values)
                        .then(res => {
                            toast.push(<Notification title='Ruolo aggiornato con successo' type='success' />);
                            mutate(res);
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, resetForm }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Modifica ruolo'
                                    loading={false}
                                    toolbar={
                                        <>
                                            <CancelButton handleClick={() => resetForm()} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                            <DestroyButton
                                                message={`Sei sicuro di voler eliminare ${role?.name}?`}
                                                loading={isSubmitting}
                                                handleClick={() => handleDelete()}
                                            />
                                        </>
                                    }
                                />
                            }
                        >
                            <RoleForm />
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default RoleShow;
