import React from "react";

import BackgroundImage from "../../../assets/svg/authBackground/1.svg";
import Simple from "./_components/Simple";

const AuthLayout = () => {
    return (
        <div
            className='app-layout-blank  flex flex-auto flex-col h-[100vh]'
            style={{
                background: `url(${BackgroundImage})`,
                backgroundSize: "cover",
                backgroundRepeat: "no-repeat",
                backgroundPosition: "center",
            }}
        >
            <Simple />
        </div>
    );
};

export default AuthLayout;
