const AddressDisplay = ({ address }) => {
    return (
        <>
            {address
                ? `${address?.address}, ${address.street_number} - ${address?.postal_code} ${address?.city} - ${address?.country}`
                : "Nessun indirizzo specificato"}
        </>
    );
};

export default AddressDisplay;
