import React from "react";

import ForgotPasswordForm from "./ForgotPasswordForm";

const ForgotPassword = () => {
    return (
        <>
            <div className='mb-8'>
                <h3 className='mb-1'>Password dimenticata?</h3>
                <p>Inserisci il tuo indirizzo email.</p>
            </div>
            <ForgotPasswordForm />
        </>
    );
};

export default ForgotPassword;
