import { useRequest } from "ahooks";
import React from "react";
import { Outlet } from "react-router-dom";

import documentTypeService from "../../../../services/documentTypeService";

export const UploadsContext = React.createContext({
    documentTypes: [],
});
const Uploads = () => {
    const { data: documentTypes = [] } = useRequest(documentTypeService.noPaginate);

    const contextValue = {
        documentTypes,
    };

    return (
        <UploadsContext.Provider value={contextValue}>
            <Outlet />
        </UploadsContext.Provider>
    );
};

export default Uploads;
