import { HiClipboardCopy } from "@react-icons/all-files/hi/HiClipboardCopy";
import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard/src";

import { Notification, Tooltip, toast } from "../../index";
import Button from "../Button";

const CopyToClipboardButton = ({ disabled, loading, label, textToCopy }) => {
    const handleClick = () => {
        // navigator.clipboard?.writeText(textToCopy);
        toast.push(<Notification title='Testo copiato negli appunti' type='success' />);
    };

    return (
        <div>
            <Tooltip title='Click per copiare negli appunti'>
                <CopyToClipboard text={textToCopy} onCopy={handleClick}>
                    {/*<button>Copy to clipboard with button</button>*/}

                    <Button type='button' disabled={disabled} loading={loading} size='xs' variant='plain' icon={<HiClipboardCopy />}>
                        {label}
                    </Button>
                </CopyToClipboard>
            </Tooltip>
        </div>
    );
};

export default CopyToClipboardButton;
