import { Form, Formik } from "formik";
import React from "react";
import * as Yup from "yup";

import FormDescription from "../../../../../components/RefactoredForms/FormDescription";
import PasswordInput from "../../../../../components/shared/PasswordInput";
import { Button, FormContainer, Notification } from "../../../../../components/ui";
import FormRow from "../../../../../components/ui/Form/FormRow/FormRow";
import toast from "../../../../../components/ui/toast/toast";
import userService from "../../../../../services/userService";

const validationSchema = Yup.object().shape({
    password: Yup.string().min(8).required("Obbligatorio"),
    password_confirmation: Yup.string()
        .oneOf([Yup.ref("password"), null], "La password non coincide")
        .required("Obbligatorio"),
    current_password: Yup.string().max(255).required("Obbligatorio"),
});

const AccountPassword = () => {
    const initialValues = {
        password: "",
        password_confirmation: "",
        current_password: "",
    };

    return (
        <Formik
            initialValues={initialValues}
            validationSchema={validationSchema}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                userService
                    .updatePassword(values)
                    .then(res => toast.push(<Notification title={"Password aggiornata con successo"} type='success' />))
                    .catch(err => toast.push(<Notification title={"Impossibile aggiornare la password"} type='error' />))
                    .finally(() => {
                        setSubmitting(false);
                        resetForm();
                    });
            }}
        >
            {({ values, touched, errors, isSubmitting, valid, resetForm }) => {
                return (
                    <Form>
                        <FormContainer>
                            <FormDescription title='Password' desc='Aggiorna la tua password.' />
                            <FormRow name='password' label='Nuova password' component={PasswordInput} required={true} />
                            <FormRow name='password_confirmation' label='Conferma nuova password' component={PasswordInput} required={true} />
                            <FormRow name='current_password' label='Password corrente' component={PasswordInput} required={true} />
                        </FormContainer>

                        <div className='mt-4 ltr:text-right'>
                            <Button className='ltr:mr-2 rtl:ml-2' type='button' onClick={resetForm}>
                                Annulla
                            </Button>
                            <Button variant='solid' loading={isSubmitting} type='submit'>
                                {isSubmitting ? "Salvataggio..." : "Salva"}
                            </Button>
                        </div>
                    </Form>
                );
            }}
        </Formik>
    );
};
export default AccountPassword;
