import React from "react";

import FormBoolDisplay from "../ui/Form/FormDisplay/FormBoolDisplay";

const FormCheckboxDisplay = ({ checked = false, children, info = null }) => {
    return (
        <div className='flex gap-1 items-start justify-start'>
            <FormBoolDisplay value={checked} />
            <div className={"flex flex-col gap-1"}>
                <div className='font-semibold'>{children || "---"} </div>
                {/* ADDITIONAL INFO IF NEEDED*/}
                {info && <span className='text-xs'>{info}</span>}
            </div>
        </div>
    );
};

export default FormCheckboxDisplay;
