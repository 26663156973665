import { first, isArray } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import { Badge } from "../../../../../components/ui";
import DownloadButton from "../../../../../components/ui/Buttons/_components/DownloadButton";
import PreviewButton from "../../../../../components/ui/Buttons/_components/PreviewButton";
import FormBoolDisplay from "../../../../../components/ui/Form/FormDisplay/FormBoolDisplay";
import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";

export const attachmentsCols = [
    {
        header: "Allegato",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) => (
            <Link to={original.uuid} className='font-bold'>
                {original.code} {getValue()}
            </Link>
        ),
    },
    {
        header: "Descrizione",
        accessorKey: "description",
    },
    {
        header: "Gruppi",
        accessorKey: "company_groups",
        enableSorting: false,
        cell: ({ getValue }) => getValue().length,
    },
    {
        header: "Data Creazione",
        accessorKey: "created_at",
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
    {
        header: "Pubblico",
        accessorKey: "is_public",
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "Mostra in dashboard",
        accessorKey: "is_pin_to_dashboard",
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "File",
        accessorKey: "media",
        enableSorting: false,
        cell: ({ getValue, row: { original } }) => {
            const value = isArray(getValue()) ? first(getValue()) : getValue();
            return value ? (
                <div className='flex gap-1'>
                    <PreviewButton url={value.url} label={original.name} filename={value.file_name} />
                    <DownloadButton url={value.url} label={original.name} filename={value.file_name} />
                </div>
            ) : (
                <></>
            );
        },
    },
];
