import DocumentTypeCreate from "../../../../views/protected/admin/documentTypes/DocumentTypeCreate";
import DocumentTypeShow from "../../../../views/protected/admin/documentTypes/DocumentTypeShow";
import DocumentTypes from "../../../../views/protected/admin/documentTypes/DocumentTypes";
import DocumentTypesIndex from "../../../../views/protected/admin/documentTypes/DocumentTypesIndex";

const documentTypesRoutes = {
    element: <DocumentTypes />,
    children: [
        {
            path: "",
            element: <DocumentTypesIndex />,
        },
        {
            path: "create",
            element: <DocumentTypeCreate />,
        },
        {
            path: ":id",
            element: <DocumentTypeShow />,
        },
    ],
};

export default documentTypesRoutes;
