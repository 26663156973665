import { HiPaperClip } from "@react-icons/all-files/hi/HiPaperClip";
import React from "react";

import PageHeader from "../Page/PageHeader";
import { Card } from "../index";
import Attachment from "./Attachment";

const Attachments = ({ attachments, loading }) => {
    return (
        <>
            <PageHeader icon={<HiPaperClip />} title='Allegati' loading={loading} />
            <Card>{!loading && attachments.map((attachment, index) => <Attachment attachment={attachment} key={index} />)}</Card>
        </>
    );
};

export default Attachments;
