import { HiOutlineEmojiSad } from "@react-icons/all-files/hi/HiOutlineEmojiSad";
import React from "react";

const EmptyAccordionItem = ({ title }) => {
    return (
        <div>
            <span className={`py-3 px-5 flex items-center font-bold`}>
                <span className='mr-4'>
                    <HiOutlineEmojiSad />
                </span>
                {title || "Nessun risultato trovato"}
            </span>
        </div>
    );
};
export default EmptyAccordionItem;
