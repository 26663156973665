import { Alert, Button, FormContainer, FormItem, Input } from "components/ui";
import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import authService from "services/authService";
import useTimeOutMessage from "utils/hooks/useTimeOutMessage";
import * as Yup from "yup";

import ActionLink from "../../../components/shared/ActionLink";
import PasswordInput from "../../../components/shared/PasswordInput";

const validationSchema = Yup.object().shape({
    password: Yup.string().required("La password è obbligatoria"),
    password_confirmation: Yup.string().oneOf([Yup.ref("password"), null], "La password non coincide"),
});

const ResetPasswordForm = props => {
    const [resetComplete, setResetComplete] = useState(false);
    const [message, setMessage] = useTimeOutMessage();
    const { token } = useParams();

    const navigate = useNavigate();

    const initialValues = {
        email: "",
        password: "",
        password_confirmation: "",
    };

    return (
        <div>
            {resetComplete && (
                <Alert className='mb-4' type='success' showIcon>
                    Password aggiornata con successo!
                </Alert>
            )}

            {message && (
                <Alert className='mb-4' type='danger' showIcon>
                    {message}
                </Alert>
            )}

            <Formik
                initialValues={initialValues}
                enableReinitialize={true}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    setSubmitting(true);
                    authService
                        .resetPassword(token, values.email, values.password, values.password_confirmation)
                        .then(res => {
                            setResetComplete(true);
                        })
                        .catch(err => {
                            setMessage(err.response.data.message);
                        })
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form>
                        <FormContainer>
                            {!resetComplete ? (
                                <>
                                    <FormItem label='Indirizzo Email' invalid={errors.email && touched.email} errorMessage={errors.email}>
                                        <Field type='email' autoComplete='off' name='email' component={Input} />
                                    </FormItem>

                                    <FormItem label='Password' invalid={errors.password && touched.password} errorMessage={errors.password}>
                                        <Field autoComplete='off' name='password' placeholder='Password' component={PasswordInput} />
                                    </FormItem>
                                    <FormItem
                                        label='Conferma Password'
                                        invalid={errors.password_confirmation && touched.password_confirmation}
                                        errorMessage={errors.password_confirmation}
                                    >
                                        <Field
                                            autoComplete='off'
                                            name='password_confirmation'
                                            placeholder='Conferma Password'
                                            component={PasswordInput}
                                        />
                                    </FormItem>
                                    <Button block loading={isSubmitting} variant='solid' type='submit'>
                                        {isSubmitting ? "Invio richiesta..." : "Aggiorna Password"}
                                    </Button>
                                </>
                            ) : (
                                <Button block variant='solid' type='button' onClick={() => navigate("/auth/login")}>
                                    Accedi
                                </Button>
                            )}

                            <div className='mt-4 text-center'>
                                <span>Torna a </span>
                                <ActionLink to='/auth/login'>Accesso</ActionLink>
                            </div>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default ResetPasswordForm;
