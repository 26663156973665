import api from "../configs/api.config";

const SUPERVISION_BASE_URL = "/supervision";
const fetch = () => api.get(SUPERVISION_BASE_URL).then(res => res.data.data);
const update = params => api.put(SUPERVISION_BASE_URL, params).then(res => res.data.data);

const supervisionService = {
    fetch,
    update,
};

export default supervisionService;
