import { Form, Formik } from "formik";
import React, { useContext } from "react";

import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import officeService from "../../../../../../../services/officeService";
import { CompanyContext } from "../../Company";
import CompanyOfficeForm from "./_components/CompanyOfficeForm";
import companyOfficeFormConfig from "./_components/companyOfficeFormConfig";

const CompanyOfficeShow = ({ office, onUpdate, onDelete }) => {
    const { company } = useContext(CompanyContext);

    const handleDelete = () => {
        officeService
            .destroy(company.uuid, office.uuid)
            .then(res => {
                toast.push(<Notification title='Sede eliminata con successo' type='success' />);
                onDelete();
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />));
    };

    return (
        <Formik
            validationSchema={companyOfficeFormConfig.validationSchema}
            initialValues={{ ...companyOfficeFormConfig.initialValues, ...office }}
            onSubmit={(values, { setSubmitting, resetForm }) => {
                officeService
                    .update(company.uuid, office.uuid, values)
                    .then(res => {
                        toast.push(<Notification title='Sede aggiornata con successo' type='success' />);
                        onUpdate();
                    })
                    .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, isSubmitting, resetForm, submitForm }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <CompanyOfficeForm />

                        <div className='gap-4 flex justify-end'>
                            <CancelButton disabled={isSubmitting} handleClick={() => resetForm()} />
                            <SaveButton handleClick={submitForm} loading={isSubmitting} disabled={!isValid || isSubmitting} />
                            <DestroyButton
                                message='Sei sicuro di voler eliminare la sede?'
                                handleClick={handleDelete}
                                disabled={!isValid || isSubmitting}
                            />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CompanyOfficeShow;
