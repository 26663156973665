/**
 * Cut string at limit (default 50)
 * @param str
 * @param limit
 * @returns {string|*}
 */
const strLimit = (str, limit = 50) => {
    if (!!str) {
        return str.length > limit ? str.substring(0, limit) + "..." : str;
    }
    return "";
};

const stringHelper = {
    strLimit,
};

export default stringHelper;
