import { Form, Formik } from "formik";
import React, { useContext, useEffect, useState } from "react";
import * as Yup from "yup";

import FormCheckbox from "../../../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../components/RefactoredForms/FormInput";
import { Notification, toast } from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import companyService from "../../../../../../../services/companyService";
import { companyFormConfig } from "../../../_components/companyFormConfig";
import { CompanyContext } from "../../Company";

const CompanyDetailsTab = () => {
    const { company, setCompany, loading } = useContext(CompanyContext);
    const [initialValues, setInitialValues] = useState(companyFormConfig.companyDataInitialValues);

    useEffect(() => {
        if (!loading) {
            setInitialValues({
                ...initialValues,
                ...company.company_data,
                has_scheduled_notification: !!company.company_data?.default_upload_notification,
            });
        }
    }, [company]);

    return (
        <Formik
            validationSchema={Yup.object().shape(companyFormConfig.companyDataValidationSchema)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, { setSubmitting }) => {
                companyService
                    .update(company.uuid, {
                        ...company,
                        groups: company.groups.map(group => group.id),
                        company_data: {
                            ...values,
                            default_upload_notification: values.has_scheduled_notification ? { ...values.default_upload_notification } : null,
                        },
                    })
                    .then(res => {
                        toast.push(<Notification title='Azienda aggiornata con successo' type='success' />);
                        setCompany(res);
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ isValid, isSubmitting, resetForm, dirty, values }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <FormDescription title='Recapiti' />

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            <FormGroup name={"email"} label={"Email azienda (per notifiche aziendali)"}>
                                <FormInput name={"email"} type={"email"} />
                            </FormGroup>
                            <FormGroup name={"pec"} label={"PEC"}>
                                <FormInput name={"pec"} type={"email"} />
                            </FormGroup>
                            <FormGroup name={"phone"} label={"Telefono"}>
                                <FormInput name={"phone"} />
                            </FormGroup>
                        </div>

                        <FormDescription title='Referente' />

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            <FormGroup name={"referent_name"} label={"Nome e cognome Referente"}>
                                <FormInput name={"referent_name"} />
                            </FormGroup>
                        </div>

                        <FormDescription title='Legale Rappresentante' />

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            <FormGroup name={"legal_first_name"} label={"Nome legale rappresentante"}>
                                <FormInput name={"legal_first_name"} />
                            </FormGroup>
                            <FormGroup name={"legal_last_name"} label={"Cognome legale rappresentante"}>
                                <FormInput name={"legal_last_name"} />
                            </FormGroup>
                            <FormGroup name={"legal_fiscal_code"} label={"Codice fiscale legale rappresentante"}>
                                <FormInput name={"legal_fiscal_code"} />
                            </FormGroup>
                        </div>

                        <FormDescription title='Sede Legale' />

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            <FormGroup name={"legal_office.address"} label={"Indirizzo"}>
                                <FormInput name={"legal_office.address"} />
                            </FormGroup>
                            <FormGroup name={"legal_office.street_number"} label={"Numero civico"}>
                                <FormInput name={"legal_office.street_number"} />
                            </FormGroup>
                            <FormGroup name={"legal_office.city"} label={"Città"}>
                                <FormInput name={"legal_office.city"} />
                            </FormGroup>
                            <FormGroup name={"legal_office.postal_code"} label={"Cap"}>
                                <FormInput name={"legal_office.postal_code"} />
                            </FormGroup>
                            <FormGroup name={"legal_office.country"} label={"Nazione"}>
                                <FormInput name={"legal_office.country"} />
                            </FormGroup>
                        </div>

                        <FormDescription title='Altro' />

                        <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                            <FormGroup name={"inail_code"} label={"Codice INAIL"}>
                                <FormInput name={"inail_code"} />
                            </FormGroup>
                            <FormGroup name={"inail_classification"} label={"Classificazione INAIL"}>
                                <FormInput name={"inail_classification"} />
                            </FormGroup>
                            <FormGroup name={"building_fund_code"} label={"Codice cassa edile"}>
                                <FormInput name={"building_fund_code"} />
                            </FormGroup>
                        </div>

                        <FormDescription title='Impostazioni predefinite per le notifiche' />
                        <FormGroup required label='' name='has_scheduled_notification'>
                            <FormCheckbox label='Abilita notifica schedulata' name='has_scheduled_notification' />
                        </FormGroup>

                        {values.has_scheduled_notification && (
                            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4 mt-6'>
                                <FormGroup name='default_upload_notification.show_upload_file_list'>
                                    <FormCheckbox
                                        name={"default_upload_notification.show_upload_file_list"}
                                        label={"Abilita visualizzazione lista file"}
                                    />
                                </FormGroup>

                                <FormGroup name='default_upload_notification.send_to_company_email'>
                                    <FormCheckbox
                                        name={"default_upload_notification.send_to_company_email"}
                                        label="Abilita invio notifica all'indirizzo email aziendale"
                                    />
                                </FormGroup>

                                <FormGroup name='default_upload_notification.send_ccn_to_sysadmin'>
                                    <FormCheckbox name={"default_upload_notification.send_ccn_to_sysadmin"} label='Metti in copia Studio Barzaghi' />
                                </FormGroup>

                                <FormGroup name='default_upload_notification.send_to_company_admin_email'>
                                    <FormCheckbox
                                        name={"default_upload_notification.send_to_company_admin_email"}
                                        label={"Invia agli amministratori della società"}
                                    />
                                </FormGroup>

                                <FormGroup name='default_upload_notification.send_to_company_delegati_email'>
                                    <FormCheckbox
                                        name={"default_upload_notification.send_to_company_delegati_email"}
                                        label={"Invia ai delegati della società"}
                                    />
                                </FormGroup>

                                <FormGroup name='default_upload_notification.send_to_company_supervisor_email'>
                                    <FormCheckbox
                                        name={"default_upload_notification.send_to_company_supervisor_email"}
                                        label='Invia allo allo studio delegato'
                                    />
                                </FormGroup>

                                <FormGroup name='default_upload_notification.send_to_company_pec'>
                                    <FormCheckbox name={"default_upload_notification.send_to_company_pec"} label='Invia alla PEC della società' />
                                </FormGroup>
                            </div>
                        )}

                        <div className='gap-4 flex justify-end'>
                            <CancelButton disabled={!isValid || isSubmitting} handleClick={() => resetForm()} />
                            <SaveButton loading={isSubmitting} disabled={!isValid || isSubmitting || !dirty} />
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CompanyDetailsTab;
