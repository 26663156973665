import React from "react";

import Users from "../../../../views/protected/admin/users/Users";
import UsersIndex from "../../../../views/protected/admin/users/UsersIndex";

const usersRoutes = {
    element: <Users />,
    children: [
        {
            path: "",
            index: true,
            element: <UsersIndex />,
        },
    ],
};

export default usersRoutes;
