import Theme from "components/layout/ProtectedLayout/_context/Theme";
import React, { useState } from "react";
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { BrowserRouter } from "react-router-dom";
import { Sanctum } from "react-sanctum";

import { sanctumConfig } from "./configs/sanctum.config";
import { themeConfig } from "./configs/theme.config";
import history from "./history";
import AppRoutes from "./routes/AppRoutes";

export const ApplicationContext = React.createContext({});

function App() {
    const [sideNavCollapse, setSideNavCollapse] = useState(themeConfig.layout.sideNavCollapse);
    const [currentRouteKey, setCurrentRouteKey] = useState(null);
    const [currentCompany, setCurrentCompany] = useState(null);

    const applicationContextValue = {
        themeConfig,
        sideNavCollapse,
        setSideNavCollapse,
        currentRouteKey,
        setCurrentRouteKey,
        currentCompany,
        setCurrentCompany,
    };

    return (
        <Sanctum config={sanctumConfig}>
            <BrowserRouter history={history}>
                <ApplicationContext.Provider value={applicationContextValue}>
                    <Theme>
                        <AppRoutes />
                    </Theme>
                </ApplicationContext.Provider>
            </BrowserRouter>
        </Sanctum>
    );
}

export default App;
