import {first} from "lodash";
import React, {useContext} from "react";
import {
    HiBuildingOffice2,
    HiCircleStack,
    HiDocumentCheck,
    HiEye,
    HiFolderOpen,
    HiListBullet,
    HiPaperClip,
    HiUsers,
    HiAdjustmentsVertical
} from "react-icons/hi2";
import {matchRoutes, Outlet, useLocation, useNavigate} from "react-router-dom";

import AdaptableCard from "../../../../../components/shared/AdaptableCard";
import {Tabs} from "../../../../../components/ui";
import BaseCompanyContacts from "../../../../../components/ui/Company/BaseCompanyContacts";
import BaseCompanyEmails from "../../../../../components/ui/Company/BaseCompanyEmails";
import BaseCompanyInfo from "../../../../../components/ui/Company/BaseCompanyInfo";
import CompanyPublicLinks from "../../../../../components/ui/Company/CompanyPublicLinks";
import TabList from "../../../../../components/ui/Tabs/TabList";
import TabNav from "../../../../../components/ui/Tabs/TabNav";
import {CompanyContext} from "./Company";

const CompanyShow = () => {
    const {company, loading} = useContext(CompanyContext);
    const navigate = useNavigate();
    const currentLocation = useLocation();

    const tabs = [
        {
            value: "info",
            path: "/admin/companies/:id/info/*",
            label: "Anagrafica",
            description: "",
            icon: <HiListBullet/>,
            show: true,
        },
        {
            value: "details",
            path: "/admin/companies/:id/details/*",
            label: "Dettaglio",
            description: "",
            icon: <HiCircleStack/>,
            show: true,
        },
        {
            value: "settings",
            path: "/admin/companies/:id/settings/*",
            label: "Impostazioni",
            description: "",
            icon: <HiAdjustmentsVertical/>,
            show: true,
        },
        {
            value: "offices",
            path: "/admin/companies/:id/offices/*",
            label: "Sedi",
            description: "",
            icon: <HiBuildingOffice2/>,
            show: true,
        },
        {
            value: "supervisions",
            path: "/admin/companies/:id/supervisions/*",
            label: "Professionista delegato",
            description: "",
            icon: <HiEye/>,
            show: true,
        },
        {
            value: "supervised",
            path: "/admin/companies/:id/supervised/*",
            label: "Aziende con delega",
            icon: <HiDocumentCheck/>,
            show: (company?.supervised_companies || []).length > 0,
        },
        {
            value: "users",
            path: "/admin/companies/:id/users/*",
            label: "Utenti",
            description: "",
            icon: <HiUsers/>,
            show: true,
        },
        {
            value: "attachments",
            path: "/admin/companies/:id/attachments/*",
            label: "Allegati",
            description: "",
            icon: <HiPaperClip/>,
            show: true,
        },
        {
            value: "documents",
            path: "/admin/companies/:id/documents/*",
            label: "Documenti",
            description: "",
            icon: <HiFolderOpen/>,
            show: true,
        },
    ];

    const getCurrentMatch = () => first(matchRoutes(tabs, currentLocation)) || undefined;

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-row flex-wrap justify-center gap-4 w-full'>
                <BaseCompanyInfo company={company} loading={loading}/>
                <BaseCompanyContacts company={company} loading={loading}/>
                <BaseCompanyEmails company={company} loading={loading}/>
                <CompanyPublicLinks company={company} loading={loading}/>
            </div>

            <AdaptableCard>
                <Tabs value={getCurrentMatch()?.route?.value || ""}
                      onChange={value => navigate(value)}>
                    <TabList>
                        {tabs.map(
                            (tab, index) =>
                                tab.show && (
                                    <TabNav key={index} value={tab.value} icon={tab?.icon}>
                                        {tab.label}
                                    </TabNav>
                                )
                        )}
                    </TabList>
                    <div className='px-4 py-6'>
                        <Outlet/>
                    </div>
                </Tabs>
            </AdaptableCard>
        </div>
    );
};

export default CompanyShow;
