import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import companyGroupService from "../../../../services/companyGroupService";
import { companyGroupsCols } from "./_components/companyGroupsCols";

const CompanyGroupsIndex = () => {
    const { data: companyGroups, run, loading } = useRequest(companyGroupService.fetchAll, { manual: true });
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Gestione gruppi di aziende'
                        loading={loading}
                        toolbar={<CreateButton handleClick={() => navigate("create")} />}
                    />
                }
            >
                <LaravelTable columns={companyGroupsCols} records={companyGroups} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default CompanyGroupsIndex;
