import api from "../configs/api.config";

const DOCUMENT_CATEGORIES_BASE_URL = "/document-categories";

const fetchAll = params => api.get(DOCUMENT_CATEGORIES_BASE_URL, { params }).then(res => res.data);

const noPaginate = params =>
    api.get(DOCUMENT_CATEGORIES_BASE_URL, { ...params, per_page: -1 }).then(res =>
        res.data.data.map(d => {
            return { ...d, label: d.name, value: d.id };
        })
    );
const fetch = (id, params) => api.get(`${DOCUMENT_CATEGORIES_BASE_URL}/${id}`, params).then(res => res.data.data);
const store = params => api.post(DOCUMENT_CATEGORIES_BASE_URL, params).then(res => res.data.data);
const update = (id, params) => api.put(`${DOCUMENT_CATEGORIES_BASE_URL}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${DOCUMENT_CATEGORIES_BASE_URL}/${id}`).then(res => res.data);

const documentCategoryService = {
    fetchAll,
    noPaginate,
    fetch,
    store,
    update,
    destroy,
};

export default documentCategoryService;
