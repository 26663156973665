import { HiPaperClip } from "@react-icons/all-files/hi/HiPaperClip";
import classNames from "classnames";
import React, { useState } from "react";

import { Notification, toast } from "../../../../../../components/ui";
import DownloadButton from "../../../../../../components/ui/Buttons/_components/DownloadButton";
import PreviewButton from "../../../../../../components/ui/Buttons/_components/PreviewButton";
import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../../components/ui/Users/UserDisplay";
import attachmentService from "../../../../../../services/attachmentService";
import { DownloadOrPreview } from "../../../../../../utils/files";
import stringHelper from "../../../../../../utils/stringHelper";

const MyCompanyAttachmentRow = ({ attachment, index }) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        if (!loading) {
            setLoading(true);
            attachmentService
                .download(attachment?.media?.url)
                .then(DownloadOrPreview)
                .catch(err => toast.push(<Notification title={"Impossibile avviare il download"} type='danger' />))
                .finally(() => setLoading(false));
        }
    };

    return (
        <div
            onClick={() => handleDownload()}
            className={classNames("flex items-center cursor-pointer p-2 gap-2 hover:bg-neutral-100", index % 2 === 0 ? "bg-neutral-50" : "")}
        >
            <div>
                <HiPaperClip color='#d4d4d4' size={60} />
            </div>
            <div className='flex flex-col flex-grow gap-2'>
                <div>
                    <h6 className='text-sky-800'>{attachment.name}</h6>
                    <div className='text-sm'>{stringHelper.strLimit(attachment?.description)}</div>
                </div>
                <div className='flex items-center justify-between'>
                    <div className='flex gap-2'>
                        <DownloadButton url={attachment?.media?.url} label={attachment.media?.file_name} filename={attachment?.media?.file_name} />
                        <PreviewButton url={attachment?.media?.url} label={attachment.media?.file_name} filename={attachment?.media?.file_name} />
                    </div>
                    <UserDisplay user={attachment.user} size={20}>
                        <DateFormatDisplay value={attachment.created_at} />
                    </UserDisplay>
                </div>
            </div>
        </div>
    );
};

export default MyCompanyAttachmentRow;
