import React from "react";
import { useNavigate } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import { sessionConfig } from "../../../../../../configs/session.config";
import userService from "../../../../../../services/userService";
import { Select } from "../../../../../ui";

const CompanySwitcher = () => {
    const { user, setUser } = useSanctum();
    const navigate = useNavigate();

    const handleCompanyChange = ({ value }) => {
        sessionConfig.setCurrentCompanyId(value);
        userService.authUser().then(res => {
            setUser(res);
            navigate("/");
        });
    };

    return user.is_system_admin ? (
        <></>
    ) : user.companies.length > 0 ? (
        <Select
            name={"company_switcher"}
            className={"min-w-[200px]"}
            placeholder={"Seleziona un'azienda"}
            defaultValue={{ value: user.company.id, label: user.company.business_name }}
            options={user.companies.map(c => ({ value: c.id, label: c.business_name }))}
            onChange={handleCompanyChange}
            invalid={false}
        />
    ) : (
        <></>
    );
};

export default CompanySwitcher;
