import React from "react";
import {Navigate} from "react-router-dom";
import {useSanctum} from "react-sanctum";

import accountRoutes from "./accounts/accountRoutes";
import {adminRoutes} from "./admin/adminRoutes";
import {myCompanyRoutes} from "./myCompany/myCompanyRoutes";

export const DashboardRedirector = () => {
    const {user} = useSanctum();
    return <Navigate to={user.is_system_admin ? "/admin/dashboard" : "/my-company/dashboard"}/>;
};

const protectedRoutes = [
    {index: true, path: "/*", element: <DashboardRedirector/>},
    {path: "Account/*", ...accountRoutes},
    {path: "admin/*", ...adminRoutes},
    {path: "my-company/*", ...myCompanyRoutes},
];

export default protectedRoutes;
