import api from "../configs/api.config";

const DASHBOARD_BASE_URL = "/dashboard";
const fetchAttachments = () => api.get(DASHBOARD_BASE_URL + "/attachments").then(res => res.data.data);
const fetchUploads = () => api.get(DASHBOARD_BASE_URL + "/uploads").then(res => res.data.data);

const dashboardService = {
    fetchAttachments,
    fetchUploads,
};

export default dashboardService;
