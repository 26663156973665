import DocumentCategories from "../../../../views/protected/admin/documentCategories/DocumentCategories";
import DocumentCategoriesIndex from "../../../../views/protected/admin/documentCategories/DocumentCategoriesIndex";
import DocumentCategoryCreate from "../../../../views/protected/admin/documentCategories/DocumentCategoryCreate";
import DocumentCategoryShow from "../../../../views/protected/admin/documentCategories/DocumentCategoryShow";

const documentCategoriesRoutes = {
    element: <DocumentCategories />,
    children: [
        {
            path: "",
            element: <DocumentCategoriesIndex />,
        },
        {
            path: "create",
            element: <DocumentCategoryCreate />,
        },
        {
            path: ":id",
            element: <DocumentCategoryShow />,
        },
    ],
};

export default documentCategoriesRoutes;
