import * as Sentry from "@sentry/react";
import "configs/i18n.config";
import React from "react";
import ReactDOM from "react-dom/client";
import { createRoutesFromChildren, matchRoutes, useLocation, useNavigationType } from "react-router-dom";

import App from "./App";
import "./index.css";
import reportWebVitals from "./reportWebVitals";

Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    enabled: process.env.REACT_APP_SENTRY_DSN !== undefined,
    environment: process.env.REACT_APP_SENTRY_ENVIRONMENT ?? "local",
    maxBreadcrumbs: 25,
    integrations: [
        // See docs for support of different versions of variation of react router
        // https://docs.sentry.io/platforms/javascript/guides/react/configuration/integrations/react-router/
        Sentry.reactRouterV6BrowserTracingIntegration({
            useEffect: React.useEffect,
            useLocation,
            useNavigationType,
            createRoutesFromChildren,
            matchRoutes,
        }),
        Sentry.replayIntegration({
            networkDetailAllowUrls: [window.location.origin],
        }),
        // Sentry.feedbackIntegration({
        //     // Additional SDK configuration goes in here, for example:
        //     colorScheme: "system",
        // }),
    ],

    // beforeSend(event, hint) {
    //     // Check if it is an exception, and if so, show the report dialog
    //     if (event.exception && event.event_id) {
    //         Sentry.showReportDialog({ eventId: event.event_id });
    //     }
    //     return event;
    // },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    tracesSampleRate: 0.3,

    // Capture Replay for 10% of all sessions,
    // plus for 100% of sessions with an error
    replaysSessionSampleRate: 0.1,
    replaysOnErrorSampleRate: 1.0,
});

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
    <React.StrictMode>
        <App />
    </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
