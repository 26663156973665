import { useRequest } from "ahooks";
import React from "react";
// requires a loader
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";

import communicationService from "../../../../../../services/communicationService";
import carouselConfigurations from "./components/carouselConfigurations";

const MyCompanyCommunicationsWidget = () => {
    const { data: communications, loading } = useRequest(communicationService.fetchActive, {
        defaultParams: [{ per_page: -1 }],
    });

    return (communications?.data ?? []).length > 0 ? (
        <Carousel
            autoPlay
            infiniteLoop
            stopOnHover
            interval={5000}
            showArrows
            showStatus={false}
            showIndicators={false}
            renderArrowPrev={(onClickHandler, hasPrev, label) =>
                hasPrev &&
                carouselConfigurations.CarouselPrevButton({
                    onClickHandler,
                    label,
                })
            }
            renderArrowNext={(onClickHandler, hasNext, label) =>
                hasNext &&
                carouselConfigurations.CarouselNextButton({
                    onClickHandler,
                    label,
                })
            }
        >
            {communications?.data.map(com => (
                <div key={com.uuid} className='text-left'>
                    <div
                        className='bg-white border-4 rounded-md min-h-[200px] overflow-y-scroll '
                        style={{
                            height: "200px",
                        }}
                    >
                        <div className='p-4'>
                            <h5 className='text-sky-800'>{com.title}</h5>
                            {com?.body && (
                                <div
                                    className='rich-text-content-display'
                                    dangerouslySetInnerHTML={{
                                        __html: com.body,
                                    }}
                                />
                            )}
                        </div>
                    </div>
                </div>
            ))}
        </Carousel>
    ) : (
        <></>
    );
};

export default MyCompanyCommunicationsWidget;
