import { isBefore } from "date-fns";
import React from "react";

import DateFormatDisplay from "../Formatter/DateFormatDisplay";
import { Tag, Tooltip } from "../index";

const UserInviteState = ({ value }) => {
    return !value ? (
        <Tag suffix suffixClass='bg-green-500'>
            {" "}
            Invito accettato{" "}
        </Tag>
    ) : (
        <Tooltip title={<DateFormatDisplay value={value} />}>
            {isBefore(new Date(value), new Date()) ? (
                <Tag className='bg-white' suffix suffixClass='bg-red-500'>
                    Invito scaduto
                </Tag>
            ) : (
                <Tag className='bg-white' suffix suffixClass='bg-yellow-500'>
                    Invitato
                </Tag>
            )}
        </Tooltip>
    );
};

export default UserInviteState;
