import { useRequest } from "ahooks";
import React from "react";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import companyService from "../../../../services/companyService";
import { companiesCols } from "./_components/companiesCols";

const SupervisedIndex = () => {
    const { data: companies, run, loading } = useRequest(companyService.fetchAll, { manual: true });

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Aziende di hai la delega'
                        description="Qui trovi l'elenco delle aziende di cui hai la delega per la visualizzazione"
                    />
                }
            >
                <LaravelTable columns={companiesCols} records={companies} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default SupervisedIndex;
