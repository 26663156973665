import { Link } from "react-router-dom";

import PermissionsCheck from "../../../../../components/shared/PermissionsCheck";
import FormBoolDisplay from "../../../../../components/ui/Form/FormDisplay/FormBoolDisplay";

export const rolesCols = [
    {
        header: "Ruolo",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) =>
            original.is_global_role ? (
                <span className='font-bold'>{getValue()}</span>
            ) : (
                <PermissionsCheck is_system_admin={false} permissions={["roles.update"]} fallback={<span className='font-bold'>{getValue()}</span>}>
                    <Link className='font-bold' to={original.id.toString()}>
                        {getValue()}
                    </Link>
                </PermissionsCheck>
            ),
    },
    {
        header: "Globale",
        accessorKey: "is_global_role",
        enableSorting: false,
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "Numero Permessi",
        enableSorting: false,
        accessorKey: "permissions_count",
        cell: ({ getValue }) => <>{getValue() || 0}</>,
    },
];
