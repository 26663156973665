import * as Yup from "yup";

const initialValues = {
    inps_number: "",
    inail_primary_pat: "",
    inail_secondary_pat: "",
    ateco_code: "",
    ccnl: "",
    csc: "",
    inps_classification: "",
    health_care: "",
    bilateral_institution: "",
    bilateral_solidarity: "",
    cig: "",
    phone: "",
    email: "",
    referent_name: "",
    address: {
        address: "",
        street_number: "",
        city: "",
        postal_code: "",
        country: "",
        latitude: "",
        longitude: "",
    },
};

const validationSchema = Yup.object().shape({
    inps_number: Yup.string().max(25).nullable().notRequired(),
    inail_primary_pat: Yup.string().max(20).nullable().notRequired(),
    inail_secondary_pat: Yup.string().max(100).nullable().notRequired(),
    ateco_code: Yup.string().max(25).nullable().notRequired(),
    ccnl: Yup.string().max(255).nullable().notRequired(),
    csc: Yup.string().max(10).nullable().notRequired(),
    inps_classification: Yup.string().max(255).nullable().notRequired(),
    health_care: Yup.string().max(50).nullable().notRequired(),
    bilateral_institution: Yup.string().max(50).nullable().notRequired(),
    bilateral_solidarity: Yup.string().max(50).nullable().notRequired(),
    cig: Yup.string().max(7).nullable().notRequired(),
    phone: Yup.string().max(25).nullable().notRequired(),
    email: Yup.string().max(100).nullable().required(),
    referent_name: Yup.string().max(200).nullable().required(),
    address: Yup.object().shape({
        address: Yup.string().max(100).nullable().required(),
        street_number: Yup.string().max(20).nullable().required(),
        city: Yup.string().max(100).nullable().required(),
        postal_code: Yup.string().max(10).nullable().required(),
        country: Yup.string().max(100).nullable().required(),
    }),
});

const companyOfficeFormConfig = {
    initialValues,
    validationSchema,
};

export default companyOfficeFormConfig;
