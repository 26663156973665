import React from "react";

import FormDescription from "../../../components/RefactoredForms/FormDescription";
import FormDisplay from "../../../components/RefactoredForms/FormDisplay";
import CancelButton from "../../../components/ui/Buttons/_components/CancelButton";

const SupervisorInfo = ({ onCancel, supervisor, referent_name = null, referent_contact = null }) => (
    <>
        <FormDescription
            title={supervisor?.business_name}
            desc={
                <div className='grid xl:grid-cols-4 lg:grid-cols-2 gap-4'>
                    <FormDisplay value='Partita Iva'>{supervisor?.vat_number}</FormDisplay>
                    <FormDisplay value='Referente'>{referent_name || supervisor?.company_data?.referent_name}</FormDisplay>
                    <FormDisplay value='Email'>{referent_contact || supervisor?.company_data?.email}</FormDisplay>
                    <FormDisplay value='Telefono'>{supervisor?.company_data?.phone}</FormDisplay>
                </div>
            }
        />
        {onCancel && (
            <div>
                <CancelButton label={"Annulla selezione"} handleClick={onCancel} />
            </div>
        )}
    </>
);

export default SupervisorInfo;
