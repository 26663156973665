import React, { useState } from "react";
import { HiFolderDownload } from "react-icons/hi";

import documentService from "../../../../services/documentService";
import { ForceDownload } from "../../../../utils/files";
import { Notification, toast } from "../../index";
import Button from "../Button";

const DownloadZipButton = ({
    companyId,
    documentCategoryId,
    year = undefined,
    month = undefined,
    documentTypeId = undefined,
    disabled = false,
    buttonText,
    size = "sm",
}) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        setLoading(true);
        documentService
            .downloadZip({
                company_id: companyId,
                per_page: -1,
                filter: {
                    document_category_id: documentCategoryId,
                    year: year,
                    month: month,
                    document_type_id: documentTypeId,
                },
            })
            .then(ForceDownload)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"} type='danger' />))
            .finally(() => setLoading(false));
    };

    return (
        <Button
            type='button'
            disabled={disabled}
            loading={loading}
            size={size}
            variant='solid'
            color='sky-800'
            icon={<HiFolderDownload />}
            onClick={() => handleDownload()}
        >
            {buttonText}
        </Button>
    );
};

export default DownloadZipButton;
