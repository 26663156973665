import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSanctum } from "react-sanctum";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Alert, Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import supervisionService from "../../../../services/supervisionService";
import SupervisorEditForm from "../../../_forms/supervision/SupervisorEditForm";
import companySupervisionFormConfig from "../../../_forms/supervision/companySupervisionFormConfig";

const SupervisorShow = () => {
    const { user } = useSanctum();
    const [initialValues, setInitialValues] = useState([]);
    const { data: supervisor, error, mutate, loading } = useRequest(supervisionService.fetch);

    useEffect(() => {
        if (supervisor) {
            setInitialValues(companySupervisionFormConfig.setInitialValues(supervisor));
        }
    }, [supervisor]);

    return (
        <PageContainer>
            {error ? (
                <Alert className='mb-4' type='warning' showIcon title='Nessun professionista delegato associato!'>
                    Nessuna azienda al momento ha il permesso di supervisionare i tuoi documenti.
                    <br />
                    Se desideri richiedere la condivisione dei documenti con un'azienda terza contatta l'ammininitratore del sistema.
                    <br />
                    Grazie.
                </Alert>
            ) : (
                <Formik
                    validationSchema={companySupervisionFormConfig.validationSchema}
                    initialValues={initialValues}
                    enableReinitialize={true}
                    onSubmit={(values, { setSubmitting }) => {
                        supervisionService
                            .update({ document_types: values.document_types })
                            .then(res => {
                                toast.push(<Notification title='Tipologie documenti aggiornate con successo' type='success' />);
                                mutate(res);
                            })
                            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                            .finally(() => setSubmitting(false));
                        setSubmitting(false);
                    }}
                >
                    {({ isValid, isSubmitting, resetForm }) => (
                        <Form>
                            <Card
                                header={
                                    <PageHeader
                                        title='Professionista delegato'
                                        description='In questa sezione puoi delegare un professionista alla visione della documentazione presente in JBOX, decidendo tu cosa condividere e cosa non mostrare.'
                                        toolbar={
                                            <>
                                                <CancelButton disabled={!isValid || isSubmitting} handleClick={() => resetForm()} />
                                                <SaveButton loading={isSubmitting} disabled={!isValid} />
                                            </>
                                        }
                                    />
                                }
                            >
                                <div className='flex flex-col gap-4'>
                                    <SupervisorEditForm companyName={user?.company?.business_name} loading={loading} supervisor={supervisor} />
                                </div>
                            </Card>
                        </Form>
                    )}
                </Formik>
            )}
        </PageContainer>
    );
};

export default SupervisorShow;
