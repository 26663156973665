import {Form, Formik} from "formik";
import React, {useContext, useEffect, useState} from "react";
import * as Yup from "yup";

import FormCheckbox from "../../../../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../components/RefactoredForms/FormGroup";
import {Notification, toast} from "../../../../../../../components/ui";
import CancelButton from "../../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../../components/ui/Buttons/_components/SaveButton";
import companyService from "../../../../../../../services/companyService";
import {companyFormConfig} from "../../../_components/companyFormConfig";
import {CompanyContext} from "../../Company";

const CompanySettingsTab = () => {
    const {company, setCompany, loading} = useContext(CompanyContext);
    // const [initialValues, setInitialValues] = useState(companyFormConfig.companySettingsInitialValues);
    //
    // useEffect(() => {
    //     if (!loading) {
    //         setInitialValues(company.company_settings);
    //     }
    // }, [company]);


    const initialValues = {
        ...companyFormConfig.companySettingsInitialValues,
        ...company.company_settings
    }


    return (
        <Formik
            validationSchema={Yup.object().shape(companyFormConfig.companySettingsValidationSchema)}
            initialValues={initialValues}
            enableReinitialize={true}
            onSubmit={(values, {setSubmitting}) => {
                companyService
                    .updateSettings(company.uuid, values)
                    .then(res => {
                        toast.push(<Notification
                            title='Impostazioni azienda aggiornate con successo'
                            type='success'/>);
                        setCompany({...company, company_settings: res});
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message}
                                                           type='danger'/>))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({isValid, isSubmitting, resetForm, dirty, values}) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <FormDescription title='Impostazioni'/>
                        <FormGroup required label='' name='is_all_year_active'>
                            <FormCheckbox label='Attiva visualizzazione di tutti gli anni'
                                          name='is_all_year_active'/>
                        </FormGroup>

                        <div className='gap-4 flex justify-end'>
                            <CancelButton disabled={!isValid || isSubmitting}
                                          handleClick={() => resetForm()}/>
                            <SaveButton loading={isSubmitting}
                                        label="Salva impostazioni"
                                        disabled={!isValid || isSubmitting || !dirty}/>
                        </div>
                    </div>
                </Form>
            )}
        </Formik>
    );
};

export default CompanySettingsTab;
