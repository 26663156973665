import { flexRender, getCoreRowModel, useReactTable } from "@tanstack/react-table";
import PropTypes from "prop-types";
import React, { useEffect, useMemo, useState } from "react";

import tableConfig from "../../../configs/table.config";
import Table from "../Table";
import TBody from "../Table/TBody";
import THead from "../Table/THead";
import Td from "../Table/Td";
import Th from "../Table/Th";
import Tr from "../Table/Tr";
import LaravelTableCheckbox from "./_components/LaravelTableCheckbox";
import LaravelTableEmptyResult from "./_components/LaravelTableEmptyResult";
import LaravelTablePagination from "./_components/LaravelTablePagination";
import LaravelTableSearch from "./_components/LaravelTableSearch";
import LaravelTableSelectedCounter from "./_components/LaravelTableSelectedCounter";
import LaravelTableSkeleton from "./_components/LaravelTableSkeleton";
import LaravelTableSortArrows from "./_components/LaravelTableSortArrows";

const LaravelTable = ({
    columns,
    records,
    loading,
    onParamsChange,
    searchable,
    headerActions = null,
    onSelectedRowsChange,
    selectable,
    defaultSelected,
}) => {
    const { data, meta, links } = records;
    const [query, setQuery] = useState("");
    const [currentPage, setCurrentPage] = useState(1);
    const [recordPerPage, setRecordPerPage] = useState(tableConfig.defaultPerPageOption);
    const [filters, setFilters] = useState({});
    const [rowSelection, setRowSelection] = React.useState(defaultSelected || []);
    const [sorting, setSorting] = React.useState([]);

    const getColumns = () => {
        if (selectable) {
            return [
                {
                    id: "select",
                    width: 10,
                    header: ({ table }) => (
                        <LaravelTableCheckbox
                            checked={table.getIsAllRowsSelected()}
                            indeterminate={table.getIsSomeRowsSelected()}
                            onChange={table.getToggleAllRowsSelectedHandler()}
                        />
                    ),
                    cell: ({ row }) => (
                        <LaravelTableCheckbox
                            checked={row.getIsSelected()}
                            indeterminate={row.getIsSomeSelected()}
                            onChange={row.getToggleSelectedHandler()}
                        />
                    ),
                },
                ...columns,
            ];
        }
        return columns;
    };

    const table = useReactTable({
        data,
        columns: getColumns(),
        getCoreRowModel: getCoreRowModel(),
        getRowId: originalRow => originalRow.id,
        state: {
            rowSelection,
            sorting,
        },
        onRowSelectionChange: setRowSelection,
        onSortingChange: setSorting,
    });

    const queryParams = useMemo(
        () => ({
            q: query,
            page: currentPage,
            per_page: recordPerPage,
            filters: filters,
            sort: sorting[0] ? (sorting[0]?.desc ? "-" : "") + sorting[0]?.id : null,
        }),
        [query, currentPage, recordPerPage, filters, sorting]
    );

    useEffect(() => {
        onParamsChange(queryParams);
    }, [queryParams]);

    useEffect(() => {
        selectable && onSelectedRowsChange && onSelectedRowsChange(Object.keys(rowSelection));
    }, [rowSelection]);

    return (
        <>
            <div className='flex justify-between items-center py-2'>
                <div>
                    {selectable && (
                        <LaravelTableSelectedCounter selected={Object.keys(rowSelection).length} resetSelection={table.resetRowSelection} />
                    )}
                    {searchable && <LaravelTableSearch query={query} handleChangeQuery={query => setQuery(query || "")} />}
                </div>
                <div>{headerActions}</div>
            </div>

            {loading ? (
                <LaravelTableSkeleton cols={getColumns().length} rows={tableConfig.defaultPerPageOption} />
            ) : (
                <Table hoverable>
                    <THead>
                        {table.getHeaderGroups().map(headerGroup => (
                            <Tr key={headerGroup.id}>
                                {headerGroup.headers.map(header => (
                                    <Th key={header.id} width={header.column.columnDef?.width || "auto"}>
                                        {header.isPlaceholder ? null : (
                                            <LaravelTableSortArrows
                                                canSort={header.column.getCanSort()}
                                                onClick={header.column.getToggleSortingHandler()}
                                                isSorted={header.column.getIsSorted()}
                                            >
                                                {flexRender(header.column.columnDef.header, header.getContext())}
                                            </LaravelTableSortArrows>
                                        )}
                                    </Th>
                                ))}
                            </Tr>
                        ))}
                    </THead>
                    <TBody>
                        {(data || []).length > 0 ? (
                            table.getRowModel().rows.map(row => (
                                <Tr key={row.id}>
                                    {row.getVisibleCells().map(cell => (
                                        <Td key={cell.id}>{flexRender(cell.column.columnDef.cell, cell.getContext())}</Td>
                                    ))}
                                </Tr>
                            ))
                        ) : (
                            <LaravelTableEmptyResult cols={getColumns().length} />
                        )}
                    </TBody>
                </Table>
            )}

            <LaravelTablePagination
                meta={meta}
                onPageChange={pageNumber => setCurrentPage(Number(pageNumber))}
                onPerPageChange={pageSize => setRecordPerPage(Number(pageSize))}
            />
        </>
    );
};

LaravelTable.propTypes = {
    columns: PropTypes.array,
    records: PropTypes.object,
    loading: PropTypes.bool,
    searchable: PropTypes.bool,
    selectable: PropTypes.bool,
    onParamsChange: PropTypes.func,
    onSelectedRowsChange: PropTypes.func,
};

LaravelTable.defaultProps = {
    columns: [],
    records: {},
    loading: true,
    searchable: false,
    selectable: false,
    onParamsChange: params => {},
    onSelectedRowsChange: params => {},
};

export default LaravelTable;
