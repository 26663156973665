import { subMinutes } from "date-fns";
import * as Yup from "yup";

const initialValues = {
    has_scheduled_notification: false,
    scheduled_notification: {
        notify_at: undefined,
        notification_text: "",
        show_upload_file_list: false,
        send_to_company_email: false,
        send_to_company_admin_email: false,
        send_ccn_to_sysadmin: false,
        send_to_company_supervisor_email: false,
        send_to_company_delegati_email: false,
        send_to_company_pec: false,
    },
};

const validationSchema = Yup.object().shape({
    has_scheduled_notification: Yup.boolean().nullable().required(),
    scheduled_notification: Yup.object().when("has_scheduled_notification", {
        is: true,
        then: Yup.object().shape({
            notify_at: Yup.date().min(subMinutes(new Date(), 15), "L'orario minimo di notifica è adesso.").nullable().required(),
            notification_text: Yup.string().max(2000).required(),
            show_upload_file_list: Yup.boolean().required(),
            send_to_company_email: Yup.boolean().required(),
            send_to_company_admin_email: Yup.boolean().required(),
            send_ccn_to_sysadmin: Yup.boolean().required(),
            send_to_company_supervisor_email: Yup.boolean().required(),
            send_to_company_delegati_email: Yup.boolean().required(),
            send_to_company_pec: Yup.boolean().required(),
        }),
    }),
});

const setInitialValues = upload => {
    if (!upload) return initialValues;
    const { scheduled_notification } = upload;

    return {
        has_scheduled_notification: !!scheduled_notification,
        scheduled_notification: {
            notify_at: scheduled_notification?.notify_at ? new Date(scheduled_notification.notify_at) : null,
            notification_text: scheduled_notification?.notification_text || "",
            show_upload_file_list: scheduled_notification?.show_upload_file_list || false,
            send_to_company_email: scheduled_notification?.send_to_company_email || false,
            send_to_company_admin_email: scheduled_notification?.send_to_company_admin_email || false,
            send_ccn_to_sysadmin: scheduled_notification?.send_ccn_to_sysadmin || false,
            send_to_company_supervisor_email: scheduled_notification?.send_to_company_supervisor_email || false,
            send_to_company_delegati_email: scheduled_notification?.send_to_company_delegati_email || false,
            send_to_company_pec: scheduled_notification?.send_to_company_pec || false,
        },
    };
};

export const uploadFormConfig = {
    initialValues,
    validationSchema,
    setInitialValues,
};
