import React from "react";

const FormDisplay = ({ value, children, info = null }) => {
    return (
        <div className='flex flex-col gap-1'>
            <span>{value}</span>
            <div className='text-gray-700 dark:text-gray-200 font-semibold'>{children || "---"} </div>
            {/* ADDITIONAL INFO IF NEEDED*/}
            {info && <span className='text-xs'>{info}</span>}
        </div>
    );
};

export default FormDisplay;
