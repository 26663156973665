import { Link } from "react-router-dom";

import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";

export const companyGroupsCols = [
    {
        header: "Gruppo",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) => (
            <Link to={original.uuid} className='font-bold'>
                {original.code} {getValue()}
            </Link>
        ),
    },
    {
        header: "N° Aziende",
        accessorKey: "companies_count",
    },
    {
        header: "Creazione",
        accessorKey: "created_at",
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
];
