import { THEME_ENUM } from "constants/theme.constant";

export const themeConfig = {
    themeColor: "sky",
    direction: THEME_ENUM.DIR_LTR,
    mode: THEME_ENUM.MODE_LIGHT,
    locale: "it",
    primaryColorLevel: 800,
    cardBordered: true,
    panelExpand: false,
    controlSize: "md",
    navMode: THEME_ENUM.NAV_MODE_THEMED,
    layout: {
        type: THEME_ENUM.LAYOUT_TYPE_MODERN,
        sideNavCollapse: false,
    },
};
