import { HiPencil } from "@react-icons/all-files/hi/HiPencil";
import React from "react";

import Button from "../Button";

const EditButton = ({ disabled, loading, label, handleClick, icon }) => {
    return (
        <Button
            type='button'
            disabled={disabled}
            loading={loading}
            size='sm'
            variant='solid'
            color='sky-800'
            icon={icon ? icon : <HiPencil />}
            onClick={handleClick}
        >
            {label || "Modifica"}
        </Button>
    );
};

export default EditButton;
