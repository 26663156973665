import api from "../configs/api.config";

const ATTACHMENTS_BASE_URL = "attachments";

/**
 * Build url
 * @param companyUuid
 * @param attachmentUuid
 * @return {string|string}
 */
const getAttachmentBaseUrl = (companyUuid = null, attachmentUuid = null) => {
    let url = companyUuid ? `companies/${companyUuid}/${ATTACHMENTS_BASE_URL}` : ATTACHMENTS_BASE_URL;

    return attachmentUuid ? `${url}/${attachmentUuid}` : url;
};

/**
 * Build attachments form data
 * @param params
 * @param withGroups
 * @return {FormData}
 */
const buildAttachmentFormData = params => {
    let formData = new FormData();
    formData.append("file", params.file[0]);
    formData.append("name", params.name);
    formData.append("description", params.description);
    formData.append("is_public", params.is_public ? 1 : 0);
    formData.append("is_pin_to_dashboard", params.is_pin_to_dashboard ? 1 : 0);
    formData.append("send_notification", params.send_notification ? 1 : 0);

    if (params.hasOwnProperty("company_groups")) {
        params.company_groups.forEach((cg, index) => formData.append(`company_groups[${index}]`, cg));
    }

    return formData;
};

/**
 * Add headers to upload a file
 * @return {{headers: {"Content-Type": string}}}
 */
const getUploadHeaders = () => {
    return { headers: { "Content-Type": "multipart/form-data" } };
};

// #############################################################################
// ATTACHMENTS
// #############################################################################
const fetchAll = params => api.get(getAttachmentBaseUrl(null, null), { params }).then(res => res.data);
const fetch = attachmentUuid => api.get(getAttachmentBaseUrl(null, attachmentUuid)).then(res => res.data.data);
const store = params => api.post(getAttachmentBaseUrl(null, null), buildAttachmentFormData(params), getUploadHeaders()).then(res => res.data.data);
const update = (attachmentUuid, params) => api.put(getAttachmentBaseUrl(null, attachmentUuid), params).then(res => res.data.data);
const destroy = attachmentUuid => api.delete(getAttachmentBaseUrl(null, attachmentUuid)).then(res => res.data);

// #############################################################################
// COMPANY ATTACHMENTS
// #############################################################################
const companyFetchAll = (companyUuid, params) => api.get(getAttachmentBaseUrl(companyUuid, null), { params }).then(res => res.data);

const companyFetchSome = (companyUuid, limit) => api.get(getAttachmentBaseUrl(companyUuid, null), { per_page: limit }).then(res => res.data.data);
const companyFetch = (companyUuid, attachmentUuid) => api.get(getAttachmentBaseUrl(companyUuid, attachmentUuid)).then(res => res.data.data);
const companyStore = (companyUuid, params) =>
    api.post(getAttachmentBaseUrl(companyUuid, null), buildAttachmentFormData(params), getUploadHeaders()).then(res => res.data.data);
const companyUpdate = (companyUuid, attachmentUuid, params) =>
    api.put(getAttachmentBaseUrl(companyUuid, attachmentUuid), params).then(res => res.data.data);
const companyDestroy = (companyUuid, attachmentUuid) => api.delete(getAttachmentBaseUrl(companyUuid, attachmentUuid)).then(res => res.data);

const download = url => api.get(url, { responseType: "blob" });

// Service export and definition
const attachmentService = {
    fetchAll,
    fetch,
    store,
    update,
    destroy,
    companyFetchAll,
    companyFetchSome,
    companyFetch,
    companyStore,
    companyUpdate,
    companyDestroy,
    download,
};

export default attachmentService;
