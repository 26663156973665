import api from "../configs/api.config";

const OFFICE_BASE_URL = "/offices";

const buildUrl = companyUuid => `companies/${companyUuid}${OFFICE_BASE_URL}`;
const fetchAll = (companyUuid, params) => api.get(buildUrl(companyUuid), { params }).then(res => res.data.data);
const fetch = (companyUuid, uuid) => api.get(buildUrl(companyUuid) + "/" + uuid).then(res => res.data.data);
const store = (companyUuid, params) => api.post(buildUrl(companyUuid), params).then(res => res.data.data);
const update = (companyUuid, uuid, params) => api.put(buildUrl(companyUuid) + "/" + uuid, params).then(res => res.data.data);
const destroy = (companyUuid, uuid) => api.delete(buildUrl(companyUuid) + "/" + uuid);

const officeService = {
    fetchAll,
    fetch,
    store,
    update,
    destroy,
};

export default officeService;
