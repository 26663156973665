import { useRequest } from "ahooks";
import React, { useContext } from "react";

import companySupervisionService from "../../../../../../../services/companySupervisionService";
import { CompanyContext } from "../../Company";
import SupervisorCreate from "./SupervisorCreate";
import SupervisorShow from "./SupervisorShow";

const CompanySupervisionTab = () => {
    const { company } = useContext(CompanyContext);

    const { data: supervisor, loading, mutate } = useRequest(companySupervisionService.fetch, { defaultParams: [company?.uuid] });

    return loading ? (
        <></>
    ) : supervisor ? (
        <SupervisorShow loading={loading} supervisor={supervisor} setSupervisor={mutate} />
    ) : (
        <SupervisorCreate setSupervisor={mutate} />
    );
};

export default CompanySupervisionTab;
