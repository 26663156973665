import { ConfigProvider } from "components/ui";
import React, { useContext } from "react";

import { ApplicationContext } from "../../../../App";

const Theme = props => {
    const { themeConfig } = useContext(ApplicationContext);

    return (
        <>
            <ConfigProvider value={themeConfig}>{props.children}</ConfigProvider>
        </>
    );
};

export default Theme;
