import * as Yup from "yup";

const initialValues = {
    supervisor_id: "",
    referent_name: "",
    referent_contact: "",
    document_types: [],
};

const validationSchema = Yup.object().shape({
    supervisor_id: Yup.string().required(),
    referent_name: Yup.string().nullable().notRequired(),
    referent_contact: Yup.string().nullable().notRequired(),
    document_types: Yup.array().required(),
});

const setInitialValues = supervisor => ({
    supervisor_id: supervisor?.id,
    referent_name: supervisor?.referent_name || "",
    referent_contact: supervisor?.referent_contact || "",
    document_types: supervisor?.document_types.map(dt => dt.id.toString()),
});

const CompanySupervisionFormConfig = {
    initialValues,
    validationSchema,
    setInitialValues,
};

export default CompanySupervisionFormConfig;
