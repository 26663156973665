import { isAfter, isBefore, isEqual, startOfDay } from "date-fns";
import React from "react";

import { Badge } from "../../../../../components/ui";

const CommunicationState = ({ start_at = null, end_at = null }) => {
    let today = startOfDay(new Date());
    const isStartValid = () => {
        if (start_at) {
            let start = startOfDay(new Date(start_at));
            return isEqual(today, start) || isBefore(start, today);
        }
        return true;
    };

    const isEndValid = () => {
        if (end_at) {
            let end = startOfDay(new Date(start_at));
            return isEqual(today, end) || isAfter(today, end);
        }
        return true;
    };

    return isStartValid() && isEndValid() ? (
        <Badge content={"Attiva"} innerClass={"bg-emerald-500 font-semibold text-neutral-50"} />
    ) : (
        <Badge content={"Non Attiva"} innerClass={"bg-red-500 font-semibold text-neutral-50"} />
    );
};

export default CommunicationState;
