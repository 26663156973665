import CompanyGroupCreate from "../../../../views/protected/admin/companyGroups/CompanyGroupCreate";
import CompanyGroups from "../../../../views/protected/admin/companyGroups/CompanyGroups";
import CompanyGroupsIndex from "../../../../views/protected/admin/companyGroups/CompanyGroupsIndex";
import CompanyGroupShow from "../../../../views/protected/admin/companyGroups/companyGroup/CompanyGroupShow";

const companiesRoutes = {
    element: <CompanyGroups />,
    children: [
        {
            path: "",
            element: <CompanyGroupsIndex />,
        },
        {
            path: "create",
            element: <CompanyGroupCreate />,
        },
        {
            path: ":id",
            element: <CompanyGroupShow />,
        },
    ],
};

export default companiesRoutes;
