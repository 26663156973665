import { HiPaperClip } from "@react-icons/all-files/hi/HiPaperClip";
import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import { Alert } from "../../../../../../components/ui";
import Button from "../../../../../../components/ui/Buttons";
import Card from "../../../../../../components/ui/Card";
import CardHeader from "../../../../../../components/ui/Card/CardHeaders/CardHeader";
import { LoadingMessage } from "../../../../../../components/ui/LoadingMessage/LoadingMessage";
import attachmentService from "../../../../../../services/attachmentService";
import dashboardService from "../../../../../../services/dashboardService";
import MyCompanyAttachmentRow from "./MyCompanyAttachmentRow";

const MyCompanyAttachmentsBox = () => {
    const { data: attachments = [], loading = true } = useRequest(dashboardService.fetchAttachments);
    const navigate = useNavigate();

    return (
        <Card>
            <CardHeader
                title={"Ultime comunicazioni"}
                toolbar={
                    <Button
                        onClick={() => navigate("/my-company/attachments")}
                        icon={<HiPaperClip />}
                        size='sm'
                        loading={loading}
                        color='sky-800'
                        variant='solid'
                    />
                }
            />
            <LoadingMessage loading={loading} message='Caricamento bacheca ...' />

            {!loading && (
                <div className='flex flex-col gap-2 mt-4'>
                    {attachments.length < 1 && (
                        <Alert showIcon={true} type='info'>
                            Nessuna nuova comunicazione
                        </Alert>
                    )}
                    {attachments.map((attachment, index) => (
                        <MyCompanyAttachmentRow key={index} index={index} attachment={attachment} />
                    ))}
                </div>
            )}
        </Card>
    );
};

export default MyCompanyAttachmentsBox;
