import { useRequest } from "ahooks";
import { useFormikContext } from "formik";
import React, { useState } from "react";

import companyService from "../../../services/companyService";
import Select from "../Select/Select";

const CompanyPicker = ({ params = {}, name }) => {
    const { loading, runAsync } = useRequest(companyService.fetchAll, { manual: true });
    const [companies, setCompanies] = useState([]);

    const { values, touched, errors, setFieldTouched, setFieldValue } = useFormikContext();

    const handleCompanySelectionChange = value => {
        if (!!value) {
            runAsync({ filter: { business_name: value, ...params } }).then(({ data }) => setCompanies(data));
        }
        setCompanies([]);
    };

    return (
        <Select
            form={{ touched, errors }}
            field={{ name: name }}
            value={values[name]}
            onBlur={() => setFieldTouched(name)}
            onChange={value => setFieldValue(name, value)}
            options={companies}
            isClearable={true}
            getOptionLabel={e => e.business_name}
            getOptionValue={e => e.id}
            isLoading={loading}
            isSearchable={true}
            onInputChange={value => handleCompanySelectionChange(value)}
            placeholder="Cerca un'azienda"
            size='sm'
            className='min-w-[320px]'
        />
    );
};

export default CompanyPicker;
