import React from "react";

import Manuals from "../../../../views/protected/myCompany/manuals/Manuals";
import ManualsIndex from "../../../../views/protected/myCompany/manuals/ManualsIndex";
import RoleCreate from "../../../../views/protected/myCompany/roles/RoleCreate";
import RoleShow from "../../../../views/protected/myCompany/roles/RoleShow";
import Roles from "../../../../views/protected/myCompany/roles/Roles";
import RolesIndex from "../../../../views/protected/myCompany/roles/RolesIndex";

const rolesRoutes = {
    element: <Manuals />,
    children: [
        {
            path: "",
            index: true,
            element: <ManualsIndex />,
        },
    ],
};

export default rolesRoutes;
