import { NAV_ITEM_TYPE_ITEM } from "../../../constants/navigation.constant";

export const myCompanyNavigation = [
    {
        key: "my-company-dashboard",
        path: "/my-company/dashboard",
        title: "Dashboard",
        icon: "home",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
    {
        key: "my-company-attachments",
        path: "/my-company/attachments",
        title: "Bacheca",
        icon: "attachments",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: ["attachments.view"],
        subMenu: [],
    },
    {
        key: "my-company-company",
        path: "/my-company/company",
        title: "Anagrafica",
        icon: "companies",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
    {
        key: "my-company-documents",
        path: "/my-company/documents",
        title: "Documenti",
        icon: "documents",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: ["documents.view"],
        subMenu: [],
    },
    {
        key: "my-company-users",
        path: "/my-company/users",
        title: "Utenti",
        icon: "users",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: [],
        subMenu: [],
    },
    {
        key: "my-company-roles",
        path: "/my-company/roles",
        title: "Ruoli",
        icon: "roles",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: undefined,
        permissions: ["users.view"],
        subMenu: [],
    },
    {
        key: "my-company-supervisions",
        path: "/my-company/supervisions",
        title: "Professionista Delegato",
        icon: "supervisions",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: true,
        permissions: ["supervisions.view"],
        subMenu: [],
    },
    {
        key: "my-company-manuals",
        path: "/my-company/manuals",
        title: "Manuali",
        icon: "manuals",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: undefined,
        is_supervised: true,
        permissions: [],
        subMenu: [],
    },
    {
        key: "my-company-other-companies",
        path: "/my-company/supervised",
        title: "Aziende",
        icon: "companies",
        type: NAV_ITEM_TYPE_ITEM,
        is_system_admin: false,
        can_manage_other_companies: true,
        is_supervised: undefined,
        permissions: ["supervisions.view"],
        subMenu: [],
    },
];
