import { useRequest } from "ahooks";
import { Field, Form, Formik } from "formik";
import React, { useContext } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";

import Container from "../../../components/shared/Container";
import PasswordInput from "../../../components/shared/PasswordInput";
import { Alert, Button, Card, FormContainer, FormItem } from "../../../components/ui";
import { sessionConfig } from "../../../configs/session.config";
import publicCompanyService from "../../../services/publicCompanyService";
import { SanctumTokenContext } from "../../../utils/SanctumToken/SanctumToken";
import useTimeOutMessage from "../../../utils/hooks/useTimeOutMessage";

const TokenAuth = () => {
    const { id } = useParams();
    const { setTokenValid } = useContext(SanctumTokenContext);

    const { data: company, loading } = useRequest(publicCompanyService.companyInfo, { defaultParams: [id] });

    const validationSchema = Yup.object().shape({
        password: Yup.string().required("La password è obbligatoria"),
    });

    const initialValues = {
        password: "",
    };

    const [message, setMessage] = useTimeOutMessage();

    return (
        <div className='app-layout-blank flex flex-auto flex-col h-[100vh]'>
            <div className='h-full'>
                <Container className='flex flex-col flex-auto items-center justify-center min-w-0 h-full'>
                    <Card className='min-w-[320px] md:min-w-[450px]' bodyClass='md:p-10'>
                        <div className='text-center'>{company?.business_name}</div>
                        <div className='text-center'>
                            <div className='mb-8'>
                                <h3 className='mb-1'>Accesso alla bacheca</h3>
                                <p>&nbsp;</p>

                                {message && (
                                    <Alert className='mb-4' type='danger' showIcon>
                                        {message}
                                    </Alert>
                                )}

                                <Formik
                                    initialValues={initialValues}
                                    validationSchema={validationSchema}
                                    onSubmit={(values, { setSubmitting }) => {
                                        publicCompanyService
                                            .auth(id, values)
                                            .then(({ token }) => {
                                                sessionConfig.setToken(token);
                                                setTokenValid(true);
                                            })
                                            .catch(err => setMessage(err.response.data.message))
                                            .finally(() => setSubmitting(false));

                                        setSubmitting(false);
                                    }}
                                >
                                    {({ touched, errors, isSubmitting }) => (
                                        <Form>
                                            <FormContainer>
                                                <FormItem invalid={errors.password && touched.password} errorMessage={errors.password}>
                                                    <Field
                                                        autoComplete='off'
                                                        name='password'
                                                        placeholder='Password'
                                                        component={PasswordInput}
                                                        disabled={loading}
                                                    />
                                                </FormItem>

                                                <Button block loading={isSubmitting} variant='solid' type='submit' disabled={loading}>
                                                    {isSubmitting ? "Acccesso in corso ..." : "Accedi"}
                                                </Button>
                                            </FormContainer>
                                        </Form>
                                    )}
                                </Formik>
                            </div>
                        </div>
                    </Card>
                </Container>
            </div>
        </div>
    );
};

export default TokenAuth;
