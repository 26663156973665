import React from "react";

import RoleCreate from "../../../../views/protected/myCompany/roles/RoleCreate";
import RoleShow from "../../../../views/protected/myCompany/roles/RoleShow";
import Roles from "../../../../views/protected/myCompany/roles/Roles";
import RolesIndex from "../../../../views/protected/myCompany/roles/RolesIndex";

const rolesRoutes = {
    element: <Roles />,
    children: [
        {
            path: "",
            index: true,
            element: <RolesIndex />,
        },
        {
            path: "create",
            element: <RoleCreate />,
        },
        {
            path: ":id",
            element: <RoleShow />,
        },
    ],
};

export default rolesRoutes;
