import MyCompanyDocuments from "../../../../views/protected/myCompany/documents/MyCompanyDocuments";

const documentsRoutes = {
    path: "documents",
    element: <MyCompanyDocuments />,
    children: [
        {
            path: ":cat",
            element: <MyCompanyDocuments />,
        },
        {
            path: ":cat/:num_a",
            element: <MyCompanyDocuments />,
        },
        {
            path: ":cat/:num_a/:num_b",
            element: <MyCompanyDocuments />,
        },
    ],
};

export default documentsRoutes;
