import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import companyService from "../../../../services/companyService";
import { companiesCols } from "./_components/companiesCols";

const CompaniesIndex = () => {
    const { data: companies, run, loading } = useRequest(companyService.fetchAll, { manual: true });
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card header={<PageHeader title='Gestione aziende' toolbar={<CreateButton handleClick={() => navigate("create")} />} />}>
                <LaravelTable columns={companiesCols} records={companies} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default CompaniesIndex;
