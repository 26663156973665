import { formatISO9075 } from "date-fns";
import { get, orderBy } from "lodash";

import api from "../configs/api.config";

const DOCUMENT_BASE_URL = "/documents";

const fetchAll = params =>
    api.get(DOCUMENT_BASE_URL, { params }).then(res => {
        return res.data.data;
    });
const fetchSome = limit =>
    api
        .get(DOCUMENT_BASE_URL, {
            params: {
                per_page: limit || 15,
                page: 1,
                order_by: "-created_at",
            },
        })
        .then(res => res.data.data);

const store = params => {
    let formData = new FormData();
    formData.append("file", params.file);
    params.company_id && formData.append("company_id", params.company_id);
    params.document_type_id && formData.append("document_type_id", params.document_type_id);
    params.year && formData.append("year", params.year.value);
    params.month && formData.append("month", params.month.value);
    params.expiration && formData.append("expiration", formatISO9075(params.expiration));
    return api.post(DOCUMENT_BASE_URL, formData, { headers: { "Content-Type": "multipart/form-data" } }).then(res => res.data.data);
};

const destroy = documentUuid => api.delete(`${DOCUMENT_BASE_URL}/${documentUuid}`).then(res => res.data.data);

const download = url => api.get(url, { responseType: "blob" });

const reorderDocumentsByMediaFileName = documents => {
    if (documents && documents.length > 0) {
        return orderBy(
            documents,
            document => {
                let value = get(document, "media.file_name");
                return value ? value.toString().toLowerCase() : "";
            },
            ["asc"]
        );
    }

    return documents;
};

const downloadZip = params => api.get(`${DOCUMENT_BASE_URL}/zip`, { params, responseType: "blob" });

const documentService = {
    fetchAll,
    fetchSome,
    store,
    destroy,
    download,
    reorderDocumentsByMediaFileName,
    downloadZip,
};

export default documentService;
