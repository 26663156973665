import React, { useContext } from "react";
import { Navigate, useParams } from "react-router-dom";

import { SanctumTokenContext } from "../utils/SanctumToken/SanctumToken";

const TokenAuthGuard = ({ auth, pathResolver, children }) => {
    const { tokenValid } = useContext(SanctumTokenContext);
    const { id } = useParams();

    if (tokenValid !== null) {
        return auth === tokenValid ? <>{children}</> : <Navigate to={{ pathname: pathResolver(id) }} replace />;
    }

    return <></>;
};

export default TokenAuthGuard;
