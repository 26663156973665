import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import FormRichTextarea from "../../../../components/RefactoredForms/FormRichTextarea";
import FormSelect from "../../../../components/RefactoredForms/FormSelect";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import communicationService from "../../../../services/communicationService";
import companyGroupService from "../../../../services/companyGroupService";

const CommunicationsCreate = () => {
    const { data: companyGroups = [], loading } = useRequest(companyGroupService.noPaginate);
    const navigate = useNavigate();

    const initialValues = {
        title: "",
        body: "",
        start_at: "",
        end_at: "",
        company_groups: [],
    };
    const validation = Yup.object().shape({
        title: Yup.string().max(100).required(),
        body: Yup.string().nullable().notRequired(),
        start_at: Yup.date().nullable().notRequired(),
        end_at: Yup.date().nullable().notRequired(),
        company_groups: Yup.array().nullable().notRequired(),
    });

    return (
        <PageContainer>
            <Formik
                initialValues={initialValues}
                validationSchema={validation}
                onSubmit={(values, { setSubmitting }) => {
                    communicationService
                        .store({ ...values, company_groups: values.company_groups.map(cg => cg.id) })
                        .then(res => {
                            toast.push(<Notification title='Comunicazione creata con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, resetForm, dirty }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Nuova comunicazione'
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid || isSubmitting || !dirty} />
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                    <FormGroup required name={"title"} label={"Titolo"}>
                                        <FormInput name={"title"} />
                                    </FormGroup>
                                </div>
                                <FormGroup name={"body"} label={"Messaggio"}>
                                    <FormRichTextarea name={"body"} defaultValue={""} />
                                </FormGroup>
                                <FormGroup name={"company_groups"} label={"Gruppi aziende"}>
                                    <FormSelect
                                        name={"company_groups"}
                                        options={companyGroups}
                                        placeholder={"Seleziona uno o più gruppi"}
                                        isMulti={true}
                                        isLoading={loading}
                                    />
                                </FormGroup>
                                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                    <FormGroup name={"start_at"} label={"Dal"}>
                                        <FormInput type={"date"} name={"start_at"} />
                                    </FormGroup>
                                    <FormGroup name={"end_at"} label={"Al"}>
                                        <FormInput type={"date"} name={"end_at"} />
                                    </FormGroup>
                                </div>
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default CommunicationsCreate;
