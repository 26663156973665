import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React from "react";
import { useNavigate } from "react-router-dom";
import * as Yup from "yup";

import FormCheckbox from "../../../../components/RefactoredForms/FormCheckbox";
import FormDescription from "../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import FormSelect from "../../../../components/RefactoredForms/FormSelect";
import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card, Notification, toast } from "../../../../components/ui";
import CancelButton from "../../../../components/ui/Buttons/_components/CancelButton";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import SaveButton from "../../../../components/ui/Buttons/_components/SaveButton";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import companyGroupService from "../../../../services/companyGroupService";
import companyService from "../../../../services/companyService";
import { companyFormConfig } from "./_components/companyFormConfig";

const CompanyCreate = () => {
    const navigate = useNavigate();

    const { data: companyGroups } = useRequest(companyGroupService.noPaginate);

    const validationSchema = Yup.object().shape(companyFormConfig.companyValidationSchema);

    const initialValues = {
        ...companyFormConfig.companyInitialValues,
        with_admin: false,
    };

    return (
        <PageContainer>
            <Formik
                validationSchema={validationSchema}
                initialValues={initialValues}
                onSubmit={(values, { setSubmitting }) => {
                    companyService
                        .store({ ...values, groups: values.groups.map(g => g.value) })
                        .then(res => {
                            toast.push(<Notification title='Azienda creata con successo' type='success' />);
                            navigate("../" + res.uuid);
                        })
                        .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ isValid, isSubmitting, values, resetForm, setFieldValue }) => (
                    <Form>
                        <Card
                            header={
                                <PageHeader
                                    title='Creazione nuova azienda'
                                    description='Inserisci i dati minimi richiesti per creare una nuova azienda. Le utenze sarà possibile gestirle in un secondo momento.'
                                    toolbar={
                                        <>
                                            <CancelButton label={"Annulla"} handleClick={() => navigate("../")} />
                                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                                        </>
                                    }
                                />
                            }
                        >
                            <div className='flex flex-col gap-4'>
                                <FormDescription title='Informazioni generali' />

                                <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                                    <FormGroup label={"Codice"} name={"code"}>
                                        <FormInput name={"code"} />
                                    </FormGroup>
                                    <FormGroup label={"Ragione Sociale"} name={"business_name"} required>
                                        <FormInput name={"business_name"} />
                                    </FormGroup>
                                    <FormGroup label={"Partita Iva"} name={"vat_number"}>
                                        <FormInput name={"vat_number"} />
                                    </FormGroup>
                                    <FormGroup label={"Codice Fiscale"} name={"fiscal_code"} required>
                                        <FormInput name={"fiscal_code"} />
                                    </FormGroup>
                                </div>

                                <FormDescription title='Gruppi' />

                                <FormGroup label={"Gruppi aziende"} name={"groups"}>
                                    <FormSelect
                                        name='groups'
                                        isMulti
                                        isClearable
                                        options={companyGroups}
                                        value={values.groups}
                                        onChange={values => setFieldValue("groups", values)}
                                    />
                                </FormGroup>

                                <FormDescription title='Permessi' />

                                <FormCheckbox name={"can_manage_other_companies"} label={"Possibilità di gestire altre aziende"} />
                            </div>
                        </Card>
                    </Form>
                )}
            </Formik>
        </PageContainer>
    );
};

export default CompanyCreate;
