import React from "react";
import { HiChevronLeft, HiChevronRight } from "react-icons/hi";

const CarouselPrevButton = ({ onClickHandler, label }) => {
    return (
        <button
            type='button'
            className='cursor-pointer absolute font-semibold rounded-md text-2xl border bg-sky-800 text-white'
            onClick={onClickHandler}
            title={label}
            style={{ zIndex: 2, top: "80%", left: 25 }}
        >
            <HiChevronLeft />
        </button>
    );
};

const CarouselNextButton = ({ onClickHandler, label }) => {
    return (
        <button
            type='button'
            className='cursor-pointer absolute font-semibold rounded-md text-2xl bg-sky-800 text-white'
            onClick={onClickHandler}
            title={label}
            style={{ zIndex: 2, top: "80%", right: 25 }}
        >
            <HiChevronRight />
        </button>
    );
};

const carouselConfigurations = {
    CarouselPrevButton,
    CarouselNextButton,
};

export default carouselConfigurations;
