import { HiFolder } from "@react-icons/all-files/hi/HiFolder";
import React from "react";

const Folder = ({ label, description, onSelect }) => (
    <div className='flex items-center hover:bg-neutral-100 cursor-pointer' onClick={onSelect}>
        <div>
            <HiFolder color='#fed903' size={100} />
        </div>
        <div className='flex flex-col'>
            <h6>{label}</h6>
            <span>{description}</span>
        </div>
    </div>
);

export default Folder;
