import Menu from "./Menu";
import MenuCollapse from "./MenuCollapse";
import MenuGroup from "./MenuGroup";
import MenuItem from "./MenuItem";

Menu.MenuItem = MenuItem;
Menu.MenuCollapse = MenuCollapse;
Menu.MenuGroup = MenuGroup;

export default Menu;
