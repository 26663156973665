import { first, isArray } from "lodash";
import React from "react";

import FormCheckbox from "../../../../components/RefactoredForms/FormCheckbox";
import FormDisplay from "../../../../components/RefactoredForms/FormDisplay";
import FormGroup from "../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../components/RefactoredForms/FormInput";
import DownloadButton from "../../../../components/ui/Buttons/_components/DownloadButton";
import PreviewButton from "../../../../components/ui/Buttons/_components/PreviewButton";

const CompanyAttachmentEditForm = ({ loading, attachment }) => {
    const media = isArray(attachment?.media) ? first(attachment.media) : attachment?.media;

    return (
        <div className='flex flex-col gap-4'>
            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup label='Nome allegato' required>
                    <FormInput name='name' />
                </FormGroup>
                <FormGroup label='Descrizione allegato' name='description' required>
                    <FormInput name='description' />
                </FormGroup>
                <FormGroup name={"is_public"} label={"Visibilità"}>
                    <FormCheckbox name={"is_public"} label={"Spunta per rendere il file visibili all'esterno della piattaforma"} />
                </FormGroup>
                {media ? (
                    <FormDisplay value={"Allegato"} info={"Visualizza l'anteprima o scarica l'allegato."}>
                        <div className='flex gap-1'>
                            <PreviewButton url={media.url} label={attachment.name} filename={media.file_name} />
                            <DownloadButton url={media.url} label={attachment.name} filename={media.file_name} />
                        </div>
                    </FormDisplay>
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
};

export default CompanyAttachmentEditForm;
