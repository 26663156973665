import React, { useContext } from "react";
import { Outlet } from "react-router-dom";

import FormDisplay from "../../../../../../components/RefactoredForms/FormDisplay";
import DateFormatDisplay from "../../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../../components/ui/Users/UserDisplay";
import UserInviteState from "../../../../../../components/ui/Users/UserInviteState";
import UserState from "../../../../../../components/ui/Users/UserState";
import { MyCompanyUserContext } from "../User";

const MyCompanyUserCommonData = () => {
    const { user } = useContext(MyCompanyUserContext);

    return (
        <div className='flex flex-col gap-4'>
            <div className='flex flex-grow items-center justify-between gap-4'>
                <UserDisplay user={user} size='lg'>
                    {user?.email}
                </UserDisplay>
            </div>

            <div className='grid xl:grid-cols-3 gap-4'>
                <FormDisplay value='Stato'>
                    <UserState user={user} field={"company_user.active"} />
                </FormDisplay>
                <FormDisplay value='Stato invito'>
                    <UserInviteState value={user?.welcome_valid_until} />
                </FormDisplay>
                <FormDisplay value='Indirizzo email verificato'>
                    <DateFormatDisplay value={user?.email_verified_at} />
                </FormDisplay>
                <FormDisplay value='Ultimo Accesso'>
                    <DateFormatDisplay value={user?.last_login} formatString='dd/MM/yyyy' />
                </FormDisplay>
            </div>

            <Outlet />
        </div>
    );
};

export default MyCompanyUserCommonData;
