import { useRequest } from "ahooks";
import React, { useContext, useEffect, useState } from "react";

import FormDescription from "../../../../../../components/RefactoredForms/FormDescription";
import { Card, Notification, toast } from "../../../../../../components/ui";
import CancelButton from "../../../../../../components/ui/Buttons/_components/CancelButton";
import DestroyButton from "../../../../../../components/ui/Buttons/_components/DestroyButton";
import SaveButton from "../../../../../../components/ui/Buttons/_components/SaveButton";
import DualListBox from "../../../../../../components/ui/DualListBox/DualListBox";
import PageHeader from "../../../../../../components/ui/Page/PageHeader";
import companyGroupService from "../../../../../../services/companyGroupService";
import companyService from "../../../../../../services/companyService";
import { CompanyGroupContext } from "../CompanyGroupShow";

const CompanyGroupCompanies = () => {
    const { companyGroup, setCompanyGroup } = useContext(CompanyGroupContext);

    const [selectedCompanies, setSelectedCompanies] = useState([]);

    useEffect(() => {
        companyGroup && setSelectedCompanies(mapCompanies(companyGroup.companies));
    }, [companyGroup]);

    const handleSaveGroup = () => {
        companyGroupService
            .attachToGroup(companyGroup.uuid, {
                companies: selectedCompanies.map(c => c.value),
            })
            .then(res => {
                toast.push(<Notification title='Elenco aziende aggiornato con successo' type='success' />);
                setCompanyGroup({ ...companyGroup, companies: res });
            })
            .catch(err => toast.push(<Notification title={err?.response?.data?.message} type='danger' />));
    };

    const { runAsync, loading } = useRequest(companyService.fetchAll, { manual: true });

    const [companyList, setCompanyList] = useState([]);

    const handleRunAsync = () => {
        runAsync({ per_page: -1 })
            .then(res => setCompanyList(mapCompanies(res.data)))
            .catch(err => setCompanyList([]));
    };

    useEffect(() => {
        handleRunAsync();
    }, []);

    const mapCompanies = companies =>
        companies.map(company => {
            return {
                value: company.id,
                label: company.business_name,
            };
        });

    return (
        <Card
            header={
                <PageHeader
                    title='Associazione aziende'
                    description={`Scegli le aziende da aggiungere al gruppo ${companyGroup?.name} spostandole da una lista all'altra usando le frecce nel mezzo. Successivamente premi Associa per confermare la selezione.`}
                    toolbar={
                        <>
                            <CancelButton handleClick={() => setSelectedCompanies(mapCompanies(companyGroup.companies))} />
                            <SaveButton handleClick={() => handleSaveGroup()} loading={loading} label='Associa' />
                        </>
                    }
                />
            }
        >
            <div className='flex flex-col gap-4'>
                <DualListBox
                    loading={loading}
                    optionTitle={"Aziende disponibili"}
                    selectedTitle={"Aziende selezionate"}
                    options={companyList ?? []}
                    selected={selectedCompanies}
                    onChange={values => setSelectedCompanies(values)}
                />

                <div className='flex justify-end gap-4'></div>
            </div>
        </Card>
    );
};

export default CompanyGroupCompanies;
