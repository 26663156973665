import { HiTrash } from "@react-icons/all-files/hi/HiTrash";
import React from "react";

import useSweetAlert from "../../hooks/useSweetAlert";
import Button from "../Button";

const DestroyButton = ({ disabled, loading, label, handleClick, message, ...props }) => {
    const { SweetAlert } = useSweetAlert();

    const handleMessagePopUp = () => {
        SweetAlert.fire({
            icon: "warning",
            text: message || "Sei sicuro?",
        }).then(res => {
            if (res.isConfirmed) {
                handleClick();
            }
        });
    };

    return (
        <Button
            type='button'
            disabled={disabled}
            loading={loading}
            size='sm'
            variant='solid'
            color='red-500'
            icon={<HiTrash />}
            onClick={handleMessagePopUp}
            {...props}
        >
            {label || "Elimina"}
        </Button>
    );
};

export default DestroyButton;
