import React from "react";

import CommunicationCreate from "../../../../views/protected/admin/communications/CommunicationCreate";
import CommunicationShow from "../../../../views/protected/admin/communications/CommunicationShow";
import Communications from "../../../../views/protected/admin/communications/Communications";
import CommunicationsIndex from "../../../../views/protected/admin/communications/CommunicationsIndex";

const communicationsRoutes = {
    element: <Communications />,
    children: [
        {
            path: "",
            index: true,
            element: <CommunicationsIndex />,
        },
        {
            path: ":id",
            element: <CommunicationShow />,
        },
        {
            path: "create",
            element: <CommunicationCreate />,
        },
    ],
};

export default communicationsRoutes;
