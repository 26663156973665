import React from "react";
import { HiX } from "react-icons/hi";

import Tag from "../../Tag/Tag";

const LaravelTableSelectedCounter = ({ selected, resetSelection }) => {
    const renderBadge = () => {
        if (selected && selected > 0) {
            return <Tag suffix={<HiX className='ml-1 rtl:mr-1 cursor-pointer text-red-500' onClick={resetSelection} />}>Selezionati {selected}</Tag>;
        }
        return <>&nbsp;</>;
    };

    return <>{renderBadge()}</>;
};

export default LaravelTableSelectedCounter;
