import React, { useContext } from "react";

import DocumentNavigator from "../../../../../components/ui/DocumentNavigator/DocumentNavigator";
import { SupervisedContext } from "../SupervisedShow";

const SupervisedDocuments = () => {
    const { company } = useContext(SupervisedContext);

    if (!company) return null;

    return <DocumentNavigator company={company} isAdmin={false} />;
};

export default SupervisedDocuments;
