import React, { useContext } from "react";

import { PublicContext } from "../../../components/layout/PublicLayout/PublicLayout";
import Attachments from "../../../components/ui/Attachments/Attachments";
import BaseCompanyInfo from "../../../components/ui/Company/BaseCompanyInfo";

const Dashboard = () => {
    const { attachments, attachmentsLoading } = useContext(PublicContext);
    const { company, companyLoading } = useContext(PublicContext);

    return (
        <>
            <div className='grid grid-cols-4 gap-4'>
                <BaseCompanyInfo company={company} loading={companyLoading} />
            </div>
            <Attachments attachments={attachments} loading={attachmentsLoading} />
        </>
    );
};

export default Dashboard;
