import classNames from "classnames";
import React, { useContext } from "react";

import PermissionsCheck from "../../shared/PermissionsCheck";
import useUniqueId from "../hooks/useUniqueId";
import { GroupContextProvider } from "./context/groupContext";
import MenuContext from "./context/menuContext";

const MenuGroup = ({ nav, children, className }) => {
    const { variant, sideCollapsed } = useContext(MenuContext);

    const menuGroupDefaultClass = "menu-group";
    const menuGroupClass = classNames(menuGroupDefaultClass, className);

    const entityHeaderId = useUniqueId("entity-header-");

    return (
        <PermissionsCheck
            is_system_admin={nav.is_system_admin}
            can_manage_other_companies={nav?.can_manage_other_companies}
            is_supervised={nav?.is_supervised}
            permissions={nav.permissions}
        >
            <div className={menuGroupClass}>
                {nav?.title && !sideCollapsed && (
                    <div className={classNames("menu-title", `menu-title-${variant}`)} id={entityHeaderId}>
                        {nav?.title}
                    </div>
                )}
                <GroupContextProvider value>
                    <ul>{children}</ul>
                </GroupContextProvider>
            </div>
        </PermissionsCheck>
    );
};

export default MenuGroup;
