import { isAfter, isBefore, parse } from "date-fns";
import React from "react";

import { DOCUMENT_STATE_ENUM } from "../../../constants/enums/documentState.enum";
import Badge from "../Badge";
import Tooltip from "../Tooltip";

const DocumentStateDisplay = ({ state, auto_publish_at = null }) => {
    const documentIsWaitingToBePublished =
        auto_publish_at && state?.value === DOCUMENT_STATE_ENUM.PUBLISHED
            ? isAfter(parse(auto_publish_at, "yyyy-MM-dd", new Date()), new Date())
            : false;

    if (state)
        return documentIsWaitingToBePublished ? (
            <Tooltip title={`Pubblicazione: ${auto_publish_at}`}>
                <Badge content='In attesa' innerClass={`bg-yellow-500 font-semibold text-yellow-50`} />
            </Tooltip>
        ) : (
            <Badge content={state.label} innerClass={`bg-${state.color}-500 font-semibold text-${state.color}-50`} />
        );
    return <></>;
};

export default DocumentStateDisplay;
