import { useRequest } from "ahooks";
import React, { createContext } from "react";
import { useParams } from "react-router-dom";

import PageContainer from "../../../../../components/layout/ProtectedLayout/_components/PageContainer";
import companyGroupService from "../../../../../services/companyGroupService";
import CompanyGroupCompanies from "./_components/CompanyGroupCompanies";
import CompanyGroupEdit from "./_components/CompanyGroupEdit";

export const CompanyGroupContext = createContext({
    loading: true,
    companyGroup: {},
    setCompanyGroup: companyGroup => {},
});

const CompanyGroupShow = () => {
    const { id } = useParams();

    const { data: companyGroup, loading, mutate } = useRequest(companyGroupService.fetch, { defaultParams: [id] });

    const contextValues = {
        loading,
        companyGroup,
        setCompanyGroup: mutate,
    };

    return (
        <PageContainer>
            <CompanyGroupContext.Provider value={contextValues}>
                {!loading && (
                    <div className='flex flex-col gap-4'>
                        <CompanyGroupEdit />
                        <CompanyGroupCompanies />
                    </div>
                )}
            </CompanyGroupContext.Provider>
        </PageContainer>
    );
};

export default CompanyGroupShow;
