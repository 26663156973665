import React from "react";
import { FaSort, FaSortDown, FaSortUp } from "react-icons/fa";

import { useConfig } from "../../ConfigProvider";

const LaravelTableSortArrows = ({ canSort, onClick, isSorted, children }) => {
    const { themeColor, primaryColorLevel } = useConfig();

    const color = `text-${themeColor}-${primaryColorLevel}`;

    const renderSort = () => {
        if (canSort) {
            if (typeof isSorted === "boolean") {
                return <FaSort />;
            }
            return isSorted === "asc" ? <FaSortDown className={color} /> : <FaSortUp className={color} />;
        }
        return <></>;
    };

    return (
        <div onClick={onClick} className={canSort ? "cursor-pointer" : ""}>
            <span>{children}</span>
            {canSort && <div className='inline-flex'>{renderSort()}</div>}
        </div>
    );
};

export default LaravelTableSortArrows;
