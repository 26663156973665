import FileDownload from "js-file-download";

export const DownloadOrPreview = (res, forceDownload = false) => {
    const filename = res.headers["content-disposition"]?.split("filename=")[1]?.split(";")[0]?.replace(/"/g, "");
    const contentType = res.headers["content-type"];
    const isPdf = contentType === "application/pdf";

    if (isPdf && !forceDownload) {
        const file = new Blob([res.data], { type: contentType });
        const url = URL.createObjectURL(file);
        window.open(url);
        URL.revokeObjectURL(url);
    } else {
        return FileDownload(res.data, filename);
    }
};

export const ForceDownload = res => {
    return DownloadOrPreview(res, true);
};
