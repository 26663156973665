import { first, isArray } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import DownloadButton from "../../../../../../../../components/ui/Buttons/_components/DownloadButton";
import PreviewButton from "../../../../../../../../components/ui/Buttons/_components/PreviewButton";
import DateFormatDisplay from "../../../../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../../../../components/ui/Users/UserDisplay";

export const companyAttachmentsCols = [
    {
        header: "Allegato",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) =>
            original.is_global ? (
                <>
                    {original.code} {getValue()}
                </>
            ) : (
                <Link to={original.uuid} className='font-bold'>
                    {original.code} {getValue()}
                </Link>
            ),
    },
    {
        header: "Descrizione",
        accessorKey: "description",
    },
    {
        header: "File",
        accessorKey: "media",
        enableSorting: false,
        cell: ({ getValue, row: { original } }) => {
            const value = isArray(getValue()) ? first(getValue()) : getValue();
            return value ? (
                <div className='flex gap-1'>
                    <PreviewButton url={value.url} label={original.name} filename={value.file_name} />
                    <DownloadButton url={value.url} label={original.name} filename={value.file_name} />
                </div>
            ) : (
                <></>
            );
        },
    },
    {
        header: "Utente",
        accessorKey: "user",
        enableSorting: false,
        cell: ({ getValue }) => <UserDisplay user={getValue()} />,
    },
    {
        header: "Data Creazione",
        accessorKey: "created_at",
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
];
