import { PAGE_CONTAINER_GUTTER_X, PAGE_CONTAINER_GUTTER_Y } from "constants/theme.constant";
import React from "react";

const PageContainer = props => {
    const { children } = props;

    return (
        <div className='h-full flex flex-auto flex-col justify-between'>
            <main className='h-full'>
                <div className={`page-container relative h-full flex flex-auto flex-col ${PAGE_CONTAINER_GUTTER_X} ${PAGE_CONTAINER_GUTTER_Y}`}>
                    {/*// TODO PAGE-TITLE & BREADCRUMB*/}
                    <>{children}</>
                </div>
            </main>
        </div>
    );
};

export default PageContainer;
