import { first, isArray } from "lodash";
import React from "react";
import { Link } from "react-router-dom";

import PermissionsCheck from "../../../../../components/shared/PermissionsCheck";
import DownloadButton from "../../../../../components/ui/Buttons/_components/DownloadButton";
import PreviewButton from "../../../../../components/ui/Buttons/_components/PreviewButton";
import FormBoolDisplay from "../../../../../components/ui/Form/FormDisplay/FormBoolDisplay";
import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../components/ui/Users/UserDisplay";

export const attachmentsCols = [
    {
        header: "Allegato",
        accessorKey: "name",
        cell: ({ getValue, row: { original } }) =>
            original.is_from_sysadmin ? (
                <span className='font-bold'>
                    {original.code} {getValue()}
                </span>
            ) : (
                <PermissionsCheck
                    is_system_admin={false}
                    permissions={["attachments.update"]}
                    fallback={
                        <span className='font-bold'>
                            {original.code} {getValue()}
                        </span>
                    }
                >
                    <Link to={original.uuid} className='font-bold'>
                        {original.code} {getValue()}
                    </Link>
                </PermissionsCheck>
            ),
    },
    {
        header: "Descrizione",
        accessorKey: "description",
        enableSorting: false,
    },
    {
        header: "Data Creazione",
        accessorKey: "created_at",
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
    {
        header: "Pubblico",
        accessorKey: "is_public",
        enableSorting: false,
        cell: ({ getValue }) => <FormBoolDisplay value={getValue()} />,
    },
    {
        header: "File",
        accessorKey: "media",
        enableSorting: false,
        cell: ({ getValue, row: { original } }) => {
            const value = isArray(getValue()) ? first(getValue()) : getValue();
            return value ? (
                <div className='flex gap-1'>
                    <PreviewButton url={value.url} label={original.name} filename={value.file_name} />
                    <DownloadButton url={value.url} label={original.name} filename={value.file_name} />
                </div>
            ) : (
                <></>
            );
        },
    },
    {
        header: "Utente",
        accessorKey: "user",
        enableSorting: false,
        cell: ({ getValue, row: { original } }) => <UserDisplay user={getValue()} />,
    },
];
