import { HiDownload } from "@react-icons/all-files/hi/HiDownload";
import React, { useState } from "react";

import api from "../../../../configs/api.config";
import { ForceDownload } from "../../../../utils/files";
import { Notification, Tooltip, toast } from "../../index";
import Button from "../Button";

const DownloadButton = ({ disabled, url, label, filename, size = "sm" }) => {
    const [loading, setLoading] = useState(false);

    const handleDownload = () => {
        setLoading(true);
        api.get(url, { responseType: "blob" })
            .then(ForceDownload)
            .catch(err => toast.push(<Notification title={"Impossibile avviare il download"} type='danger' />))
            .finally(() => setLoading(false));
    };

    return (
        <Tooltip title={`Scarica ${label}`}>
            <Button
                type='button'
                disabled={disabled}
                loading={loading}
                size={size}
                variant='solid'
                color='sky-800'
                icon={<HiDownload />}
                onClick={() => handleDownload()}
            />
        </Tooltip>
    );
};

export default DownloadButton;
