import api from "../configs/api.config";

const authUser = () => api.get("user").then(res => res.data);
const updatePassword = params => api.put("user/password", params);
const passwordConfirmationStatus = () => api.get("user/confirmed-password-status");
const confirmPassword = () => api.post("user/confirm-password");
const storeAvatar = file => {
    let formData = new FormData();
    formData.append("avatar", file);
    return api.post("user/avatar", formData).then(res => res.data);
};

const updateProfileInformation = params => api.put("user/profile-information", params);

const userService = {
    authUser,
    updatePassword,
    passwordConfirmationStatus,
    confirmPassword,
    storeAvatar,
    updateProfileInformation,
};

export default userService;
