import React from "react";

import FormDescription from "../../../../../../../../components/RefactoredForms/FormDescription";
import FormGroup from "../../../../../../../../components/RefactoredForms/FormGroup";
import FormInput from "../../../../../../../../components/RefactoredForms/FormInput";

const CompanyOfficeForm = () => {
    return (
        <>
            <FormDescription title='Informazioni generali' />

            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup required label='Nome e cognome referente' name='referent_name'>
                    <FormInput name={"referent_name"} />
                </FormGroup>
                <FormGroup required label='Recapito telefonico' name='phone'>
                    <FormInput name={"phone"} />
                </FormGroup>
                <FormGroup required label='Indirizzo email' name='email'>
                    <FormInput name={"email"} type={"email"} />
                </FormGroup>
                <FormGroup required label='CIG' name='cig'>
                    <FormInput name={"cig"} />
                </FormGroup>
            </div>

            <FormDescription title='Indirizzo' />

            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup required label='Indirizzo' name='address.address'>
                    <FormInput name='address.address' />
                </FormGroup>
                <FormGroup required label='Numero civico' name='address.street_number'>
                    <FormInput name='address.street_number' />
                </FormGroup>
                <FormGroup required label='Città' name='address.city'>
                    <FormInput name='address.city' />
                </FormGroup>
                <FormGroup required label='Cap' name='address.postal_code'>
                    <FormInput name='address.postal_code' />
                </FormGroup>
                <FormGroup required label='Nazione' name='address.country'>
                    <FormInput name='address.country' />
                </FormGroup>
            </div>

            <FormDescription title='Altro' />

            <div className='grid xl:grid-cols-3 lg:grid-cols-2 gap-4'>
                <FormGroup required label='Matricola INPS' name='inps_number'>
                    <FormInput name='inps_number' />
                </FormGroup>
                <FormGroup required label='Pat 1 INAIL' name='inail_primary_pat'>
                    <FormInput name='inail_primary_pat' />
                </FormGroup>
                <FormGroup required label='Pat 2 INAIL' name='inail_secondary_pat'>
                    <FormInput name='inail_secondary_pat' />
                </FormGroup>
                <FormGroup required label='Codice ateco' name='ateco_code'>
                    <FormInput name='ateco_code' />
                </FormGroup>
                <FormGroup required label='CCNL' name='ccnl'>
                    <FormInput name='ccnl' />
                </FormGroup>
                <FormGroup required label='CSC' name='csc'>
                    <FormInput name='csc' />
                </FormGroup>
                <FormGroup required label='Classificazione INPS' name='inps_classification'>
                    <FormInput name='inps_classification' />
                </FormGroup>
                <FormGroup required label='Assistenza sanitaria' name='health_care'>
                    <FormInput name='health_care' />
                </FormGroup>
                <FormGroup required label='Ente bilaterale' name='bilateral_institution'>
                    <FormInput name='bilateral_institution' />
                </FormGroup>
                <FormGroup required label='Solidarietà bilaterale' name='bilateral_solidarity'>
                    <FormInput name='bilateral_solidarity' />
                </FormGroup>
            </div>
        </>
    );
};

export default CompanyOfficeForm;
