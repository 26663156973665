import PropTypes from "prop-types";
import React from "react";

import tableConfig from "../../../../configs/table.config";
import Pagination from "../../Pagination/Pagination";
import Select from "../../Select/Select";

const LaravelTablePagination = ({ meta, onPerPageChange, onPageChange }) => {
    return meta !== undefined ? (
        <div className='flex items-center justify-between mt-4'>
            <Pagination
                currentPage={meta.current_page}
                displayTotal={tableConfig.displayTotal}
                total={meta.total}
                pageSize={meta.per_page}
                onChange={onPageChange}
            />

            <div style={{ minWidth: 100 }}>
                <Select
                    menuPlacement={"top"}
                    size='sm'
                    isSearchable={false}
                    value={tableConfig.perPageOptions.filter(option => option.value === (meta.per_page || tableConfig.defaultPerPageOption))}
                    options={tableConfig.perPageOptions}
                    onChange={option => onPerPageChange(option.value)}
                />
            </div>
        </div>
    ) : (
        <></>
    );
};

LaravelTablePagination.propTypes = {
    meta: PropTypes.object,
    onPageChange: PropTypes.func,
    onPerPageChange: PropTypes.func,
};

export default LaravelTablePagination;
