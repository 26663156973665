import { HiChevronDoubleLeft, HiChevronDoubleRight, HiChevronLeft, HiChevronRight } from "react-icons/hi";

import { Button } from "../../index";

const DualListBoxActions = ({
    addDisabled,
    addAllDisabled,
    removeDisabled,
    removeAllDisabled,
    onAddClicked,
    onAddAllClicked,
    onRemoveAllClicked,
    onRemoveClicked,
}) => {
    return (
        <div className='flex flex-col gap-4 flex-none m-auto'>
            <Button disabled={addDisabled} onClick={onAddClicked} icon={<HiChevronRight />} size='sm' variant='solid' color='neutral-300' />
            <Button
                disabled={addAllDisabled}
                onClick={onAddAllClicked}
                icon={<HiChevronDoubleRight />}
                size='sm'
                variant='solid'
                color='neutral-300'
            />
            <Button
                disabled={removeAllDisabled}
                onClick={onRemoveAllClicked}
                icon={<HiChevronDoubleLeft />}
                size='sm'
                variant='solid'
                color='neutral-300'
            />
            <Button disabled={removeDisabled} onClick={onRemoveClicked} icon={<HiChevronLeft />} size='sm' variant='solid' color='neutral-300' />
        </div>
    );
};

export default DualListBoxActions;
