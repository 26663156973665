import { HiEyeOff } from "@react-icons/all-files/hi/HiEyeOff";
import React from "react";

import Td from "../../Table/Td";
import Tr from "../../Table/Tr";

const LaravelTableEmptyResult = ({ cols }) => {
    return (
        <Tr>
            <Td colSpan={cols}>
                <div className='flex items-center justify-center'>
                    <HiEyeOff className='mr-1' />
                    Nessun risultato trovato
                </div>
            </Td>
        </Tr>
    );
};

export default LaravelTableEmptyResult;
