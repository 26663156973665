import { useRequest } from "ahooks";
import React from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import { useSanctum } from "react-sanctum";

import PageContainer from "../../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../../components/ui";
import PageHeader from "../../../../../components/ui/Page/PageHeader";
import listService from "../../../../../services/listService";
import usersService from "../../../../../services/usersService";
import usePermissions from "../../../../../utils/hooks/usePermissions";
import MyCompanyUserDropdown from "./components/MyCompanyUserDropdown";

export const MyCompanyUserContext = React.createContext({
    user: undefined,
    setUser: user => {},
    roles: [],
    canUpdateUser: false,
    canUserSeeAllDocumentTypes: false,
});

const User = () => {
    //
    const { uuid } = useParams();
    const { data: user, mutate } = useRequest(usersService.fetch, { defaultParams: [uuid ?? ""] });
    const { data: roles = [] } = useRequest(listService.roles);
    const { user: authUser } = useSanctum();

    /*
      CONTROLLO PERMESSI

      L'utente può aggiornare un altro utente se non è se stesso e se l'utente non admin sta
      cercando di modificare un admin E l'utente ha il permesso di aggiornare gli utenti

      L'utente vede tutti i documenti se ha il permesso can_view_all_document_types OPPURE è admin

   */
    const canUpdateUser = () => authUser?.id !== user?.id && ((authUser?.role?.id !== 1 && user?.role?.id === 1) || authUser?.role?.id === 1);

    const handleUpdateUser = res => mutate({ ...user, ...res });
    const navigate = useNavigate();

    const contextValue = {
        user: user,
        setUser: handleUpdateUser,
        roles: roles,
        canUpdateUser: usePermissions(user?.role?.permissions ?? [], ["users.udpate"], false) && canUpdateUser(),
        canUserSeeAllDocumentTypes: (user?.company_user?.can_view_all_document_types ?? false) || user?.role?.id === 1,
    };

    return (
        <PageContainer>
            <MyCompanyUserContext.Provider value={contextValue}>
                <Outlet />
            </MyCompanyUserContext.Provider>
        </PageContainer>
    );
};

export default User;
