import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import documentCategoryService from "../../../../services/documentCategoryService";
import { documentCategoriesCols } from "./_components/documentCategoriesCols";

const DocumentCategoriesIndex = () => {
    const { data: documentCategories, run, loading } = useRequest(documentCategoryService.fetchAll, { manual: true });
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Gestione categorie documenti'
                        loading={loading}
                        toolbar={<CreateButton handleClick={() => navigate("create")} />}
                    />
                }
            >
                <LaravelTable
                    columns={documentCategoriesCols}
                    records={documentCategories}
                    loading={loading}
                    onParamsChange={run}
                    searchable
                    sortable
                />
            </Card>
        </PageContainer>
    );
};

export default DocumentCategoriesIndex;
