import { Card } from "components/ui";
import React from "react";
import { Outlet } from "react-router-dom";

import AuthLogo from "../../../../assets/logos/logoBlueYellowSquare.svg";
import Container from "../../../shared/Container";

const Simple = ({ children, content, ...rest }) => {
    return (
        <div className='h-full'>
            <Container className='flex flex-col flex-auto items-center justify-center min-w-0 h-full'>
                <Card className='min-w-[320px] md:min-w-[450px]' bodyClass='md:p-10'>
                    <div className='flex flex-auto items-center justify-center'>
                        <img src={AuthLogo} />
                    </div>
                    <div className='text-center'>
                        <Outlet />
                    </div>
                </Card>
            </Container>
        </div>
    );
};

export default Simple;
