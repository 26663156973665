import {
    addYears,
    eachMonthOfInterval,
    eachYearOfInterval,
    endOfYear,
    format,
    startOfYear,
    subYears
} from "date-fns";
import {it} from "date-fns/locale";


const startYear = process.env.REACT_APP_DOCUMENTS_START_YEAR ?? 2020
const pastYearNumber = process.env.REACT_APP_COMPANY_PAST_YEAR_LIMIT ?? 0
const futureYearNumber = process.env.REACT_APP_COMPANY_FUTURE_YEAR_LIMIT ?? 0

const getMonths = () =>
    eachMonthOfInterval({
        start: startOfYear(new Date()),
        end: endOfYear(new Date()),
    }).map((month, index) => ({
        value: index + 1,
        label: format(month, "MMMM", {locale: it}).toUpperCase(),
    }));

const getMonth = monthNumber => getMonths().find(month => month.value === monthNumber);

const getYears = () =>
    eachYearOfInterval({
        start: subYears(new Date(), pastYearNumber),
        end: addYears(new Date(), futureYearNumber),
    }).map(year => ({
        value: Number(format(year, "yyyy")),
        label: format(year, "yyyy").toString(),
    }));

const getAllYears = () =>
    console.log('startYear', startYear) ||
    eachYearOfInterval({
        start: new Date(startYear, 1, 1),
        end: addYears(new Date(), futureYearNumber),
    }).map(year => ({
        value: Number(format(year, "yyyy")),
        label: format(year, "yyyy").toString(),
    }));

const dateHelper = {
    getMonths,
    getMonth,
    getYears,
    getAllYears,
};

export default dateHelper;
