import React from "react";
import { Outlet, useNavigate } from "react-router-dom";
import { useSanctum } from "react-sanctum";

const Admin = () => {
    const { user } = useSanctum();

    const navigate = useNavigate();

    if (!user.is_system_admin) {
        navigate("/", { replace: true });
        return <h1>Unauthorized</h1>;
    }
    return <Outlet />;
};

export default Admin;
