import { HiDotsHorizontal } from "@react-icons/all-files/hi/HiDotsHorizontal";
import { isBefore } from "date-fns";
import React from "react";
import SweetAlert from "sweetalert2";

import { Button, Dropdown, Notification, toast } from "../../../../../components/ui";
import usersService from "../../../../../services/usersService";

const AdminUserDropdown = ({ user, onDelete, onReInvite, onToggleActivation }) => {
    const handleDelete = () =>
        usersService
            .destroy(user.uuid)
            .then(res => {
                toast.push(<Notification title='Utente eliminato con successo' type='success' />);
                onDelete();
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));

    const handleReInvite = () => {
        usersService
            .reInvite(user.uuid)
            .then(res => {
                toast.push(<Notification title='Notifica di invito inviata con successo' type='success' />);
                onReInvite(res);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    const handleToggleActivation = () => {
        usersService
            .toggleActivation(user.uuid)
            .then(res => {
                toast.push(<Notification title='Stato aggiornato con successo' type='success' />);
                onToggleActivation(res);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    const displayConfirm = (message, callback) => {
        SweetAlert.fire({
            icon: "warning",
            text: message,
        }).then(res => {
            if (res.isConfirmed) {
                callback();
            }
        });
    };

    const checkWelcomeValid = () => (user.welcome_valid_until ? isBefore(new Date(user.welcome_valid_until), new Date()) : false);

    return (
        <Dropdown placement='bottom-end' renderTitle={<Button shape='circle' variant='plain' size='xs' icon={<HiDotsHorizontal />} />}>
            <Dropdown.Item
                eventKey={"toggle-activation"}
                onClick={() =>
                    displayConfirm(`Sei sicuro di voler ${user.active ? "disattivare" : "attivare"} ${user.name}?`, handleToggleActivation)
                }
            >
                {user?.active ? "Disattiva" : "Attiva"}
            </Dropdown.Item>
            {checkWelcomeValid() && (
                <Dropdown.Item eventKey={"reinvite"} onClick={() => handleReInvite()}>
                    Rinnova invito
                </Dropdown.Item>
            )}
            <Dropdown.Item eventKey={"delete"} onClick={() => displayConfirm(`Sei sicuro di voler eliminare ${user.name}?`, handleDelete)}>
                Elimina
            </Dropdown.Item>
        </Dropdown>
    );
};

export default AdminUserDropdown;
