import { useFormikContext } from "formik";
import { get } from "lodash";
import React from "react";

const FormLabel = ({ label, name, required }) => {
    return (
        <label htmlFor={name} className='text-gray-500'>
            {label}
            {required && <span className='ms-1 text-red-500'>*</span>}
        </label>
    );
};

export default FormLabel;
