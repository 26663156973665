import { useRequest } from "ahooks";
import { Form, Formik } from "formik";
import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";

import FormCheckbox from "../../../../../../components/RefactoredForms/FormCheckbox";
import FormGroup from "../../../../../../components/RefactoredForms/FormGroup";
import FormRichTextarea from "../../../../../../components/RefactoredForms/FormRichTextarea";
import { FormContainer, Notification, toast } from "../../../../../../components/ui";
import Button from "../../../../../../components/ui/Buttons";
import CancelButton from "../../../../../../components/ui/Buttons/_components/CancelButton";
import SaveButton from "../../../../../../components/ui/Buttons/_components/SaveButton";
import DateTimepicker from "../../../../../../components/ui/DatePicker/DateTimepicker";
import PageHeader from "../../../../../../components/ui/Page/PageHeader";
import listService from "../../../../../../services/listService";
import uploadService from "../../../../../../services/uploadService";
import { uploadFormConfig } from "./uploadFormConfig";

const UploadNotificationForm = ({ upload, loading, mutate }) => {
    const [initialValues, setInitialValues] = useState(uploadFormConfig.initialValues);
    const navigate = useNavigate();
    const { data: defaultNotificationMessages } = useRequest(listService.defaultNotificationMessages);

    const richTextArea = useRef();

    useEffect(() => setInitialValues(uploadFormConfig.setInitialValues(upload)), [upload]);

    return (
        <Formik
            enableReinitialize={true}
            initialValues={initialValues}
            validationSchema={uploadFormConfig.validationSchema}
            onSubmit={(values, { setSubmitting }) => {
                console.log("values", values);

                uploadService
                    .update(upload?.uuid, uploadService.mapUploadValuesToApi(upload, values))
                    .then(res => {
                        toast.push(<Notification title='Upload aggiornato con successo' type='success' />);
                        mutate({
                            ...upload,
                            scheduled_notification: res.scheduled_notification,
                        });
                    })
                    .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />))
                    .finally(() => setSubmitting(false));
            }}
        >
            {({ resetForm, values, setFieldValue, setFieldTouched, isValid, isSubmitting }) => (
                <Form>
                    <div className='flex flex-col gap-4'>
                        <PageHeader
                            title={"Impostazioni di notifica"}
                            description={"Scegli chi deve ricevere la notifica con la disponibilità di nuovi documenti"}
                        />

                        <FormGroup required label='' name='has_scheduled_notification'>
                            <FormCheckbox label='Abilita notifica schedulata' name='has_scheduled_notification' />
                        </FormGroup>

                        <div className='grid xl:grid-cols-2 gap-4'>
                            <div className='flex flex-col gap-4'>
                                <FormGroup
                                    required={values.has_scheduled_notification}
                                    name='scheduled_notification.notify_at'
                                    label='Giorno e ora di invio notifica'
                                >
                                    <DateTimepicker
                                        amPm={false}
                                        clearable={true}
                                        labelFormat={{ month: "MM", year: "YYYY" }}
                                        inputFormat='DD/MM/YYYY HH:mm:ss'
                                        value={values?.scheduled_notification?.notify_at || null}
                                        onBlur={() => setFieldTouched("scheduled_notification.notify_at")}
                                        onChange={value => setFieldValue("scheduled_notification.notify_at", value)}
                                        disabled={!values.has_scheduled_notification}
                                        name='scheduled_notification.notify_at'
                                    />
                                </FormGroup>
                                <FormGroup
                                    required={values.has_scheduled_notification}
                                    name='scheduled_notification.notification_text'
                                    label='Testo della notifica'
                                    info={
                                        <div className='flex gap-2 mb-2'>
                                            {defaultNotificationMessages?.map((message, index) => (
                                                <Button
                                                    type='button'
                                                    disabled={!values.has_scheduled_notification}
                                                    loading={loading}
                                                    size='xs'
                                                    variant='twoTone'
                                                    color='sky-800'
                                                    key={index}
                                                    onClick={() => richTextArea?.current?.setNewContent(message.message)}
                                                    // onClick={() => setFieldValue(`scheduled_notification.notification_text`, message.message)}
                                                >
                                                    {message.label}
                                                </Button>
                                            ))}
                                        </div>
                                    }
                                >
                                    <FormRichTextarea
                                        ref={richTextArea}
                                        disabled={!values.has_scheduled_notification}
                                        // defaultValue={values?.scheduled_notification?.notification_text}
                                        defaultValue={upload?.scheduled_notification?.notification_text}
                                        name='scheduled_notification.notification_text'
                                    />
                                </FormGroup>
                            </div>
                            <div className='flex flex-col gap-4'>
                                <FormGroup required={values.has_scheduled_notification} label='' name='scheduled_notification.show_upload_file_list'>
                                    <FormCheckbox
                                        disabled={!values.has_scheduled_notification}
                                        name={"scheduled_notification.show_upload_file_list"}
                                        label={"Abilita visualizzazione lista file"}
                                    />
                                </FormGroup>

                                <FormGroup required={values.has_scheduled_notification} label='' name='scheduled_notification.send_to_company_email'>
                                    <FormCheckbox
                                        disabled={!values.has_scheduled_notification}
                                        name={"scheduled_notification.send_to_company_email"}
                                        label="Abilita invio notifica all'indirizzo email aziendale"
                                    />
                                </FormGroup>

                                <div className='ml-5 mb-3'>
                                    <FormGroup
                                        required={values.has_scheduled_notification}
                                        label=''
                                        name='scheduled_notification.send_ccn_to_sysadmin'
                                    >
                                        <FormCheckbox
                                            disabled={!values.has_scheduled_notification || !values.scheduled_notification.send_to_company_email}
                                            name={"scheduled_notification.send_ccn_to_sysadmin"}
                                            label='Metti in copia Studio Barzaghi'
                                        />
                                    </FormGroup>
                                </div>

                                <FormGroup
                                    required={values.has_scheduled_notification}
                                    label=''
                                    name='scheduled_notification.send_to_company_admin_email'
                                >
                                    <FormCheckbox
                                        disabled={!values.has_scheduled_notification}
                                        name={"scheduled_notification.send_to_company_admin_email"}
                                        label={"Invia agli amministratori della società"}
                                    />
                                </FormGroup>

                                <FormGroup
                                    required={values.has_scheduled_notification}
                                    label=''
                                    name='scheduled_notification.send_to_company_delegati_email'
                                >
                                    <FormCheckbox
                                        disabled={!values.has_scheduled_notification}
                                        name={"scheduled_notification.send_to_company_delegati_email"}
                                        label={"Invia ai delegati della società"}
                                    />
                                </FormGroup>

                                <FormGroup
                                    required={values.has_scheduled_notification}
                                    label=''
                                    name='scheduled_notification.send_to_company_supervisor_email'
                                >
                                    <FormCheckbox
                                        disabled={!values.has_scheduled_notification}
                                        name={"scheduled_notification.send_to_company_supervisor_email"}
                                        label='Invia allo allo studio delegato'
                                    />
                                </FormGroup>

                                <FormGroup required={values.has_scheduled_notification} label='' name='scheduled_notification.send_to_company_pec'>
                                    <FormCheckbox
                                        disabled={!values.has_scheduled_notification}
                                        name={"scheduled_notification.send_to_company_pec"}
                                        label='Invia alla PEC della società'
                                    />
                                </FormGroup>
                            </div>
                        </div>

                        <div className='flex justify-end gap-4'>
                            <CancelButton handleClick={() => navigate("../")} label={"Annulla"} />
                            <CancelButton handleClick={() => resetForm()} />
                            <SaveButton loading={isSubmitting} disabled={!isValid} />
                        </div>
                    </div>

                    <FormContainer></FormContainer>
                </Form>
            )}
        </Formik>
    );
};

export default UploadNotificationForm;
