const tokenKey = "public_token";
const currentCompanyKey = "current_company";

const getToken = () => sessionStorage.getItem(tokenKey);
const setToken = token => sessionStorage.setItem(tokenKey, token);

const getCurrentCompanyId = () => sessionStorage.getItem(currentCompanyKey);
const setCurrentCompanyId = id => sessionStorage.setItem(currentCompanyKey, id);
const removeCurrentCompanyId = () => sessionStorage.removeItem(currentCompanyKey);

const clear = () => sessionStorage.clear();

export const sessionConfig = {
    tokenKey,
    getToken,
    setToken,
    getCurrentCompanyId,
    setCurrentCompanyId,
    removeCurrentCompanyId,
    clear,
};
