import React, { useContext } from "react";

import DocumentNavigator from "../../../../../../../components/ui/DocumentNavigator/DocumentNavigator";
import { CompanyContext } from "../../Company";

const CompanyDocumentsTab = () => {
    const { company } = useContext(CompanyContext);

    return <DocumentNavigator company={company} isAdmin={true} />;
};

export default CompanyDocumentsTab;
