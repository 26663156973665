import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import PermissionsCheck from "../../../../components/shared/PermissionsCheck";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import attachmentService from "../../../../services/attachmentService";
import { attachmentsCols } from "./_components/attachmentsCols";

const AttachmentIndex = () => {
    const { data: attachments, run, loading } = useRequest(attachmentService.fetchAll, { manual: true });
    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Bacheca aziendale'
                        description='Sfrutta la bacheca digitale come spazio di informazione per i tuoi dipendenti e collaboratori, annunci, avvisi, regolamenti, CCNL e molto altro saranno pubblicizzati e a portata di click.'
                        loading={loading}
                        toolbar={
                            <PermissionsCheck permissions={["attachments.create"]} is_system_admin={false}>
                                <CreateButton handleClick={() => navigate("create")} />
                            </PermissionsCheck>
                        }
                    />
                }
            >
                <LaravelTable columns={attachmentsCols} records={attachments} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default AttachmentIndex;
