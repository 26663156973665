import { Link } from "react-router-dom";

export const companiesCols = [
    {
        header: "Codice",
        accessorKey: "code",
    },
    {
        header: "Ragione Sociale",
        accessorKey: "business_name",
        cell: ({ getValue, row: { original } }) => (
            <Link to={`/admin/companies/${original.uuid}`} className='font-bold'>
                {getValue()}
            </Link>
        ),
    },
    {
        header: "Partita Iva",
        accessorKey: "vat_number",
    },
    {
        header: "Codice Fiscale",
        accessorKey: "fiscal_code",
    },
    {
        header: "Telefono",
        accessorKey: "phone",
        cell: ({ row: { original } }) => original.company_data?.phone,
    },
    {
        header: "Email",
        accessorKey: "email",
        cell: ({ row: { original } }) => original.company_data?.email,
    },
    {
        header: "Referente",
        accessorKey: "reference",
        cell: ({ row: { original } }) => original.company_data?.referent_name,
    },
];
