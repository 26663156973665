import React from "react";

const FormDescription = ({ title, desc, ...props }) => {
    return (
        <div {...props}>
            <div className='font-semibold text-lg text-sky-800'>{title}</div>
            {desc && <div>{desc}</div>}
        </div>
    );
};

export default FormDescription;
