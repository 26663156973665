import { Alert, Button, Checkbox, FormContainer, FormItem, Input } from "components/ui";
import { Field, Form, Formik } from "formik";
import React from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import useTimeOutMessage from "utils/hooks/useTimeOutMessage";
import * as Yup from "yup";

import ActionLink from "../../../components/shared/ActionLink";
import PasswordInput from "../../../components/shared/PasswordInput";
import authService from "../../../services/authService";

const validationSchema = Yup.object().shape({
    email: Yup.string().email("La mail fornita non è valida").required("L'indirizzo email è obbligatorio"),
    password: Yup.string().required("La password è obbligatoria"),
    rememberMe: Yup.bool(),
});

const SignInForm = () => {
    const [message, setMessage] = useTimeOutMessage();
    const navigate = useNavigate();

    const [searchParams] = useSearchParams();
    const redirect = searchParams.get("redirect");

    const initialValues = {
        email: "",
        password: "",
        rememberMe: false,
    };

    return (
        <div>
            {message && (
                <Alert className='mb-4' type='danger' showIcon>
                    {message}
                </Alert>
            )}

            <Formik
                initialValues={initialValues}
                validationSchema={validationSchema}
                onSubmit={(values, { setSubmitting }) => {
                    const { email, password, rememberMe } = values;

                    authService
                        .signIn(email, password, rememberMe)
                        .then(res => {
                            const { force_password_change } = res;
                            if (force_password_change === undefined) {
                                if (redirect) {
                                    window.location.replace(redirect);
                                } else {
                                    window.location.reload();
                                }
                            } else {
                                navigate("/auth/force-reset-password");
                            }
                        })
                        .catch(err => setMessage(err.response.data.message))
                        .finally(() => setSubmitting(false));
                }}
            >
                {({ touched, errors, isSubmitting }) => (
                    <Form>
                        <FormContainer>
                            <FormItem label='Indirizzo Email' invalid={errors.email && touched.email} errorMessage={errors.email}>
                                <Field type='email' autoComplete='off' name='email' placeholder='Indirizzo Email' component={Input} />
                            </FormItem>

                            <FormItem label='Password' invalid={errors.password && touched.password} errorMessage={errors.password}>
                                <Field autoComplete='off' name='password' placeholder='Password' component={PasswordInput} />
                            </FormItem>

                            <div className='flex justify-between mb-6'>
                                <Field className='mb-0' name='rememberMe' component={Checkbox} children='Ricordami' />
                                <ActionLink to='/auth/forgot-password'>Password dimenticata?</ActionLink>
                            </div>

                            <Button block loading={isSubmitting} variant='solid' type='submit'>
                                {isSubmitting ? "Acccesso in corso ..." : "Accedi"}
                            </Button>
                        </FormContainer>
                    </Form>
                )}
            </Formik>
        </div>
    );
};

export default SignInForm;
