import React from "react";
import { Link } from "react-router-dom";

import PermissionsCheck from "../../../../../components/shared/PermissionsCheck";
import DateFormatDisplay from "../../../../../components/ui/Formatter/DateFormatDisplay";
import UserDisplay from "../../../../../components/ui/Users/UserDisplay";
import UserInviteState from "../../../../../components/ui/Users/UserInviteState";
import UserState from "../../../../../components/ui/Users/UserState";

export const usersCols = [
    {
        header: "Nome e cognome",
        accessorKey: "name",
        cell: ({ row: { original } }) => (
            <PermissionsCheck
                permissions={["users.update"]}
                fallback={
                    <span className='font-bold'>
                        <UserDisplay user={original} />
                    </span>
                }
            >
                <Link className='font-bold' to={original.uuid}>
                    <UserDisplay user={original} />
                </Link>
            </PermissionsCheck>
        ),
    },
    {
        header: "E-Mail",
        accessorKey: "email",
    },
    {
        header: "Stato",
        accessorKey: "company_user.active",
        enableSorting: false,
        cell: ({ row: { original } }) => <UserState user={original} field={"company_user.active"} />,
    },
    {
        header: "Stato invito",
        accessorKey: "welcome_valid_until",
        enableSorting: false,
        cell: ({ getValue }) => <UserInviteState value={getValue()} />,
    },
    {
        header: "Ultimo Accesso",
        accessorKey: "last_login",
        enableSorting: false,
        cell: ({ getValue }) => <DateFormatDisplay value={getValue()} />,
    },
];
