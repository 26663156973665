import { HiDotsVertical } from "@react-icons/all-files/hi/HiDotsVertical";
import { isBefore } from "date-fns";
import React from "react";
import { useNavigate } from "react-router-dom";
import { useSanctum } from "react-sanctum";
import SweetAlert from "sweetalert2";

import PermissionsCheck from "../../../../../../components/shared/PermissionsCheck";
import { Button, Dropdown, Notification, toast } from "../../../../../../components/ui";
import EditButton from "../../../../../../components/ui/Buttons/_components/EditButton";
import usersService from "../../../../../../services/usersService";

const MyCompanyUserDropdown = ({ user, onDelete, onReInvite, onToggleActivation }) => {
    const { user: authUser } = useSanctum();

    const handleDelete = () =>
        usersService
            .destroy(user.uuid)
            .then(res => {
                toast.push(<Notification title='Utente eliminato con successo' type='success' />);
                onDelete();
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));

    const handleReInvite = () => {
        usersService
            .reInvite(user.uuid)
            .then(res => {
                toast.push(<Notification title='Notifica di invito inviata con successo' type='success' />);
                onReInvite(res);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    const handleToggleActivation = () => {
        usersService
            .toggleActivation(user.uuid)
            .then(res => {
                toast.push(<Notification title='Stato aggiornato con successo' type='success' />);
                onToggleActivation(res);
            })
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    const handleResetPassword = () => {
        usersService
            .forgotPassword(user.uuid)
            .then(res => toast.push(<Notification title='Notifica di reset password inviata con successo' type='success' />))
            .catch(err => toast.push(<Notification title={err.response.data.message} type='danger' />));
    };

    const displayConfirm = (message, callback) => {
        SweetAlert.fire({
            icon: "warning",
            text: message,
        }).then(res => {
            if (res.isConfirmed) {
                callback();
            }
        });
    };

    const checkWelcomeValid = () => (user?.welcome_valid_until ? isBefore(new Date(user?.welcome_valid_until), new Date()) : false);

    const navigate = useNavigate();

    return (
        <Dropdown
            placement='bottom-end'
            renderTitle={
                <Button variant='solid' color={"sky-800"} size='sm' icon={<HiDotsVertical />}>
                    Azioni
                </Button>
            }
        >
            <Dropdown.Item eventKey={"edit-company-user"} onClick={() => navigate("edit")}>
                Modifica
            </Dropdown.Item>

            <Dropdown.Item
                eventKey={"toggle-activation"}
                onClick={() =>
                    displayConfirm(
                        `Sei sicuro di voler ${user.company_user.active ? "disattivare" : "attivare"} ${user.name}?`,
                        handleToggleActivation
                    )
                }
            >
                {user?.company_user.active ? "Disattiva" : "Attiva"}
            </Dropdown.Item>

            <Dropdown.Item eventKey={"toggle-activation"} onClick={() => handleResetPassword()}>
                Invia link reset password
            </Dropdown.Item>

            {checkWelcomeValid() && (
                <Dropdown.Item eventKey={"reinvite"} onClick={() => handleReInvite()}>
                    Rinnova invito
                </Dropdown.Item>
            )}
            <Dropdown.Item eventKey={"delete"} onClick={() => displayConfirm(`Sei sicuro di voler eliminare ${user.name}?`, handleDelete)}>
                Elimina
            </Dropdown.Item>
        </Dropdown>
    );
};

export default MyCompanyUserDropdown;
