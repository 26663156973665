import { EditorState, convertToRaw } from "draft-js";
import { stateFromHTML } from "draft-js-import-html";
import draftToHtml from "draftjs-to-html";
import { useField } from "formik";
import React, { forwardRef, useCallback, useEffect, useImperativeHandle, useState } from "react";
import { Editor } from "react-draft-wysiwyg";

const FormRichTextarea = forwardRef(({ name, defaultValue, ...props }, ref) => {
    const [field, meta, helpers] = useField(name);
    const [editorState, setEditorState] = useState(EditorState.createEmpty());
    const className =
        "input min-h-[120px] input-md focus:ring-sky-800 focus-within:ring-sky-800 focus-within:border-sky-800 focus:border-sky-800 text-gray-700 dark:text-gray-200";

    useImperativeHandle(ref, () => ({
        setNewContent(content) {
            setNewState(content);
            helpers.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        },
    }));

    const setNewState = useCallback(
        value => {
            setEditorState(EditorState.createWithContent(stateFromHTML(value ?? "")));
            helpers.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
        },
        [setEditorState]
    );

    const handleOnChange = value => {
        setEditorState(value);
        helpers.setValue(draftToHtml(convertToRaw(editorState.getCurrentContent())));
    };

    useEffect(() => {
        setNewState(defaultValue);
    }, [defaultValue]);

    return (
        <div>
            <Editor
                name={name}
                editorClassName={`${className} ${meta.error && "input-invalid"}`}
                toolbarClassName='bg-neutral-50 rounded-md'
                editorState={editorState}
                onEditorStateChange={handleOnChange}
                defaultValue={defaultValue}
                toolbar={{
                    options: ["inline", "list", "remove", "history"],
                    inline: {
                        options: ["bold", "italic", "underline", "strikethrough", "monospace"],
                    },
                    list: {
                        options: ["unordered", "ordered"],
                    },
                }}
            />
        </div>
    );
});

export default FormRichTextarea;
