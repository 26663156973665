import * as Yup from "yup";

const initialValues = {
    document_type: null,
    expiration: null,
    auto_publish_at: null,
};

const formValidation = Yup.object().shape({
    document_type: Yup.object().nullable().required(),
    expiration: Yup.date()
        .nullable()
        .notRequired()
        .when("document_type", {
            is: document_type => !!document_type?.is_expiration_required,
            then: Yup.date().min(new Date()).nullable().required(),
        }),
    auto_publish_at: Yup.date().nullable().notRequired(),
});

const setInitialValues = document => ({
    document_type: document?.document_type,
    expiration: document?.expiration ? new Date(document.expiration) : null,
    auto_publish_at: document?.auto_publish_at ? new Date(document.auto_publish_at) : null,
});

const uploadDocumentFormConfig = {
    initialValues,
    formValidation,
    setInitialValues,
};

export default uploadDocumentFormConfig;
