import classNames from "classnames";
import React from "react";

const LOGO_SRC_PATH = "/img/logo/";

const Logo = props => {
    const { type, mode, gutter, className, imgClass, style, logoWidth } = props;

    return (
        <div
            className={classNames("logo", className, gutter)}
            style={{
                ...style,
                ...{ width: logoWidth },
            }}
        >
            <img className={imgClass} src={`${LOGO_SRC_PATH}logo-${mode}-${type}.png`} alt={`${process.env.REACT_APP_NAME} logo`} />
        </div>
    );
};

export default Logo;
