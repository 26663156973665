import { groupBy } from "lodash";

import api from "../configs/api.config";

const DOCUMENT_TYPES_BASE_URL = "/document-types";

const fetchAll = params => api.get(DOCUMENT_TYPES_BASE_URL, { params }).then(res => res.data);
const noPaginate = params =>
    api.get(DOCUMENT_TYPES_BASE_URL, { params: { ...params, per_page: -1 } }).then(res =>
        res.data.data.map(d => {
            return { ...d, label: d.name, value: d.id };
        })
    );
const fetch = id => api.get(`${DOCUMENT_TYPES_BASE_URL}/${id}`).then(res => res.data.data);
const store = params => api.post(DOCUMENT_TYPES_BASE_URL, params).then(res => res.data.data);
const update = (id, params) => api.put(`${DOCUMENT_TYPES_BASE_URL}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${DOCUMENT_TYPES_BASE_URL}/${id}`).then(res => res.data);

const groupByCategory = (documentTypes = []) => {
    if (documentTypes.length > 0) {
        let groupedDT = groupBy(documentTypes, dt => dt?.document_category?.name);
        let optionGroups = [];

        return Object.keys(groupedDT).forEach(dtCategory =>
            optionGroups.push({
                label: dtCategory,
                options: groupedDT[dtCategory],
            })
        );
    } else {
        return [];
    }
};

const documentTypeService = {
    fetchAll,
    noPaginate,
    fetch,
    store,
    update,
    destroy,
    groupByCategory,
};

export default documentTypeService;
