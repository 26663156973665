import api from "../configs/api.config";

const ROLES_BASE_URL = "/roles";
const fetchAll = params => api.get(ROLES_BASE_URL, { params }).then(res => res.data);
const fetch = id => api.get(`${ROLES_BASE_URL}/${id}`).then(res => res.data.data);
const store = params => api.post(`${ROLES_BASE_URL}`, params).then(res => res.data.data);
const update = (id, params) => api.put(`${ROLES_BASE_URL}/${id}`, params).then(res => res.data.data);
const destroy = id => api.delete(`${ROLES_BASE_URL}/${id}`);

// ROLES DELLE COMPANIES
const companyRoles = companyUuid => api.get(`companies/${companyUuid}/roles`).then(res => res.data);

const roleService = {
    fetchAll,
    fetch,
    store,
    update,
    destroy,
    companyRoles,
};

export default roleService;
