import { Avatar, Dropdown } from "components/ui";
import React, { useContext } from "react";
import { HiOutlineLogout, HiOutlineUser } from "react-icons/hi";
import { Link } from "react-router-dom";
import { useSanctum } from "react-sanctum";
import withHeaderItem from "utils/hoc/withHeaderItem";

import { ApplicationContext } from "../../../../../../App";
import { sessionConfig } from "../../../../../../configs/session.config";

const dropdownItemList = [
    {
        path: "/Account",
        icon: <HiOutlineUser />,
        label: "Account",
    },
];

export const UserDropdown = () => {
    const { signOut, user } = useSanctum();
    const { setCurrentCompany } = useContext(ApplicationContext);
    const handleLogout = () =>
        signOut().then(() => {
            sessionConfig.removeCurrentCompanyId();
            setCurrentCompany(null);
        });

    const UserAvatar = (
        <div className='flex items-center gap-2 cursor-pointer'>
            <Avatar size={32} src={user.avatar} shape='circle' icon={<HiOutlineUser />} />
            <div className='hidden md:block'>
                <div className='text-xs capitalize'>{user.name}</div>
                <div className='font-bold'>{user.email}</div>
            </div>
        </div>
    );

    return (
        <div>
            <Dropdown menuStyle={{ minWidth: 240 }} renderTitle={UserAvatar} placement='bottom-end'>
                <Dropdown.Item variant='header'>
                    <div className='py-2 px-3 flex items-center gap-2'>
                        <Avatar src={user.avatar} shape='circle' icon={<HiOutlineUser />} />
                        <div>
                            <div className='font-bold text-gray-900 dark:text-gray-100'>{user.name}</div>
                            <div className='text-xs'>{user.email}</div>
                        </div>
                    </div>
                </Dropdown.Item>

                <Dropdown.Item variant='divider' />

                {dropdownItemList.map(item => (
                    <Dropdown.Item eventKey={item.label} key={item.label} className='mb-1'>
                        <Link className='flex gap-2 items-center' to={item.path}>
                            <span className='text-xl opacity-50'>{item.icon}</span>
                            <span>{item.label}</span>
                        </Link>
                    </Dropdown.Item>
                ))}

                <Dropdown.Item variant='divider' />

                <Dropdown.Item onClick={() => handleLogout()} eventKey='Sign Out' className='gap-2'>
                    <span className='text-xl opacity-50'>
                        <HiOutlineLogout />
                    </span>
                    <span>Disconnetti</span>
                </Dropdown.Item>
            </Dropdown>
        </div>
    );
};

export default withHeaderItem(UserDropdown);
