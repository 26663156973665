import { HiUpload } from "@react-icons/all-files/hi/HiUpload";
import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";

import DisplayTitle from "../../../../../../components/ui/Displays/DisplayTitle";
import { UPLOAD_STATE_ENUM } from "../../../../../../constants/enums/uploadState.enum";
import uploadService from "../../../../../../services/uploadService";

const UploadDocumentDropzone = ({ upload, onDocumentUploaded }) => {
    const onDrop = useCallback(acceptedFiles => {
        if (typeof acceptedFiles === "object" && acceptedFiles.length > 0) {
            acceptedFiles.forEach(file => {
                uploadService
                    .storeDocument(upload?.uuid, { file: file })
                    .then(res => onDocumentUploaded(res))
                    .catch(err => console.log(file, err));
            });
        }
    }, []);

    const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

    return (
        <div className='mb-3'>
            <DisplayTitle
                title='Upload'
                description={
                    upload?.status?.value !== UPLOAD_STATE_ENUM.COMPLETED
                        ? "Seleziona e trascina i file nella area delimitata per caricare i file. Successivamente in base alla tipologia di file sarà necessario compilare le informazioni richieste."
                        : "L'upload è stato completato e non è più possibile modificarlo. I documenti contrassegnati come pubblicato sono già disponibili, gli altri verranno pubblicati automaticamente dal sistema alla data di pubblicazione."
                }
                icon={<HiUpload className='text-neutral-300' size={60} />}
            />

            {upload?.status?.value !== UPLOAD_STATE_ENUM.COMPLETED && (
                <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    {isDragActive ? (
                        <div className='upload-draggable'>Trascina documenti</div>
                    ) : (
                        <div className='upload-draggable'>Trascina documenti</div>
                    )}
                </div>
            )}
        </div>
    );
};

export default UploadDocumentDropzone;
