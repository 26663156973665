import { useRequest } from "ahooks";
import React from "react";
import { useNavigate } from "react-router-dom";

import PageContainer from "../../../../components/layout/ProtectedLayout/_components/PageContainer";
import { Card } from "../../../../components/ui";
import CreateButton from "../../../../components/ui/Buttons/_components/CreateButton";
import LaravelTable from "../../../../components/ui/LaravelTable/LaravelTable";
import PageHeader from "../../../../components/ui/Page/PageHeader";
import uploadService from "../../../../services/uploadService";
import { uploadsCols } from "./_components/uploadsCols";

const UploadsIndex = () => {
    const { data: uploads, run, loading } = useRequest(uploadService.fetchAll, { manual: true });

    const navigate = useNavigate();

    return (
        <PageContainer>
            <Card
                header={
                    <PageHeader
                        title='Gestione caricamenti documenti'
                        loading={loading}
                        toolbar={<CreateButton handleClick={() => navigate("create")} />}
                    />
                }
            >
                <LaravelTable columns={uploadsCols} records={uploads} loading={loading} onParamsChange={run} searchable sortable />
            </Card>
        </PageContainer>
    );
};

export default UploadsIndex;
